import React, { PureComponent } from "react"
import PropTypes from "prop-types"

import { Button } from "reactstrap"
import Select from "components/FormRenderer/components/Select"
import { findByType } from "api/actions"
import { getFrontendTranslations } from "components/Layout/layoutManager"
import translate from "components/i18n/translate"

class BulkActionSelection extends PureComponent {
  static propTypes = {
    actions: PropTypes.array,
    items: PropTypes.number,
    onRenderAction: PropTypes.func,
    resetActionState: PropTypes.func,
  }

  state = {
    selectedIndex: 0,
  }

  normalizeActions = () => {
    const { actions } = this.props
    return actions.map((action, index) => (
      { name: action.title, selected: index === this.state.selectedIndex, value: index }
    ))
  }

  fetchSelectedAction = () => {
    const { actions } = this.props
    return actions && actions[this.state.selectedIndex]
  }

  onChange = (event) => {
    this.setState({ selectedIndex: event.target.value })
    this.props.resetActionState()
  }

  canClick = () => {
    const action = this.fetchSelectedAction()
    const config = findByType(action.fields, "entityId")
    return (config && this.props.items)
  }

  onClick = (event) => {
    const action = this.fetchSelectedAction()
    const { onRenderAction } = this.props
    onRenderAction && onRenderAction(action, event)
  }

  render() {
    const { actions } = this.props
    if (!actions || actions.length === 0) {
      return null
    }

    return <div className="d-flex">
      <Select name="bulk" value={this.normalizeActions()} onChange={this.onChange}/>
      <Button color="primary" className="px-4 mb-3" onClick={this.onClick} disabled={!this.canClick()}>{getFrontendTranslations() ? getFrontendTranslations().action.bulk.start : translate("action.bulk.start")}</Button>
    </div>
  }
}

export default BulkActionSelection
