import React, { PureComponent } from "react"
import Proptypes from "prop-types"

import FormRenderer from "components/FormRenderer/FormRenderer"
import { addClass, removeClass } from "components/FormRenderer/form"
import { findByName } from "api/actions"
import get from "get"

const FIELD_PERMISSIONS_CAPACITIES = "permissionCollectionCapacityIds"
const FIELD_ADVERTISED_FACILITY = "advertisedParkingFacilityIds"
const FIELD_PERMISSIONS_ID = "permissionCollectionId"
const FIELD_PERMISSION_MODE = "permissionCollectionMode"

const getInitialPermissions = action => get(findByName(action.fields, FIELD_PERMISSIONS_CAPACITIES), "value") || []
class ProductCreatorForm extends PureComponent {
  static propTypes = {
    action: Proptypes.object,
  }

  state = {
    action: this.props.action,
    initialPermissions: getInitialPermissions(this.props.action),
    selectedPermissionIds: getInitialPermissions(this.props.action)
    .filter(permission => permission.selected)
    .map(permission => permission.value),
  }

  componentDidMount() {
    const { initialPermissions, selectedPermissionIds } = this.state
    const action = { ...this.state.action }
    const exisitingValues = get(findByName(action.fields, FIELD_ADVERTISED_FACILITY), "value") || [] // Existing values for already advertised facilities
    const facilities = initialPermissions
    .filter(permission => selectedPermissionIds.find(id => id == permission.value))
    .map(permission => {
      const alreadySelected = exisitingValues.find(selected => selected.value === permission.parkingFacilityId)
      return { name: permission.parkingFacility, value: permission.parkingFacilityId, selected: !!alreadySelected }
    })
    this.setAdvertisedValue(action, facilities)
    this.setState({ action, selectedPermissionIds })
  }

  onFormChange = (event, field) => {
    if (field.name === FIELD_PERMISSIONS_CAPACITIES) {
      const selectedPermissionIds = Array.prototype.map.call(event.target.options || [], option => option.value)
      this.handlePermissionCapacityChange(selectedPermissionIds)
    } else if (field.name === FIELD_PERMISSIONS_ID) {
      this.handlePermissionCollectionIdChange(event.target.value)
    } else if (field.name === FIELD_PERMISSION_MODE) {
      this.handlePermissionModeChange(event.target.value)
    }
  }

  // creating: fill the advertised facilities based on selected permissions
  handlePermissionCapacityChange = (selectedPermissionIds) => {
    const action = { ...this.state.action }
    const facilities = this.state.initialPermissions
    .filter(permission => selectedPermissionIds.find(id => id == permission.value))
    .map(permission => ({ name: permission.parkingFacility, value: permission.parkingFacilityId }))

    this.setAdvertisedValue(action, facilities)
    this.setState({ action, selectedPermissionIds })
  }

  // existing: fill the advertised facilities based on selected permission id
  handlePermissionCollectionIdChange = (permissionCollectionId) => {
    const action = { ...this.state.action }
    const permissionIdField = findByName(action.fields, FIELD_PERMISSIONS_ID)
    permissionIdField.value = permissionIdField.value
    .filter(v => v.value) // remove default option (the: "please select...")
    .map(v => {
      v.selected = v.value == permissionCollectionId
      return v
    })
    const facilities = get(permissionIdField.value.find(p => p.selected), "parking_facilities") || []
    this.setAdvertisedValue(action, facilities)
    this.setState({ action })
  }

  // if existing, set advertised to the existing parking facilities
  // if new, update the facilities with the last stored selected permissions
  handlePermissionModeChange = (mode) => {
    const action = { ...this.state.action }
    if (mode === "existing") {
      const permissionIds = get(findByName(action.fields, FIELD_PERMISSIONS_ID), "value") || []
      const selectedPermission = permissionIds.find(p => p.selected)
      const facilities = get(selectedPermission, "parking_facilities") || []
      this.setAdvertisedValue(action, facilities)
      this.setState({ action })
    } else {
      this.handlePermissionCapacityChange(this.state.selectedPermissionIds)
    }
  }

  setAdvertisedValue = (action, facilities) => {
    const advertised = findByName(action.fields, FIELD_ADVERTISED_FACILITY) || {}
    advertised.class = facilities.length === 0 ? addClass(advertised.class, "disabled") : removeClass(advertised.class, "disabled")
    advertised.value = facilities
  }

  render() {
    const { action } = this.state
    if (!action) {
      return null
    }

    return <FormRenderer action={action} onChange={this.onFormChange}/>
  }
}

export default ProductCreatorForm
