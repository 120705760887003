import React from "react"
import { Row, Col, Alert, Input, Label, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap"

import SirenEntityType from "api/SirenEntityType"
import { filterByClass, findByRelation, filterByRelation } from "api/utils"
import EntityButtonLink from "components/EntityButtonLink"
import Card from "components/Card"
import InfoBlock from "components/InfoBlock"
import { getFrontendTranslations, getEnvTitle } from "components/Layout/layoutManager"
import translate from "components/i18n/translate"
import PropertyTranslation from "components/PropertyTranslation"
import { setItem } from "storage"
import classnames from "classnames"


class ParkingFacilityPageGarageInfo extends React.Component {

  state = {
    type: null,
    activeTab: null,
  }

  componentDidMount() {
    setItem("type", this.state.type)
  }

  handleInputChange = (event) => {
    const { value } = event.target
    this.setState({ type: value })
    setItem("type", value)
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab })
    }
  }

  render() {
    const { entity } = this.props
    const productOfferEntities = filterByRelation(entity.entities, "productOffer")
    const showSelection = entity.properties.separateProductsByCustomerType
    const productCustomerTypes = this.state.type === null ? [...new Set(productOfferEntities.map(ent => {
      return ent.properties.customerType
    }))] : [...new Set(["any_customer_type"].concat(this.state.type))]
    const productOffers = filterByClass(entity.entities, "https://schema.evopark.com/ProductOffer")
    if (!productOffers.length) {
      return <Alert color="secondary" className="text-center text-main">{getFrontendTranslations() ? getFrontendTranslations().facility.noProduct : translate("facility.noProduct")}</Alert>
    }
    const values = [{ name: getFrontendTranslations() ? getFrontendTranslations().facility.person : translate("facility.person"), value: "person" }, { name: getFrontendTranslations() ? getFrontendTranslations().facility.company : translate("facility.company"), value: "company" }]

    var categories = []

    const productCategories = productOfferEntities.reduce((result, ent) => {
      if (ent.properties.categories) {
        ent.properties.categories.forEach(item => {
          categories.push(item)
        })
      }
      result = [...new Set(categories)]
      return result
    }, {})

    if (productCategories.length > 0) {
      if (this.state.activeTab == null) {
        if (getEnvTitle().indexOf("Ghent") !== -1 || getEnvTitle().indexOf("Mobiliteitsbedrijf") !== -1) {
          this.toggle(productCategories.find(product => product == "standaardtarief") || productCategories[0])
        } else {
          this.toggle(productCategories[0])
        }
      }
    }

    const PriceDetail = ({ propEntity, propertyName, price }) => {
      return (
        <div className="pb-2">
          <span className="text-capitalize font-weight-bold pr-1">
            <PropertyTranslation entity={propEntity} name={propertyName}/>
          </span>
          {price}
        </div>
      )
    }

    const Tariffs = ({ products }) => {
      var noProducts = true
      return products.map(pricing => {
        if (productCustomerTypes.includes(pricing.properties.customerType)) {
          noProducts = false
          const signupLink = findByRelation(pricing.links, "signup")
          return (
            <Col sm="6" lg="4" className={`mb-4 ${pricing.properties.id}`} key={pricing.selfLink}>
              <Card
                className="p-4 h-100 bg-light"
                cardBodyClassName="d-flex flex-column justify-content-between"
                title={pricing.title}
                img={pricing.properties.image}
                imgClass="pricing-image">
                {pricing.properties.description && <div>{pricing.properties.description}</div>}
                <div>
                  {
                    pricing.properties.duration &&
                    <div className="pb-2 text-secondary">
                      <PropertyTranslation entity={pricing} name="duration"/> {pricing.properties.duration}
                    </div>
                  }

                  {
                    pricing.properties.oneTimePrice &&
                      <PriceDetail propEntity={pricing} propertyName="oneTimePrice" price={pricing.properties.oneTimePrice} currency={pricing.properties.priceCurrency}/>
                  }
                  {
                    pricing.properties.setupFee &&
                      <PriceDetail propEntity={pricing} propertyName="setupFee" price={pricing.properties.setupFee} currency={pricing.properties.priceCurrency}/>
                  }
                  {
                    pricing.properties.price &&
                    <PriceDetail propEntity={pricing} propertyName="price" price={pricing.properties.price} currency={pricing.properties.priceCurrency}/>
                  }
                  {
                    signupLink
                      ? <EntityButtonLink entity={pricing} rel={["signup"]} color="primary" buttonClassName="px-4"/>
                      : <span className="text-info">{getFrontendTranslations() ? getFrontendTranslations().facility.unavailable : translate("facility.unavailable")}</span>
                  }
                </div>
              </Card>
            </Col>
          )
        } else {
          if (noProducts) {
            return <Alert color="secondary" className="text-center text-main ml-4 mt-2">{getFrontendTranslations() ? getFrontendTranslations().facility.noProduct : translate("facility.noProduct")}</Alert>
          }
        }
      })
    }

    return (
      <div className="text-center text-lg-left mt-4">
        { showSelection &&
          <Row className="mb-4">
            <Col md="3">
              <Label><strong>{getFrontendTranslations() ? getFrontendTranslations().facility.signupType : translate("facility.signupType")}:</strong></Label>
            </Col>
            <Col md="9">
              {
                values.map(option => (
                  <span key={`${option.name}-${option.value}`} className="m-3 p-4">
                    <Input
                      type="radio"
                      id={option.value}
                      name={option.name}
                      value={option.value}
                      onChange={this.handleInputChange}
                      checked={this.state.type === option.value}
                    />
                    <Label className="form-check-label" htmlFor={option.value}>{option.name}</Label>
                  </span>
                ))
              }
            </Col>
          </Row>
        }
        <InfoBlock>
          { productCategories.length == 0 ? <Row>
            <Tariffs products={productOffers}/>
          </Row> : <div>
            <Nav tabs className="tariffs">
              {productCategories.map(category => (
                <NavItem key={category}>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === category })}
                    onClick={() => { this.toggle(category) }}
                  >
                    <span className="text-capitalize">{category}</span>
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              {productCategories.map(category => {
                const filteredProducts = productOffers.filter(product => product.properties.categories.indexOf(category) > -1)
                return (
                  <TabPane tabId={category} key={category}>
                    <Row>
                      <Tariffs products={filteredProducts}/>
                    </Row>
                  </TabPane>
                )})}
            </TabContent>
          </div>
          }
        </InfoBlock>
        <style jsx>{`
          :global(.pricing-image) {
            max-height: 200px;
          }
          :global(.tariffs .nav-item) {
            color: gray !important;
            font-weight: bold;
          }
          :global(.tariffs .nav-item a:active) {
            color: black !important;
          }
          :global(.tariffs .nav-item a:hover) {
            color: black !important;
            pointer: cursor;
          }
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            :global(.pricing-image) {
              align-self: flex-start;
            }
         }
        `}</style>
      </div>
    )
  }
}
ParkingFacilityPageGarageInfo.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default ParkingFacilityPageGarageInfo
