import React from "react"
import PropTypes from "prop-types"

import SirenEntityType from "api/SirenEntityType"
import ActionsRenderer from "components/TableRenderer/ActionsRenderer"
import FormSubmissionWrapper from "components/FormRenderer/FormSubmissionWrapper"

/*
  This is a generic page for forms. If there are any specialized pages like
  Login and Signup they will not be tackled with this. We will see if all the
  forms have a pattern and if there is one we will keep adding them here.
*/
const FormPage = ({ entity, onActionResolve }) => (
  entity.actions.length
    ? <FormSubmissionWrapper onActionResolve={onActionResolve}>
      <ActionsRenderer actions={entity.actions} className="mx-4"/>
    </FormSubmissionWrapper>
    : null
)
FormPage.propTypes = {
  entity: SirenEntityType.isRequired,
  onActionResolve: PropTypes.func,
}

export default FormPage
