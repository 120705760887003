import React from "react"
import PropTypes from "prop-types"

const SelectAllBarContent = ({ text, action, onClick, selectedCount }) => (
  <div className="d-flex select-all-bar">
    {selectedCount > 0 && <span>{text}</span>}
    &nbsp;
    <span className="btn-link clickable" onClick={onClick}>{action}</span>
    <style jsx>{`
        .select-all-bar {
          justify-content: center;
          padding: 0.5rem;
          background-color: lightgrey;
          border-radius: 5px;
          margin-bottom: 3px;
        }

        .select-all-bar .clickable {
          cursor: pointer;
        }
      `}</style>
  </div>
)
SelectAllBarContent.propTypes = {
  text: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selectedCount: PropTypes.number,
}

export default SelectAllBarContent
