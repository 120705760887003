import React from "react"

const minimizeSidebar = () => {
  document.body.classList.toggle("sidebar-minimized")
  document.body.classList.toggle("brand-minimized")
}

const SidebarMinimizer = () =>
  <button
    className="sidebar-minimizer"
    type="button"
    onClick={minimizeSidebar}>
  </button>

export default SidebarMinimizer
