import React from "react"

import SirenEntityType from "api/SirenEntityType"
import TextBlock from "components/pages/public/LandingPage/TextBlock"
import { findByClassStrict } from "api/utils"
import { TextBlockClass } from "components/registry"

const PasswordResetSuccess = ({ entity }) => {
  const textBlock = findByClassStrict(entity.entities, TextBlockClass)
  return (
    <div className="container h-100 p-4 margin mark">
      <TextBlock entity={textBlock} className="row h-100 justify-content-center align-items-center p-4"/>
      <style jsx>{`
        .margin {
          margin-top: 5em;
        }
        `}</style>
    </div>
  )}

PasswordResetSuccess.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default PasswordResetSuccess
