export const FORM_INLINE_CLASS = "form-inline"
export const SPLIT_NEW_LINE = "split-newline"

export const getColSize = (classes = []) => classes.indexOf("full") > -1 ? 12 : 6

export const is = name => (classes = []) => classes.indexOf(name) > -1

export const isRequired = is("required")
export const hasTooltip = is("tooltip")
export const isDiscriminator = is("field-discriminator")
export const isDiscriminatorByCategory = is("field-discriminator-category")
export const isInline = is("inline")
export const isDisabled = is("disabled")
export const isMultipleSelect = is("multiple")
export const isWysiwyg = is("wysiwyg")
export const isAside = is("aside")
export const isLoading = is("loading")
export const isSplitNewLine = is(SPLIT_NEW_LINE)
export const isWarning = is("warning")
export const isWithGenerator = is("with-generator")
export const isStrictMode = is("strict-mode")

const isAcceptingImage = is("file-accept-images")
const isAcceptingXlsx = is("file-accept-xlsx")
const isAcceptingXls = is("file-accept-xls")

export const formatFormClassName = (className) => className.replace("inline", FORM_INLINE_CLASS)

const getAbstract = (name) => (classes) => {
  const abstractBy = (classes || []).find(klass => klass.indexOf(name) !== -1)
  return abstractBy ? abstractBy.replace(name, "") : null
}
export const getGroupBy = getAbstract("groupBy-")
export const getLimitBy = getAbstract("limitBy-")
export const getNotification = getAbstract("notification-")
export const getRows = getAbstract("rows-")

export const getAutocomplete = (classes = []) =>
  classes.reduce((result, klass) => {
    const found = klass.match(/autocomplete-?(\S+)?/)
    found && result.push(found[1] || "on")
    return result
  }, [])
  .join(" ") || null

export const getDisplayRules = (classes = []) => (
  classes.reduce((rules, name) => {
    const keyMatch = name.match(/^if-([^-]*)-?(.*)/)
    if (keyMatch && keyMatch[1]) {
      rules.push(keyMatch[2] ? { criteria: keyMatch[1], value: keyMatch[2] } : keyMatch[1])
    }
    return rules
  }, [])
)

export const getDiscriminatorKey = (classes = [], name, category) => (
  isDiscriminatorByCategory(classes) ? `${name}-${category}` : name
)

export const safeArray = (array = []) =>
  array !== null
    ? Array.isArray(array) ? array : [array]
    : []

export const addClass = (classes, className) => {
  const result = safeArray(classes)
  result.indexOf(className) === -1 && result.push(className)
  return result
}

export const removeClass = (classes, className) => safeArray(classes).filter(c => c !== className)

export const getAcceptAttribute = (classes = []) => {
  const accept = []
  isAcceptingImage(classes) && accept.push("image/x-png", "image/jpeg")
  isAcceptingXlsx(classes) && accept.push("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
  isAcceptingXls(classes) && accept.push("application/vnd.ms-excel")
  return accept.join(",")
}
