import React from "react"

import SirenEntityType from "api/SirenEntityType"
import FontAwesomeIcon from "components/FontAwesome/FontAwesomeIcon"

const ParkingFacilityFeature = ({ entity }) => {
  let result = null
  if (entity.properties.value) {
    result = <div>{entity.title}: {entity.properties.value}</div>
  } else if (entity.properties.image) {
    result = <div className="d-lg-inline">
      <FontAwesomeIcon title={entity.title} iconURI={entity.properties.image} fontSize="20px" width="20px" className="mr-4 mt-2"/>
      <span className="d-lg-none"> {entity.title}</span>
    </div>
  }

  return result
}

ParkingFacilityFeature.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default ParkingFacilityFeature
