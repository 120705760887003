import React from "react"
import PropTypes from "prop-types"

import Context from "components/FormRenderer/formSubmissionContext"

const FormSubmissionWrapper = ({ onActionResolve, children }) => (
  <Context.Consumer>
    { ({ onEntityResolve }) => <Context.Provider value={{ onEntityResolve, onActionResolve }}>
      { children }
    </Context.Provider> }
  </Context.Consumer>
)

FormSubmissionWrapper.propTypes = {
  onActionResolve: PropTypes.func,
  children: PropTypes.node,
}

export default FormSubmissionWrapper
