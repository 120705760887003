import React from "react"
import PropTypes from "prop-types"
import { setLocale } from "../api/api"
import "flag-icons/css/flag-icons.min.css"

const PROPTYPES = {
  actions: PropTypes.array,
}

const handleSetLocale = (e) => {
  e.preventDefault()
  const data = e.target.dataset
  setLocale({ href: data.apiUrl, method: data.apiMethod })
}

const LocaleSelect = ({ actions }) => {
  return actions.map(action => {
    const locale = action.locale.toLowerCase().split("-")[1]
    // console.log(JSON.stringify(action))
    //{"name":"locale-de-DE","class":["locale"],"fields":[],"href":"http://localhost:3000/hanova-admin.evopark.de.local/users/32909/locale?locale=de-DE","method":"PATCH","title":"Deutsch","type":"application/json","locale":"de-DE"}
    return <a
      key={action.href}
      data-api-url={action.href}
      data-api-method={action.method}
      href="#"
      onClick={handleSetLocale}
      className="dropdown-item">
      <span className={`fi fi-${locale} mr-3`}></span>
      {action.title}
    </a>
  })
}

LocaleSelect.propTypes = PROPTYPES
export default LocaleSelect
