import React from "react"

import SirenEntityType from "api/SirenEntityType"
import InfoBlock from "components/InfoBlock"
import PropertyTranslation from "components/PropertyTranslation"

const OpeningTimes = ({ entity }) => {
  if (!entity.properties.openingHours) {
    return null
  }

  return (
    <InfoBlock title={<PropertyTranslation entity={entity} name="openingHours"/>}>
      <div className="opening-hours">{entity.properties.openingHours}</div>
      <style jsx>{`
        .opening-hours {
          white-space: pre-line;
        }
      `}</style>
    </InfoBlock>
  )
}

OpeningTimes.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default OpeningTimes
