import React from "react"
import PropTypes from "prop-types"

import EntityType, { ActionsType } from "api/SirenEntityType"

import registry from "components/registry"
import { filterByClass, resolveLinks } from "api/utils"

import Card from "components/Card"
import PropertiesBox from "components/PropertiesBox"
import ActionsRenderer from "components/TableRenderer/ActionsRenderer"
import Link from "components/Link"

const resolveActions = (entity, actionNames = []) => {
  if (actionNames.indexOf("all") !== -1) {
    return entity.actions
  }
  return entity.actions.filter(action => actionNames.indexOf(action.name) !== -1)
}

const resolveSubEntites = (entities = [], entitiesConfig = []) => (
  entitiesConfig.reduce((loadedConfiguration, config) => {
    const filteredEntities = filterByClass(entities, config.class)
    filteredEntities.forEach(entity => {
      if (entity) {
        config = { ...config, entity: entity }
        loadedConfiguration.push(config)
      }
    })
    return loadedConfiguration
  }, [])
)

/*
  TableDetailView currently renders properties and actions. I am
  trying to find out a good pattern for all views so we can reuse
  stuff
*/
const TableDetailView = ({ entity, title = "", keys = "", actions = [], children = null }) => {
  let subEntitiesConfigs, links = []
  if (!keys || actions.length === 0) {
    const config = registry.getConfigForClass(entity.class)
    keys = keys || config.properties
    actions = (actions && actions.length) ? actions : resolveActions(entity, config.actionNames)
    subEntitiesConfigs = resolveSubEntites(entity.entities, config.entities)
    links = resolveLinks(entity.links, config.linkRelations)
  }

  return <Card header={title || entity.title}>
    {children}
    <PropertiesBox entity={entity} keys={keys} label/>
    { subEntitiesConfigs.map(config => (
      <PropertiesBox key={config.entity.key} entity={config.entity} keys={config.properties} label/>
    )) }
    { links && links.map(link => (
      <div key={link} className="align-self-center mt-4">
        <Link className="btn btn-secondary" link={link}>{link.title}</Link>
      </div>
    ))}
    { !!actions.length && <hr/> }
    <ActionsRenderer actions={actions}/>
    <style jsx global>{`
      .actions {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
      .actions div {
        border: none;
      }
      .actions form > div {
        padding:  0px !important;
        margin-bottom:  0px;
      }
      .actions div > h5 {
        display: none;
      }
    `}</style>
  </Card>
}

TableDetailView.propTypes = {
  entity: EntityType.isRequired,
  actions: ActionsType,
  keys: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
}

export default TableDetailView
