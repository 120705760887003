import PropTypes from "prop-types"
import { findByRelationStrict } from "api/utils"
import EntityType from "api/SirenEntityType"

const PropertyTranslation = ({ entity = {}, name }) => {
  const i18nEntity = findByRelationStrict(entity.entities, ["i18n"])
  return (i18nEntity && i18nEntity.properties[name]) || null
}

PropertyTranslation.propTypes = {
  entity: EntityType.isRequired,
  name: PropTypes.string,
}

export default PropertyTranslation
