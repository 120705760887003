
import React, { PureComponent } from "react"

import SirenEntityType from "api/SirenEntityType"
import withEntity from "components/withEntity"

import { findByClass, findByRelationStrict } from "api/utils"
import { findByName } from "api/actions"
import { InvoiceListClass, BookingRequestListClass, ContractBookingListClass, ParkingTransactionListClass, CustomerAndMediaContractMappingListClass } from "components/registry"
import EntityRenderer from "components/EntityRenderer/EntityRenderer"
import TableRenderer from "components/TableRenderer/TableRenderer"
import Card from "components/Card"
import PropertiesBox from "components/PropertiesBox"
import ActionToggle from "components/ActionToggle"
import PropertyTranslation from "components/PropertyTranslation"
import getCustomKeys from "components/getCustomKeys"
import CreditCardRenderer from "components/CreditCardRenderer/CreditCardRenderer"
import { getEnvTitle } from "components/Layout/layoutManager"

class CustomerAccountTableView extends PureComponent {
  render() {
    const { entity } = this.props
    const addressEntity = findByRelationStrict(entity.entities, ["address"])
    const deliveryAddressEntity = findByRelationStrict(entity.entities, ["deliveryAddress"])
    const directDebitPaymentMethodEntity = findByRelationStrict(entity.entities, ["directDebitPaymentMethod"])
    const trustcommercePaymentMethodEntity = findByRelationStrict(entity.entities, ["trustcommercePaymentMethod"])
    const pgsPaymentMethodEntity = findByRelationStrict(entity.entities, ["pgsPaymentMethod"])
    const cardPaymentMethodEntity = findByRelationStrict(entity.entities, ["cardPaymentMethod"])
    const invoicesListEntity = findByClass(entity.entities, InvoiceListClass)
    const customerAndMediaContractMappingEntity = findByClass(entity.entities, CustomerAndMediaContractMappingListClass)
    const bookingRequestListEntity = findByClass(entity.entities, BookingRequestListClass)
    const contractBookingListEntity = findByClass(entity.entities, ContractBookingListClass)
    const parkingTransactionListEntity = findByClass(entity.entities, ParkingTransactionListClass)

    const updateCustomerAction = findByName(entity.actions, "update-customer")
    const updateDirectDebitPaymentMethodAction = findByName(entity.actions, "update-direct-debit-payment-method")
    const updateAddressAction = findByName(entity.actions, "update-address")
    const updateNotesAction = findByName(entity.actions, "update-notes")
    const customKeys = entity && getCustomKeys(entity.properties, "custom")

    return (
      <React.Fragment>
        <EntityRenderer entity={entity} properties={"customerNumber,name,contact_name,contact_department,email,secondaryEmail,telephone," + `${customKeys}`} action={updateCustomerAction}/>
        <Card header={<PropertyTranslation entity={entity} name="notes"/>}>
          <span>{entity.properties.notes}</span>
          <ActionToggle action={updateNotesAction}/>
        </Card>
        { addressEntity && <Card>
          <PropertiesBox
            entity={addressEntity}
            keys="streetAddress,addressLocality,addressRegion,postalCode,addressCountry"
            label/>
          <PropertiesBox
            entity={deliveryAddressEntity}
            keys="streetAddress,addressLocality,addressRegion,postalCode,addressCountry"
            label/>
          <ActionToggle action={updateAddressAction}/>
        </Card> }
        <EntityRenderer
          entity={directDebitPaymentMethodEntity}
          properties="accountHolder,bic,iban,creditor_identifier,mandateIdentification"
          action={updateDirectDebitPaymentMethodAction}/>
        { trustcommercePaymentMethodEntity &&
        <CreditCardRenderer entity={trustcommercePaymentMethodEntity} properties="name,exp,cc,last_payment_status"/>
        }
        { pgsPaymentMethodEntity &&
        <CreditCardRenderer entity={pgsPaymentMethodEntity} properties="card_number,exp,last_payment_status"/>
        }
        { cardPaymentMethodEntity &&
        <CreditCardRenderer entity={cardPaymentMethodEntity} properties="name,exp,cc,last_payment_status"/>
        }
        { (invoicesListEntity && getEnvTitle().indexOf("Boston") === -1) &&
          <TableRenderer
            entity = {invoicesListEntity}
            className = "customer-account invoices"
            rowClick={true}
            selectRow={false}
            search={false}
            pagination={false}
            isSecondaryTable={true}
            exportCSV={false}/> }
        { (customerAndMediaContractMappingEntity && getEnvTitle().indexOf("Boston") === -1) &&
          <TableRenderer
            entity = {customerAndMediaContractMappingEntity}
            className = "customer-account customer-and-media-contract-mappings"
            rowClick={true}
            selectRow={false}
            search={false}
            pagination={false}
            isSecondaryTable={true}
            exportCSV={false}/> }
        { bookingRequestListEntity &&
          <TableRenderer
            entity={bookingRequestListEntity}
            className="customer-account booking-requests"
            rowClick={true}
            selectRow={false}
            search={false}
            pagination={false}
            isSecondaryTable={true}
            exportCSV={false}/> }
        { contractBookingListEntity &&
          <TableRenderer
            entity={contractBookingListEntity}
            className="customer-account contract-bookings"
            rowClick={true}
            selectRow={false}
            search={false}
            pagination={false}
            isSecondaryTable={true}
            exportCSV={false}/> }
        { parkingTransactionListEntity &&
          <TableRenderer
            entity={parkingTransactionListEntity}
            className="customer-account parking-transactions"
            rowClick={true}
            selectRow={false}
            search={false}
            pagination={false}
            isSecondaryTable={true}
            exportCSV={false}/> }

        <style jsx global>{`
          .customer-account {
            margin: 0px !important
          }
        `}</style>
      </React.Fragment>
    )
  }
}

CustomerAccountTableView.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default withEntity(CustomerAccountTableView, {
  entities: ["invoices", "bookingRequests", "contractBookings", "address", "deliveryAddress", "directDebitPaymentMethod", "parkingTransactions", "customerAndMediaContractMapping"],
})
