import React from "react"

import SirenEntityType from "api/SirenEntityType"
import DownloadButtons from "components/DownloadButtons"
import PropertiesBox from "components/PropertiesBox"
import { findByClass, filterByRelationStrict } from "api/utils"
import { CustomerClass, BookingChangeListClass, ContractBookingListClass } from "components/registry"
import Address from "components/pages/admin/BookingRequest/Address"
import TableRenderer from "components/TableRenderer/TableRenderer"
import Card from "components/Card"
import getCustomKeys from "components/getCustomKeys"
import withEntity from "components/withEntity"
import PropTypes from "prop-types"

const CustomerBooking = ({ entity, layoutName }) => {
  const customerEntity = findByClass(entity.entities, CustomerClass)
  const bookingChangeListEntity = findByClass(entity.entities, BookingChangeListClass)
  const customKeys = customerEntity && getCustomKeys(customerEntity.properties, "custom")
  const contractBookingListEntity = findByClass(entity.entities, ContractBookingListClass)
  const downloadLinks = filterByRelationStrict(entity.links, ["alternate", "download-link"])

  return (
    <>
      <Card header={entity.title} headerClass={`${layoutName == "customerSelfService" ? "customer-" : ""}BookingRequest`}>
        <PropertiesBox
          entity={customerEntity}
          keys={"customerNumber,name,contact_name,contact_department,email,secondaryEmail,telephone,vatNumber," + `${customKeys}`}
          label/>
        <Address entity={customerEntity}/>
        <PropertiesBox
          entity={entity}
          keys = "customerOnboardingField,validFrom,validUntil,daysSinceRequest,parkingFacilityName,parkingProductName,remarks,parkingProductPaymentMode,paymentMethodTypeForDisplay,paymentMethodShortDescriptor,oneTimeFee,initialBillingAmount,recurringFee,payrollCycle,initiallyRequestedParkingSpaceCount,currentParkingSpaceCount,initiallyRequestedVehicleCount,currentVehicleCount,productCategories,productDuration,costCenter"
          label/>
        <DownloadButtons links={downloadLinks}/>
        <style jsx global>{`
            .actions {
              position: relative;
            }
            .actions > form:nth-child(2) {
              position: absolute;
              right: 0;
              bottom: 0;
            }
            .actions div {
              border: none;
            }
            .actions form > div {
              padding:  0px !important;
              margin-bottom:  0px;
            }
            .actions div > h5 {
              display: none;
            }
            .actions div.p-4.card:not(:first-child) div > h6 {
              display: none;
            }
            .no-margin {
              margin: 0px !important;
            }
            .toggled-action .actions {
              margin-top: 0px;
            }
            .mixed-actions > .toggled-action > div > button {
              position: absolute;
              top: -7.8rem;
              margin-top: 3.35rem;
              right: 0;
              margin-right: 1.25rem;
            }
          `}
        </style>
      </Card>
      { contractBookingListEntity &&
      <TableRenderer
        entity={contractBookingListEntity}
        className="no-margin"
        rowClick
        selectRow={false}
        search={false}
        pagination={false}
        isSecondaryTable={true}
        exportCSV={false}
      />
      }
      { bookingChangeListEntity &&
        <TableRenderer
          entity={bookingChangeListEntity}
          className="no-margin"
          rowClick={false}
          selectRow={false}
          search={false}
          pagination={false}
          isSecondaryTable={true}
          exportCSV={false}
        />
      }
    </>
  )
}

CustomerBooking.propTypes = {
  entity: SirenEntityType.isRequired,
  layoutName: PropTypes.string,
  currentUrl: PropTypes.string,
  prevUrl: PropTypes.string,
}
export default withEntity(CustomerBooking, {
  entities: ["customer", "bookingChanges", "contractBookings"],
})
