import React from "react"
import PropTypes from "prop-types"

import { getFrontendTranslations } from "components/Layout/layoutManager"
import translate from "components/i18n/translate"

const RequiredTooltip = ({ required }) => (
  required
    ? <span className="text-danger" title={getFrontendTranslations() ? getFrontendTranslations().action.required : translate("action.required")}>
       *
    </span>
    : null
)
RequiredTooltip.propTypes = {
  required: PropTypes.bool,
}

export default RequiredTooltip
