import React from "react"
import { Row, Col } from "reactstrap"

import SirenEntityType from "api/SirenEntityType"
import { mapEntitiesByClass } from "api/entities"
import OpeningTimes from "components/pages/public/ParkingFacilityPage/OpeningTimes"
import EntityButtonLink from "components/EntityButtonLink"
import InfoBlock from "components/InfoBlock"
import PropertyTranslation from "components/PropertyTranslation"
import { PostalAddressClass, ParkingFacilityContactClass, LocationFeatureSpecificationClass } from "components/registry"

// later on, the default image will be returned by the API directly
const defaultImage = "https://images.unsplash.com/photo-1474408886716-087263db7da1?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=604c0952fd2247208e256d19014f4196&auto=format&fit=crop&w=1053&q=80"

const ParkingFacilityPageGarageInfo = ({ entity }) => (
  <Row className="pt-4 pb-4">
    <Col lg="4">
      <img src={entity.properties.image || defaultImage} className="img-fluid mx-auto d-block"/>
    </Col>
    <Col lg="8">
      <Row>
        <Col sm="6" md="4">
          {mapEntitiesByClass(entity.entities, [PostalAddressClass, ParkingFacilityContactClass])}
          <EntityButtonLink entity={entity} rel={["navigate"]} target="_blank" buttonClassName="px-4 mt-4"/>
        </Col>
        {
          entity.properties.openingHours &&
          <Col sm="6" md="4">
            <OpeningTimes entity={entity}/>
          </Col>
        }
        <Col sm="6" md="4">
          <InfoBlock title={<PropertyTranslation entity={entity} name="amenityFeature"/>}>
            {entity.properties.headroom && <div><PropertyTranslation entity={entity} name="headroom"/>: {entity.properties.headroom} {entity.properties.headroomUnit}</div>}
            <div>{mapEntitiesByClass(entity.entities, LocationFeatureSpecificationClass)}</div>
          </InfoBlock>
        </Col>
      </Row>
    </Col>
  </Row>
)

ParkingFacilityPageGarageInfo.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default ParkingFacilityPageGarageInfo
