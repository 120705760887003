import React, { Component } from "react"
import { TaskQueue } from "cwait"
import Dropzone from "components/Dropzone/Dropzone"
import "./Upload.css"
import Progress from "components/Progress/Progress"
import PropTypes from "prop-types"
import { Button, Form } from "reactstrap"
import api from "api/api"
import withFormSubmission from "components/FormRenderer/withFormSubmission"
import withFormContext from "components/FormRenderer/withFormContext"
import { getActionEntityStatus } from "components/FormRenderer/actionEntity"
import FailedFiles from "./FailedFiles"
import { getFrontendTranslations } from "components/Layout/layoutManager"
import translate from "components/i18n/translate"

const MAX_SIMULTANEOUS_UPLOADS = 5
const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_UPLOADS)

class Upload extends Component {

  static propTypes = {
    url: PropTypes.string,
    onActionResolve: PropTypes.func,
  }

  state = {
    files: [],
    retryFiles: [],
    uploading: false,
    uploadProgress: {},
    completedUpload: false,
    uploadedPercentage: 0,
    failedPercentage: 0,
    uploadedCount: 0,
    failedCount: 0,
    uploadFailedFiles: [],
    uploadSucceededFiles: [],
  }

  onFilesAdded = files => {
    this.setState(prevState => {
      if (prevState.files.length < 1) {
        return { files: prevState.files.concat(files), completedUpload: false }
      }
      else {
        let filesList = prevState.files
        files.map(file => {
          filesList.filter(e => e.name === file.name).length > 0
            ? filesList = filesList.filter(e => e.name != file.name).concat(file)
            : filesList = filesList.concat(file)
        })
        return { files: filesList, completedUpload: false }
      }
    })
  }

  uploadFiles = async event => {
    event.preventDefault()
    this.setState({ uploadProgress: {}, uploading: true })
    try {
      await Promise.all(this.state.files.map(queue.wrap(file => this.sendRequest(file))))
      this.setState({ completedUpload: true, uploading: false })
    } catch (e) {
      this.setState({ completedUpload: true, uploading: false })
    }
  }

  sendRequest = file => {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append("pdf", file, file.name)
      const href = this.props.url
      const method = "POST"
      const data = formData
      const headers = {
        "Content-Type": "multipart/form-data",
      }

      api({ href, method, data, headers })
      .then((result) => {
        const { entity } = result

        this.setState(prevState => {
          const copy = { ...prevState.uploadProgress }
          copy[file.name] = { state: "done", percentage: 100 }
          const isDuplicateFile = prevState.uploadSucceededFiles.filter(succeededFile => succeededFile.file == file.name).length > 0
          const uploadedCount = isDuplicateFile ? prevState.uploadedCount : prevState.uploadedCount + 1
          const uploadedPercentage = (uploadedCount / prevState.files.length) * 100
          const uploadSucceededFiles = isDuplicateFile
            ? prevState.uploadSucceededFiles.filter(e => e.file != file.name).concat([{ file: file.name }])
            : prevState.uploadSucceededFiles.concat([{ file: file.name }])
          const retryFiles = [...new Set(prevState.retryFiles.filter(e => e != file))]
          return { uploadedCount, uploadedPercentage, uploadProgress: copy, uploadSucceededFiles, retryFiles }
        })

        this.props.onActionResolve(entity)
        resolve(entity)

      }).catch(error => {
        this.setState(prevState => ({
          retryFiles: error.type && (error.type == "https://schema.evopark.com/errors/timeout" || error.type == "https://schema.evopark.com/errors/offline")
            ? [...new Set(prevState.retryFiles.concat(file))]
            : [...new Set(prevState.retryFiles.filter(e => e != file))],
        }))
        const errorEntity = getActionEntityStatus(error)

        this.setState(prevState => {
          const copy = { ...prevState.uploadProgress }
          copy[file.name] = { state: "error", percentage: 0 }
          const isDuplicateFile = prevState.uploadFailedFiles.filter(failedFile => failedFile.file == file.name).length > 0
          const failedCount = isDuplicateFile ? prevState.failedCount : prevState.failedCount + 1
          const failedPercentage = (failedCount / prevState.files.length) * 100
          const uploadFailedFiles = isDuplicateFile
            ? prevState.uploadFailedFiles.filter(e => e.file != file.name).concat([{ file: file.name, error: errorEntity ? errorEntity.properties.description : error.title }])
            : prevState.uploadFailedFiles.concat([{ file: file.name, error: errorEntity ? errorEntity.properties.description : error.title }])
          return { failedCount, failedPercentage, uploadProgress: copy, uploadFailedFiles }
        })

        this.props.onActionResolve(error)
        reject(error)
      })
    })
  }



  render() {
    return (
      <Form onSubmit={this.uploadFiles}>
        <div className="upload">
          <div className="upload-content">
            <div>
              <Dropzone
                onFilesAdded={this.onFilesAdded}
                disabled={this.state.uploading || this.state.completedUpload}
              />
            </div>
            <div className="files">
              <div className="files-row">
                {
                  this.state.files.length > 0 && <div className="filename">{translate("fileUpload.clear") ? getFrontendTranslations().fileUpload.totalSelected.replace("%{total}", `${this.state.files.length}`) : translate("fileUpload.totalSelected", { total: this.state.files.length })}</div>
                }
                { (this.state.uploading || this.state.completedUpload) &&
                  <div>
                    <Progress uploadedPercentage={this.state.uploadedPercentage} failedPercentage={this.state.failedPercentage}/>
                  </div>
                }
              </div>
              { this.state.files.length > 0 &&
                <div className="pl-2 pt-2">
                  {
                    this.state.uploadedCount > 0 && <div className="filename">{getFrontendTranslations() ? getFrontendTranslations().fileUpload.successfulUpload.replace("%{total}", `${this.state.uploadedCount}`) : translate("fileUpload.successfulUpload", { total: this.state.uploadedCount })}</div>
                  }
                  {
                    this.state.failedCount > 0 && <div className="filename">{getFrontendTranslations() ? getFrontendTranslations().fileUpload.failedUpload.replace("%{total}", `${this.state.failedCount}`) : translate("fileUpload.failedUpload", { total: this.state.failedCount })}</div>
                  }
                  <ul><FailedFiles uploadFailedFiles={this.state.uploadFailedFiles}/></ul>
                </div>
              }
            </div>
          </div>
          <div className="upload-actions">
            <div>
              <Button
                type="submit"
                color="btn btn-primary"
                disabled={this.state.files.length <= 0 || this.state.uploading || (this.state.files.length > 0 && this.state.retryFiles.length <= 0 && this.state.completedUpload)}
                className="m-2"
              >
                {this.state.retryFiles.length ? (getFrontendTranslations() ? getFrontendTranslations().fileUpload.reUpload : translate("fileUpload.reUpload")) : (getFrontendTranslations() ? getFrontendTranslations().fileUpload.upload : translate("fileUpload.upload"))}
              </Button>
              <Button
                color="btn btn-primary"
                onClick={() =>
                  this.setState({ files: [], retryFiles: [], completedUpload: false, uploadFailedFiles: [], failedCount: 0 })
                }
                disabled={this.state.files.length <= 0}
              >
                {getFrontendTranslations() ? getFrontendTranslations().fileUpload.clear : translate("fileUpload.clear")}
              </Button>
            </div>
          </div>
        </div>
      </Form>
    )
  }
}

export default withFormContext(withFormSubmission(Upload))
