import React, { lazy } from "react"

import EntityType from "api/SirenEntityType"

import { filterByRelation } from "api/utils"
const TableRenderer = lazy(() => import("components/TableRenderer/TableRenderer"))

const DESCRIBED_BY = "describedby"

const filterEntitiesByRelation = (entities = [], relation, exclusion) => (
  entities.filter(entity => (
    entity.rel.indexOf(relation) > -1 && entity.rel.indexOf(exclusion) === -1
  ))
)

export const extractEntities = (entities = []) => {
  const columnDefinitionEntites = filterByRelation(entities, DESCRIBED_BY)

  return columnDefinitionEntites.reduce((result, columnDefinitionEntity) => {
    const relations = [...new Set(columnDefinitionEntity.rel)]
    const filteredRelations = relations.filter(relation => relation !== DESCRIBED_BY)
    const filteredEntities = filteredRelations.reduce((subEntities, relation) => {
      const relatedEntities = filterEntitiesByRelation(entities, relation, DESCRIBED_BY)
      return subEntities.concat(relatedEntities)
    }, [columnDefinitionEntity])
    result.push({ title: columnDefinitionEntity.title, entities: filteredEntities, key: columnDefinitionEntity.key })
    return result
  }, [])
}

const MultiTableRenderer = ({ entity }) => {
  const extractedEntities = extractEntities(entity.entities)
  return extractedEntities && extractedEntities.map(({ title, key, entities }) => {
    if (!entities || (entities && entities.length <= 1)) {
      return null
    }
    const modifiedEntity = { ...entity, entities, title }
    return <TableRenderer key={key} entity={modifiedEntity}/>
  })
}

MultiTableRenderer.propTypes = {
  entity: EntityType.isRequired,
}

export default MultiTableRenderer
