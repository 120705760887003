import React from "react"
import PropTypes from "prop-types"
import Link from "components/Link"

import {
  ButtonGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card as ReactStrapCard,
  CardTitle,
  CardSubtitle,
  CardBody,
  CardHeader,
} from "reactstrap"

import { ActionType, LinkType } from "api/SirenEntityType"

class ActionMenu extends React.PureComponent {
  static propTypes = {
    onSelect: PropTypes.func,
    title: PropTypes.string,
    actions: PropTypes.arrayOf(ActionType),
  }

  static defaultProps = {
    title: "Actions",
  }

  state = {
    open: false,
  }

  toggle = () => this.setState(state => ({ open: !state.open }))

  select = () => this.props.onSelect && this.props.onSelect()

  render() {
    const { title, actions } = this.props
    return (
      <ButtonDropdown
        isOpen={this.state.open}
        toggle={this.toggle}>
        <DropdownToggle caret>{title}</DropdownToggle>
        <DropdownMenu right>
          { actions.map(action => <DropdownItem key={action.name}>{action.title || action.name}</DropdownItem>) }
        </DropdownMenu>
      </ButtonDropdown>
    )
  }
}

const Card = ({ header, title, subTitle, actions, links = [], children, img = null, className, cardBodyClassName = "", onClick, imgClass, headerClass = "" }) => (
  <ReactStrapCard className={className} onClick={onClick}>
    {header && <CardHeader className={headerClass}>
      <strong>{header}</strong>
      {!!links.length && <div className="card-header-actions">
        {
          links.map(link => (
            <Link link={link} key={link.href} className="card-header-action">{link.title}</Link>
          ))
        }
      </div>}
    </CardHeader>}
    <img src={img} className={`img-fluid mx-auto d-block px-4 pb-2 ${imgClass}`}/>
    <CardBody className={`h-100 ${cardBodyClassName}`}>
      {(title || (actions && !!actions.length)) && (
        <CardTitle>
          {title}
          <ButtonGroup className="float-right">
            { (actions && !!actions.length) && <ActionMenu actions={actions}/> }
          </ButtonGroup>
        </CardTitle>
      )}
      { subTitle && <CardSubtitle className="text-muted mb-4">{subTitle}</CardSubtitle> }
      { children }
    </CardBody>
    <style jsx>{`
      /* Global is a HACK but it does not work otherwise */
      :global(.card .card-footer a:nth-child(n+2):before) {
        content: " | ";
      }
      :global(.hidden) {
        display: none;
      }
    `}</style>
  </ReactStrapCard>
)
Card.propTypes = {
  header: PropTypes.node,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  actions: PropTypes.arrayOf(ActionType),
  links: PropTypes.arrayOf(LinkType),
  children: PropTypes.node,
  className: PropTypes.string,
  imgClass: PropTypes.string,
  cardBodyClassName: PropTypes.string,
  img: PropTypes.node,
  onClick: PropTypes.func,
  headerClass: PropTypes.string,
}

export default Card
