import React from "react"
import SirenEntityType from "api/SirenEntityType"
import FinaliseRequestOffer from "components/pages/public/Account/Signup/FinaliseRequestOffer"

const Waitlist = ({ entity = {} }) => <FinaliseRequestOffer entity={entity} actionName="signup"/>
Waitlist.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default Waitlist
