import React from "react"

import EntityType from "api/SirenEntityType"
import Card from "components/Card"
import PropertiesBox from "components/PropertiesBox"
import Upload from "components/Upload/Upload"
import ActionsRenderer from "components/TableRenderer/ActionsRenderer"
import { findByName } from "api/actions"
import withFormContext from "components/FormRenderer/withFormContext"
import PropTypes from "prop-types"
import { isActionSuccess,
  getAction,
  getActionStatus,
  ACTION,
  ACTION_STATUS } from "components/FormRenderer/actionEntity"

class BillingPeriod extends React.Component {

  static propTypes = {
    entity: EntityType.isRequired,
    context: PropTypes.object,
  }

  onActionResolve = entity => {
    if (isActionSuccess(entity) && getActionStatus(entity) === ACTION_STATUS.COMPLETED) {
      getAction(entity) === ACTION.UPDATE && this.props.context.onActionResolve(entity)
    }
    else {
      console.log(`${ACTION_STATUS.POTENTIAL} and ${ACTION_STATUS.FAILED} don't need any handling right now.`)
    }
  }

  render() {
    const { entity } = this.props
    const uploadInvoiceAction = findByName(entity.actions, "upload-invoice")
    const publishInvoiceAction = findByName(entity.actions, "publish-invoices")
    const completeUploadAction = findByName(entity.actions, "complete-upload")
    const uploadInvoiceUrl = uploadInvoiceAction && uploadInvoiceAction.href

    return (
      <Card header={entity.title}>
        <PropertiesBox
          entity={entity}
          keys="billingPeriod,invoiceCount,state"
          label/>
        { (uploadInvoiceAction || completeUploadAction || publishInvoiceAction) && <hr/> }
        { uploadInvoiceAction && <Upload url={uploadInvoiceUrl} onActionResolve={this.onActionResolve}/> }
        <div className="d-flex justify-content-between">
          { (completeUploadAction) && <ActionsRenderer actions={[completeUploadAction]}/> }
          { (publishInvoiceAction) && <ActionsRenderer actions={[publishInvoiceAction]}/> }
        </div>
        <style jsx global>{`
          .actions {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
          }
          .actions div {
            border: none;
          }
          .actions > form:first-child {
            width: 100%;
          }
          .actions form > div {
            padding:  0px !important;
            margin-bottom:  0px;
          }
          .actions div > h5 {
            display: none;
          }
          .actions div.p-4.card:not(:first-child) div > h6 {
            display: none;
          }
          .no-margin {
            margin: 0px !important;
          }
        `}</style>
      </Card>
    )
  }
}

export default withFormContext(BillingPeriod)
