import React, { Component } from "react"
import PropTypes from "prop-types"

import { Input } from "reactstrap"
import { getAutocomplete, isDisabled } from "components/FormRenderer/form"
import InputMask from "react-input-mask"
import { getEnvTitle } from "components/Layout/layoutManager"

class PureInput extends Component {
  render() {
    const { name, value, type = "text", classes, required, error, className, onChange, onBlur, min, max, step, innerRef, placeholder, component } = this.props
    const Control = component || Input
    const telMask = getEnvTitle().indexOf("Boston") !== -1 ? "(999) 999-9999" : ""
    const cardMask = "9999 9999 9999 9999"
    const expirationMask = "99"
    const CvcMask = "999"

    return (
      <Control
        type={type}
        name={name}
        invalid={!!error}
        defaultValue={value}
        autoComplete={getAutocomplete(classes)}
        required={required}
        innerRef={innerRef}
        min={type == "date" ? (min ? min : "1980-01-01") : min}
        max={max}
        step={step}
        onChange={onChange}
        onBlur={onBlur}
        className={className}
        disabled={isDisabled(classes)}
        placeholder={placeholder}
        mask={type == "tel" ? telMask : name == "cardNumber" ? cardMask : (name == "expMonth" || name == "expYear") ? expirationMask : name == "cvc" ? CvcMask : null}
        maskChar=" "
        inputRef={(type == "tel" || name == "cardNumber" || name == "expMonth" || name == "expYear" || name == "cvc") ? innerRef : null}
        tag={ (type == "tel" || name == "cardNumber" || name == "expMonth" || name == "expYear" || name == "cvc") ? InputMask : null}
      />
    )
  }
}
PureInput.propTypes = {
  error: PropTypes.node,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  type: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  step: PropTypes.number,
  classes: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
  innerRef: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  component: PropTypes.any,
}

export default PureInput
