import React from "react"
import PropTypes from "prop-types"

import { logout } from "api/api"

class Logout extends React.Component {
  static propTypes = {
    action: PropTypes.object.isRequired,
    btnClassName: PropTypes.string,
  }

  onLogout = () => {
    const { href, method } = this.props.action
    logout({ href, method })
  }

  render() {
    return <span className={`btn text-danger pointer-cursor ${this.props.btnClassName || ""}`} onClick={this.onLogout}>
      <i className="fa fa-lock text-danger ml-0"></i> {this.props.action.title}
      <style jsx>{`
      @media (max-width: 769px) {
        .btn.text-danger {
          width: 100%;
        }
      }
      .pointer-cursor {
        cursor: pointer;
      }
    `}</style>
    </span>
  }
}

export default Logout
