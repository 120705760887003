import React from "react"

import Entities from "components/Entities"
import SirenEntityType from "api/SirenEntityType"

const LandingPage = ({ entity }) => <Entities entities={entity.entities}/>
LandingPage.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default LandingPage
