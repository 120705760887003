import React from "react"
import PropTypes from "prop-types"

const Hero = ({ title }) => (
  <React.Fragment>
    <div className="hero d-flex text-center text-sm-left align-items-center align-items-sm-end">
      <div className="container pb-sm-4 h-50">
        <h1>{title}</h1>
      </div>
    </div>
  </React.Fragment>
)
Hero.propTypes = {
  title: PropTypes.string,
}

export default Hero
