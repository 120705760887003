import React from "react"

import Link from "components/Link"
import EntityType from "api/SirenEntityType"
import { getEnvTitle } from "components/Layout/layoutManager"

const Footer = ({ entity }) => {
  if (getEnvTitle().indexOf("Boston") !== -1 && entity.links) {
    entity.links.filter(link => {
      if (link.rel.indexOf("agb") > -1) {
        link.href = "http://www.massport.com/logan-airport/to-from-logan/parking/FPP-info/EVOPark-TC"
        link.rel.indexOf("external") == -1 && link.rel.push("external")
      }
    })
  }
  if ((getEnvTitle().indexOf("Ghent") !== -1 || getEnvTitle().indexOf("Mobiliteitsbedrijf") !== -1) && entity.links) {
    entity.links.filter(link => {
      if (link.rel.indexOf("impressum") > -1) {
        link.href = "https://stad.gent/nl/openingsuren-adressen/mobiliteitsbedrijf-stad-gent"
        link.rel.indexOf("external") == -1 && link.rel.push("external")
      }
      if (link.rel.indexOf("datenschutz") > -1) {
        link.href = "https://stad.gent/nl/over-gent-en-het-stadsbestuur/juridische-info/met-respect-voor-je-privacy"
        link.rel.indexOf("external") == -1 && link.rel.push("external")
      }
      if (link.rel.indexOf("agb") > -1) {
        link.href = "https://stad.gent/nl/reglementen?search=parkeergarage"
        link.rel.indexOf("external") == -1 && link.rel.push("external")
      }
    })
  }
  return (
    <footer className="app-footer">
      {(entity.links.length !== 0 && !entity.properties.texts) && <div className="container text-md-right pt-md-3 h-100 d-flex d-md-block align-items-center justify-content-center">
        { entity.links.map((link, index) =>
          <Link key={link.href} link={link} className={index ? "pl-4" : undefined}/>
        )}
        {getEnvTitle().indexOf("Boston") !== -1 && <a className="m-4" target="_blank" rel="noopener noreferrer" href="http://www.massport.com/logan-airport/to-from-logan/parking/FPP-info/EVOPark-FAQ">FAQs</a>}
      </div>
      }
      {
        (entity.properties.texts && entity.links.length === 0) && <div className="w-100 mx-4 h-100 d-flex align-items-center justify-content-between">
          { entity.properties.texts.map((text) =>
            <div key={text}>{text}</div>
          )
          }
        </div>
      }
      <style jsx>{`
        footer {
          background: #f0f3f5;
          border-top: 1px solid #a4b7c1;
          height: 50px;
          flex-shrink: 0;
        }
        @media (min-width: 769px) {
          footer {
            height: 100px;
          }
        }
      `}</style>
    </footer>
  )
}
Footer.propTypes = {
  entity: EntityType.isRequired,
}
export default Footer
