import React from "react"
import PropTypes from "prop-types"

import { findByRelationStrict } from "api/utils"
import EntityType from "api/SirenEntityType"
import get from "get"
import Header from "components/Layout/Header/Header"

const HeaderEntity = ({ entity, topNav, sidebar, currentUrl }) => {

  const indexLink = findByRelationStrict(entity.links, "index")
  const logoLink = findByRelationStrict(entity.links, "logo")
  if (indexLink && logoLink) {
    indexLink.logoHref = logoLink.href
  }

  const menu = findByRelationStrict(entity.entities, "menu")
  const loginLink = findByRelationStrict(entity.links, "login")
  const logoutAction = entity.actions.find(a => a.name === "logout")
  const statusText = entity.properties.statusText
  const customerEntity = findByRelationStrict(entity.entities, "customer")
  const selfServiceLink = findByRelationStrict(entity.links, "self-service")
  const customer = {
    customerNumber: get(customerEntity, "properties.customerNumber"),
    name: get(customerEntity, "properties.name"),
    selfServiceLink,
  }

  return <Header
    indexLink={indexLink}
    loginLink={loginLink}
    logoutAction={logoutAction}
    statusText={statusText}
    customer={customer}
    headerDropdown={menu}
    topNav={topNav}
    sidebar={sidebar}
    currentUrl={currentUrl}/>
}
HeaderEntity.propTypes = {
  entity: EntityType.isRequired,
  topNav: EntityType,
  sidebar: PropTypes.bool,
  currentUrl: PropTypes.string,
}

export default HeaderEntity
