import React from "react"
import PropTypes from "prop-types"

import EntityType from "api/SirenEntityType"
import { findByRelationStrict } from "api/utils"
import Link from "components/Link"
import FontAwesomeIcon from "components/FontAwesome/FontAwesomeIcon"

const PDFAdapter = ({ entity, property }) => {
  const link = PDFAdapter.value(entity, property)
  if (!link) { return null }

  return <div className="text-center">
    <Link download link={link} className="btn btn-outline-secondary">
      <FontAwesomeIcon title={link.title} iconURI={link.icon} className="mr-2"/>{link.title}
    </Link>
  </div>
}

PDFAdapter.value = (entity, property = "pdf") => findByRelationStrict(entity.links, ["alternate", property])

PDFAdapter.propTypes = {
  entity: EntityType,
  property: PropTypes.string,
}

export default PDFAdapter
