import React, { Component } from "react"
import PropTypes from "prop-types"

class Progress extends Component {

  static propTypes = {
    uploadedPercentage: PropTypes.number,
    failedPercentage: PropTypes.number,
  }

  render() {
    return (
      <div className="progress">
        <div className="progress-bar" role="progressbar" aria-valuenow={this.props.uploadedPercentage} aria-valuemin="0" aria-valuemax="100"></div>
        <div className="progress-bar bg-danger" role="progressbar" aria-valuenow={this.props.failedPercentage} aria-valuemin="0" aria-valuemax="100"></div>
        <style jsx global>{`
        .progress-bar {
          width: ${this.props.uploadedPercentage + "%"};
        }
        .bg-danger{
          width: ${this.props.failedPercentage + "%"};
        }
      `}</style>
      </div>
    )
  }
}

export default Progress
