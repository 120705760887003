import React from "react"
import PropTypes from "prop-types"

import SelectAllBarContent from "components/TableRenderer/SelectAllBarContent"
import { getFrontendTranslations } from "components/Layout/layoutManager"
import translate from "components/i18n/translate"

const SelectAllBar = ({ selected, total, onSelectAll, onClearSelection }) => {
  if (selected < total) {
    return <SelectAllBarContent
      text={getFrontendTranslations() ? getFrontendTranslations().table.selectAll.currentSelection.replace("%{selected}", `${selected}`) : translate("table.selectAll.currentSelection", { selected })}
      action={getFrontendTranslations() ? getFrontendTranslations().table.selectAll.selectAll.replace("%{total}", `${total}`) : translate("table.selectAll.selectAll", { total })}
      onClick={onSelectAll} selectedCount={selected}
    />
  } else if (selected === total) {
    return <SelectAllBarContent
      text={getFrontendTranslations() ? getFrontendTranslations().table.selectAll.allSelected.replace("%{total}", `${total}`) : translate("table.selectAll.allSelected", { total })}
      action={getFrontendTranslations() ? getFrontendTranslations().table.selectAll.deselect : translate("table.selectAll.deselect")}
      onClick={onClearSelection} selectedCount={total}
    />
  }
}

SelectAllBar.propTypes = {
  selected: PropTypes.number,
  total: PropTypes.number,
  onSelectAll: PropTypes.func,
  onClearSelection: PropTypes.func,
}

export default SelectAllBar
