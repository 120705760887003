import React from "react"
import PropTypes from "prop-types"

import { getFontAwesomeIconClass } from "components/FontAwesome/fontawesome"

const FontAwesomeIcon = ({ title, iconURI, width, height, fontSize, className = "" }) => (
  <React.Fragment>
    <i title={title} className={`${getFontAwesomeIconClass(iconURI)} ${className}`}></i>
    <style jsx>{`
      i {
        ${fontSize && `font-size: ${fontSize};`}
        ${width && `width: ${width};`}
        ${height && `height: ${height};`}
      }
    `}
    </style>
  </React.Fragment>
)

FontAwesomeIcon.propTypes = {
  title: PropTypes.string,
  iconURI: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.string,
  className: PropTypes.string,
}

export default FontAwesomeIcon
