import React from "react"
import PropTypes from "prop-types"

import { Input, Label, FormGroup } from "reactstrap"

import Editor from "components/FormRenderer/components/Editor"
import { isWysiwyg, isSplitNewLine, SPLIT_NEW_LINE, getRows } from "components/FormRenderer/form"

const TextArea = ({ name, title, value, error, classes, required, className = "", innerRef, placeholder }) => {
  if (isWysiwyg(classes)) {
    return <Editor name={name} title={title} value={value} required={required} className={className} innerRef={innerRef}/>
  }

  return <FormGroup className={name}>
    <Label>
      {title}
      <Input
        type="textarea"
        invalid={!!error}
        name={name}
        rows={getRows(classes)}
        defaultValue={value}
        required={required}
        className={`${className} ${isSplitNewLine(classes) ? SPLIT_NEW_LINE : ""}`}
        innerRef={innerRef}
        placeholder={placeholder}
      />
      { error }
    </Label>
  </FormGroup>
}
TextArea.propTypes = {
  error: PropTypes.node,
  name: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
  innerRef: PropTypes.func,
  placeholder: PropTypes.string,
}

export default TextArea
