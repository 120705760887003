import React from "react"

import EntityType from "api/SirenEntityType"
import withEntity from "components/withEntity"
import Entities from "components/Entities"

const Hero = ({ entity }) => (
  <section className="hero mx-auto">
    <Entities entities={entity.entities}/>
    <style jsx>{`
      .hero {
        height: 320px;
        position: relative;
        background: no-repeat 50% 50%;
        background-size: cover;
        display: flex;
        justify-content: center;
      }
      @media (min-width: 576px) {
        .hero {
          height: 420px;
        }
      }
    `}</style>
    <style jsx>{`
      .hero {
        ${entity.properties.image && `background-image: url("${entity.properties.image}");`}
      }
    `}</style>
  </section>
)
Hero.propTypes = {
  entity: EntityType,
}

export default withEntity(Hero, {
  properties: ["image"],
})
