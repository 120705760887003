import React from "react"
import { Row, Col } from "reactstrap"

import SirenEntityType from "api/SirenEntityType"
import InfoBlock from "components/InfoBlock"
import { findByRelationStrict } from "api/utils"
import TextBlock from "components/pages/public/LandingPage/TextBlock"

const ParkingFacilityPageDescription = ({ entity }) => {
  const descriptionEntity = findByRelationStrict(entity.entities, "description")
  const productsDescriptionEntity = findByRelationStrict(entity.entities, "productsDescription")
  const isContentEmpty = (ent) => ent && ent.properties.html == "<p><br></p>"
  
  if (!descriptionEntity && !productsDescriptionEntity) { return null }

  return (
    <Row className="mt-lg-4">
      {(descriptionEntity && !isContentEmpty(descriptionEntity)) && <Col xs="12" md={(productsDescriptionEntity && !isContentEmpty(productsDescriptionEntity)) ? 6 : 12}>
        <InfoBlock title={descriptionEntity.title}>
          <TextBlock entity={descriptionEntity} className="pl-0"/>
        </InfoBlock>
      </Col>}
      {(productsDescriptionEntity && !isContentEmpty(productsDescriptionEntity)) && <Col xs="12" md={(descriptionEntity && !isContentEmpty(descriptionEntity)) ? 6 : 12}>
        <InfoBlock title={productsDescriptionEntity.title}>
          <TextBlock entity={productsDescriptionEntity} className="pl-0"/>
        </InfoBlock>
      </Col>}
    </Row>
  )
}
ParkingFacilityPageDescription.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default ParkingFacilityPageDescription
