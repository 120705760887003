import React from "react"
import PropTypes from "prop-types"

import PropertiesTable from "components/PropertiesTable"

const Value = ({ children }) => {
  if (!children) {
    return null
  }

  if (typeof children === "object") {
    return Array.isArray(children)
      // the key generation is quite tricky to handle as `child` can be anything.
      // We'll use this hack for now as it's called only in the dev environment
      ? children.map(child => <Value key={typeof child === "string" ? child : Math.random().toString(36).substr(2, 9)}>{child}</Value>)
      : <PropertiesTable properties={children}/>
  }

  return children.toString()
}
Value.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.array]),
}

export default Value
