export default (aElement, bElement, order, property) => {
  const a = aElement[property] || ""
  const b = bElement[property] || ""

  if (typeof a === "number" && typeof b === "number") {
    return order === "desc"
      ? b - a
      : a - b
  }

  return order === "desc"
    ? b.toString().localeCompare(a.toString())
    : a.toString().localeCompare(b.toString())
}
