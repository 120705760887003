import React from "react"
import PropTypes from "prop-types"
import toRegex from "path-to-regexp"

import GenericPage from "components/GenericPage"
import { API_URL } from "api/api"

import history from "appHistory"
import decodeURL from "./urlHelpers/decodeURL"

const uriKeys = []
const uriPattern = toRegex("/:type([r|b|raw|base64])/:uri(.*)", uriKeys)

function matchURI(path) {
  const match = uriPattern.exec(path)
  if (!match) {
    return null
  }
  const params = {}
  for (let i = 1, len = match.length; i < len; ++i) {
    params[uriKeys[i - 1].name] = match[i] !== undefined ? match[i] : undefined
  }
  return params
}


class App extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object,
  }

  static defaultProps = {
    location: document.location,
  }

  state = {
    location: this.props.location,
  }

  componentDidMount() {
    this.unlisten = history.listen(this.onHistoryChange)
  }

  componentWillUnmount() {
    this.unlisten && this.unlisten()
  }

  onHistoryChange = (location) => {
    this.setState(() => ({ location }))
  }

  render() {
    const { pathname, search } = this.state.location
    let uri
    const params = matchURI(pathname)
    if (params) {
      const { type } = params
      uri = params.uri
      if (type === "b" || type === "base64") {
        try {
          uri = decodeURL(this.state.location.pathname)
        } catch (error) {
          // Render error page here
          return <GenericPage id={uri} history={history}/>
        }
      }
    } else {
      uri = `${API_URL}${pathname}`
    }
    uri = `${uri}${search}`
    return <GenericPage id={uri} history={history}/>
  }
}
export default App
