import React from "react"
import PropTypes from "prop-types"

import EntityType from "api/SirenEntityType"

const DurationAdapter = ({ entity, property }) => {
  const duration = entity.properties[property]
  if (!duration) {
    return null
  }
  return <div>{ duration.split("/").join(" / ") }</div>
}

DurationAdapter.propTypes = {
  entity: EntityType,
  property: PropTypes.string.isRequired,
}

export default DurationAdapter
