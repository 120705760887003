import React from "react"
import { Container, Row } from "reactstrap"

import FormRenderer from "components/FormRenderer/FormRenderer"
import TextBlock from "components/pages/public/LandingPage/TextBlock"
import SirenEntityType from "api/SirenEntityType"
import { findByName } from "api/actions"
import { findByClassStrict } from "api/utils"
import { TextBlockClass } from "components/registry"

const CustomerOnboardingPage = ({ entity }) => {
  const action = findByName(entity.actions, "finish-import-onboarding")
  const textBlock = findByClassStrict(entity.entities, TextBlockClass)
  if (!action) {
    return null
  }
  return (
    <div className="app flex-row d-flex h-100 align-items-center mt-4">
      <Container className="account-container">
        <Row className="justify-content-center">
          <TextBlock entity={textBlock}/>
        </Row>
        <Row className="justify-content-center">
          <FormRenderer action={action}/>
        </Row>
      </Container>
      <style jsx>{`
          @media (min-width: 769px) {
            :global(.account-container) {
              width: 32.7%;
            }
          }
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            :global(.row) {
              display: block !important;
            }
         }
      `}</style>
    </div>
  )
}

CustomerOnboardingPage.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default CustomerOnboardingPage
