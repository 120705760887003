import React, { Component, Fragment } from "react"

import SirenEntityType from "api/SirenEntityType"
import { findByName } from "api/actions"

import CardActionsRenderer from "components/TableRenderer/CardActionsRenderer"

class GarageSystem extends Component {
  render() {
    const { entity } = this.props
    const updateAction = findByName(entity.actions, "update")
    const fetchUsageProfilesAction = findByName(entity.actions, "fetch-usage-profiles")
    const pushUnprocessedEventsDataAction = findByName(entity.actions, "push-unprocessed-events-data")

    return <Fragment>
      { updateAction && <CardActionsRenderer actions={[updateAction]} className="mx-4"/> }
      { fetchUsageProfilesAction && <CardActionsRenderer actions={[fetchUsageProfilesAction]} className="mx-4"/> }
      { pushUnprocessedEventsDataAction && <CardActionsRenderer actions={[pushUnprocessedEventsDataAction]} className="mx-4"/> }
    </Fragment>
  }
}

GarageSystem.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default GarageSystem
