import React, { useState } from "react"
import DatePicker from "react-multi-date-picker"

export default function MultiDatePicker() {
  let values = []

  for (var date of arguments[0].defaultValue.split(",")) {
    values.push(new Date(date))
  }

  const [value, setValue] = useState(values)

  return <DatePicker
    multiple value={value}
    onChange={setValue}
    name="allowedCancellationDates"
    disableYearPicker="true"
    hideYear="true"
    format="DD/MM"/>
}
