import React from "react"
import PropTypes from "prop-types"
import { Nav, NavItem } from "reactstrap"

import NavLink from "components/NavLink"
import { LinksType } from "api/SirenEntityType"

const TopNavContent = ({ links, currentUrl, className = "" }) => (
  <Nav navbar className={`container justify-content-start flex-column flex-sm-row ${className}`}>
    {links.map(link => (
      <NavItem key={link.href} rel={link.title} className="px-2 py-2 py-sm-0 link-container">
        <NavLink link={link} className={new URL(currentUrl).pathname === new URL(link.href).pathname ? "active" : ""}>{link.title}</NavLink>
      </NavItem>
    )
    )}
    <style jsx>{`
      @media (max-width: 576px) {
        :global(.link-container) {
          width: 100%;
        }
      }

       :global(.top-nav ul) {
        position:relative;
       }

       :global(.top-nav ul > li:last-child){
        right: 0;
        position:absolute !important;
      }

      :global(.top-nav ul li a[rel=rerun_signup]){
        color: #80e5ff !important;
        text-decoration: underline;
      }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        :global(.app-header.navbar .nav-item:last-child) {
          margin-top:-15px !important;
        }
     }
    `}</style>
  </Nav>
)
TopNavContent.propTypes = {
  links: LinksType,
  currentUrl: PropTypes.string,
  className: PropTypes.string,
}

export default TopNavContent
