import React, { Component } from "react"
import "./Dropzone.css"
import PropTypes from "prop-types"
import { getFrontendTranslations } from "components/Layout/layoutManager"
import translate from "components/i18n/translate"

class Dropzone extends Component {

  static propTypes = {
    disabled: PropTypes.bool,
    onFilesAdded: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = { hightlight: false }
    this.fileInputRef = React.createRef()
  }

  openFileDialog = () => {
    if (this.props.disabled) { return }
    this.fileInputRef.current.click()
  }

  onFilesAdded = evt => {
    if (this.props.disabled) { return }
    const files = evt.target.files
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files)
      this.props.onFilesAdded(array)
    }
  }

  onDragOver = event => {
    event.preventDefault()
    if (this.props.disabled) { return }
    this.setState({ hightlight: true })
  }

  onDragLeave = () => {
    this.setState({ hightlight: false })
  }

  onDrop = event => {
    event.preventDefault()
    if (this.props.disabled) { return }
    const files = event.dataTransfer.files
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files)
      this.props.onFilesAdded(array)
    }
    this.setState({ hightlight: false })
  }

  fileListToArray(list) {
    const array = []
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i))
    }
    return array
  }

  render() {
    return (
      <div
        className={`dropzone ${this.state.hightlight ? "highlight" : ""}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
      >
        <input
          ref={this.fileInputRef}
          className="file-input"
          type="file"
          accept="application/pdf"
          multiple
          onChange={this.onFilesAdded}
        />
        <i className="fa fa-cloud-upload"></i>
        <span>{getFrontendTranslations() ? getFrontendTranslations().fileUpload.uploadFiles : translate("fileUpload.uploadFiles")}</span>

        <style jsx global>{`
        .dropzone {
          cursor: ${this.props.disabled ? "default" : "pointer"};
        }
      `}</style>
      </div>
    )
  }
}

export default Dropzone
