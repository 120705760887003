import React from "react"
import PropTypes from "prop-types"
import { Input, FormGroup, Label } from "reactstrap"

import { getAcceptAttribute } from "components/FormRenderer/form"
import Image from "components/Image"
import { getFrontendTranslations } from "components/Layout/layoutManager"
import translate from "components/i18n/translate"

const ImageContainer = ({ title, text, src, className }) => (
  <div className={className}>
    <i className="text-muted">{text}</i>
    <Image title={title} src={src} className="d-block mb-4 img-thumbnail bg-white" width="150px"/>
  </div>
)
ImageContainer.propTypes = {
  title: PropTypes.node,
  text: PropTypes.string,
  src: PropTypes.string,
  className: PropTypes.string,
}

const isImage = value => RegExp("(data:image/)|(jpg|png)$", "i").test(value)

class File extends React.Component {
  static propTypes = {
    error: PropTypes.node,
    name: PropTypes.string,
    value: PropTypes.string,
    title: PropTypes.node,
    className: PropTypes.string,
    classes: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
    innerRef: PropTypes.func,
    required: PropTypes.bool,
  }

  state = {
    newImageValue: undefined,
  }

  handleFiles = (event) => {
    const files = event.target.files
    const file = files[0] // only handle 1 file at the moment
    if (!file || (file && file.type.indexOf("image/") === -1)) { return }

    const reader = new FileReader()
    reader.onload = (e) => this.setState(() => ({ newImageValue: e.target.result }))
    reader.readAsDataURL(file)
  }

  render() {
    const { name, title, value, className, classes, type, error, innerRef, required } = this.props
    const { newImageValue } = this.state

    return (
      <FormGroup className={name}>
        <Label className="mb-4 w-auto">
          {title}
          <Input
            type={type}
            name={name}
            required={required}
            className={className}
            invalid={!!error}
            innerRef={innerRef}
            accept={getAcceptAttribute(classes)}
            onChange={this.handleFiles}/>
          {error}
        </Label>
        <div className="d-md-flex">
          {isImage(value) && <ImageContainer title={title} text={getFrontendTranslations() ? getFrontendTranslations().file.currentPictureLabel : translate("file.currentPictureLabel")} src={value} className="mr-4"/>}
          {newImageValue && <ImageContainer title={title} text={getFrontendTranslations() ? getFrontendTranslations().file.newPictureLabel : translate("file.newPictureLabel")} src={newImageValue}/>}
        </div>
      </FormGroup>
    )
  }
}

export default File
