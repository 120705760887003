import React from "react"
import PropTypes from "prop-types"

const Loader = ({ className = "" }) => <React.Fragment>
  <div className={`loader ${className}`}/>
  <style jsx>{`
    .loader {
      display: inline-block;
      font-size: 2px;
      border-radius: 50%;
      width: 7em;
      height: 7em;
      position: relative;
      text-indent: -9999em;
      border-top: 1.1em solid rgba(255, 255, 255, 0.2);
      border-right: 1.1em solid rgba(255, 255, 255, 0.2);
      border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
      border-left: 1.1em solid #ffffff;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: load8 1.1s infinite linear;
      animation: loading 1.1s infinite linear;
    }
    @-webkit-keyframes loading {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes loading {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  `}</style>
</React.Fragment>

Loader.propTypes = {
  className: PropTypes.string,
}

export default Loader
