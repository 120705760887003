import React from "react"
import PropTypes from "prop-types"
import { FormGroup } from "reactstrap"

import LoadingButton from "components/LoadingButton"
import { isDisabled, isLoading, isWarning } from "components/FormRenderer/form"

const Submit = ({ name = "submit", title, error, classes, className, value, onClick }) => (
  <FormGroup className={name}>
    <LoadingButton type="submit" name={name} value={value} onClick={onClick} color={isWarning(classes) ? "warning" : "primary"} loading={isLoading(classes)} className={`px-4 no-click-event ${className}`} disabled={isDisabled(classes) || isLoading(classes)}>{title}</LoadingButton>
    {error}
    <style jsx global>{`
      .no-click-event > span {
        pointer-events: none;
      }
    `}</style>
  </FormGroup>
)
Submit.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.node,
  classes: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default Submit
