import React, { PureComponent } from "react"
import PropTypes from "prop-types"

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import EntityType from "api/SirenEntityType"
import Card from "components/Card"
import BulkActionSelection from "components/TableRenderer/BulkActionSelection"
import FilterBar from "components/TableRenderer/FilterBar"
import SelectAllBar from "components/TableRenderer/SelectAllBar"
import SimpleActionModal from "components/Modal/SimpleActionModal"
import FormSubmissionWrapper from "components/FormRenderer/FormSubmissionWrapper"
import getSearchParam from "urlHelpers/getSearchParam"
import PropertiesTable from "components/PropertiesTable"
import { propertyAdapter, resolveAdapter } from "components/TableRenderer/adapters/adapterRegistry"
import { findByRelation, filterByRelation } from "api/utils"
import { splitActionsByClass, findByType, BULK_ACTION_CLASS, isSimpleAction } from "api/actions"
import get from "get"
import { isActionSuccess,
  getActionEntityItems,
  findAndReplace,
  findAndDelete,
  getAction,
  getActionStatus,
  ACTION,
  ACTION_STATUS } from "components/FormRenderer/actionEntity"
import getTranslations from "components/i18n/getTranslations"
import { getFrontendTranslations } from "components/Layout/layoutManager"
import translate from "components/i18n/translate"
import ActionsAdapter from "components/TableRenderer/adapters/ActionsAdapter"
import { findBySelfLink } from "api/entities"
import DetailViewSection from "components/TableRenderer/DetailViewSection"
import encodeURL from "urlHelpers/encodeURL"
import history from "appHistory"
import { getItem, setItem } from "storage"
import decodeURL from "urlHelpers/decodeURL"

const SELECTALL_MODE = "SELECTALL_MODE"
const LIST_OF_EXEMPTED = "LIST_OF_EXEMPTED"
const LIST_OF_SELECTED = "LIST_OF_SELECTED"
const { ClearSearchButton } = Search
const GENERIC_COLUMNS = [
  {
    title: "Name",
    adapter: propertyAdapter("title"),
  },
  {
    title: "Properties",
    adapter(entity) {
      return <PropertiesTable properties={entity.properties} translations={getTranslations(entity)}/>
    },
  },
]

const createColumnDefinitions = (entity, dataFormatter) => (
  new Promise(resolve => {
    const defs = get(findByRelation(entity.entities, "describedby"), "properties.columns")
    if (!Array.isArray(defs)) {
      return resolve(GENERIC_COLUMNS)
    }
    const getDefs = defs.reduce((promise, def) => {
      return promise.then(result => {
        const { property, adapter: adapterClass, entity: entityIdentifier } = def
        return resolveAdapter(adapterClass, property, entityIdentifier).then(resolvedAdapter => {
          result.push({
            ...def,
            dataField: def.property ? def.property : "actions",
            text: def.title,
            title: true,
            headerClasses: def.property ? def.property : "",
            classes: def.property ? def.property : "",
            headerTitle: column => column.title,
            sortBy: def.sort,
            sort: !!(def.sort),
            formatExtraData: def,
            formatter: (cell, row) => dataFormatter(cell, row, resolvedAdapter),
            filterValue: resolvedAdapter.filterValue,
            adapter: resolvedAdapter,
            value: resolvedAdapter.value,
          })
          return result
        }).catch(() => {
          // ignore adapter resolve errors for now. Later we might add a faulty column
          return result
        })
      })
    }, Promise.resolve([]))
    getDefs.then(resolve)
  })
)

const UNIQUE_ID = "_uniqueId"
export const ACTION_VIEW = "action"
export const ENTITY_VIEW = "entity"
class TableRenderer extends PureComponent {
  state = {
    detailView: ACTION_VIEW,
    renderedEntity: undefined,
    renderedAction: undefined,
    selectedItems: [],
    selectedItemsLength: 0,
    filterEntities: undefined,
    filterByElement: [],
    filterValueElement: [],
    filteredItems: [],
    totalDataSize: undefined,
    sizePerPage: undefined,
    currentPage: undefined,
    order: undefined,
    orderBy: undefined,
    filter: [],
    filterValue: [],
    searchValue: undefined,
    showMultiFilter: false,
  }

  componentDidMount() {
    const { entity } = this.props
    this.loadTable()
    if (window.location.pathname.indexOf("/b/") === 0) {
      const browserUrl = new URL(decodeURL(window.location.pathname))
      if (browserUrl.searchParams.has("detailView")) {
        const detailViewUrl = browserUrl.searchParams.get("detailView")
        const matchedItem = entity.entities.find(ent => ent.key == detailViewUrl)
        this.setState({ detailView: ENTITY_VIEW, renderedEntity: matchedItem })
        this.smoothScroll()
      }
      if (this.props.isSecondaryTable && browserUrl.searchParams.has("secondaryTable")) {
        const secondaryTable = new URL(decodeURL(browserUrl.searchParams.get("secondaryTable")))
        const secondaryDetailViewUrl = secondaryTable.searchParams.get("detailView")
        const matchedItem = entity.entities.find(ent => ent.key == secondaryDetailViewUrl)
        this.setState({ detailView: ENTITY_VIEW, renderedEntity: matchedItem })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.entity.entities != prevProps.entity.entities) {
      let browserUrl = window.location.pathname
      if (browserUrl.indexOf("/b/") === 0) {
        browserUrl = new URL(decodeURL(window.location.pathname))
        if (browserUrl.searchParams.has("detailView") && browserUrl.searchParams.has("secondaryTable")) {
          const detailViewUrl = browserUrl.searchParams.get("detailView")
          const matchedItem = this.props.entity.entities.find(ent => ent.key == detailViewUrl)
          this.setState({ detailView: ENTITY_VIEW, renderedEntity: matchedItem })
          this.smoothScroll()
        }
        if (this.props.isSecondaryTable) {
          if (browserUrl.searchParams.has("secondaryTable")) {
            const secondaryTable = new URL(decodeURL(browserUrl.searchParams.get("secondaryTable")))
            const secondaryDetailViewUrl = secondaryTable.searchParams.get("detailView")
            const matchedItem = this.props.entity.entities.find(ent => ent.key == secondaryDetailViewUrl)
            this.setState({ detailView: ENTITY_VIEW, renderedEntity: matchedItem })
          }
        }
      }
      this.loadTable()
    }

    if (prevState.items !== this.state.items) {
      const normalizedItems = this.normalizeItems()
      this.setState({ normalizedItems, filteredItems: normalizedItems })
      this.createFilter()
    }

    if ((this.state.renderedAction && prevState.renderedAction !== this.state.renderedAction) ||
        (this.state.renderedEntity && prevState.renderedEntity !== this.state.renderedEntity)) {
      this.smoothScroll()
    }
  }

  loadTable = () => {
    const { entity } = this.props
    const href = get(entity, "selfLink")
    const url = new URL(href)
    const prevUrl = this.props.prevUrl && this.props.prevUrl.split("?")[0]
    const currentUrl = this.props.currentUrl && this.props.currentUrl.split("?")[0]
    prevUrl != currentUrl && this.setSelectAllMode(false)

    const sizePerPage = parseInt(url.searchParams.get("itemsPerPage")) || 25
    const currentPage = entity.properties.currentPage || 1
    const order = url.searchParams.get("order")
    const orderBy = url.searchParams.get("orderBy")
    const filter = url.searchParams.getAll("filter[]") || []
    const filterValue = url.searchParams.getAll("filterValue[]") || []
    const searchValue = url.searchParams.get("searchValue")
    getItem(SELECTALL_MODE) === undefined && this.setSelectAllMode(false)
    const selectedItemsLength = getItem(SELECTALL_MODE) ? (entity.properties.numberOfItems - getItem(LIST_OF_EXEMPTED).length) : getItem(LIST_OF_SELECTED).length
    let isSearchable = false
    const paginationLink = findByRelation(this.props.entity.links, "tableAction")
    if (paginationLink) {
      const paginationHref = paginationLink.href
      const paginationUrl = new URL(paginationHref)
      isSearchable = paginationUrl.searchParams.has("searchValue")
    }

    this.setState({
      currentPage: currentPage,
      sizePerPage: sizePerPage,
      totalDataSize: entity.properties.numberOfItems || 0,
      order: order,
      orderBy: orderBy,
      filter: filter,
      filterValue: filterValue,
      searchValue: searchValue,
      selectedItemsLength: selectedItemsLength,
    })

    createColumnDefinitions(entity, this.dataFormatter).then(columnDefinitions => {
      const items = filterByRelation(entity.entities, "itemListElement")
      this.setState({ columnDefinitions, items })
      // looks if there is a pre-selected item
      const itemHref = getSearchParam({ url: document.location.href, name: "item" })
      const itemEntity = itemHref ? findBySelfLink(items, itemHref) : null
      itemEntity && this.setState({ detailView: ENTITY_VIEW, renderedEntity: itemEntity })
    })
    this.setFilterEntity(entity)
    this.actions = splitActionsByClass(entity.actions, BULK_ACTION_CLASS)
    this.table = React.createRef()
    this.detailSection = React.createRef()
    this.sizePerPage = sizePerPage
    this.currentPage = currentPage
    this.order = order
    this.orderBy = orderBy
    this.filter = filter
    this.filterValue = filterValue
    this.searchValue = searchValue
    this.isSearchable = isSearchable
  }

  setFilterEntity = (entity = {}) => {
    this.setState({ filterEntities: filterByRelation(entity.entities, "filter") })
  }

  createFilter = () => {
    const { filterEntities } = this.state
    if (!filterEntities) {
      return
    }

    this.fetchFilterByElement(this.state.filter, null)

  }

  onFilterByChange = ({ target: { value } }, id) => {
    var filter = this.state.filter
    var filterValue = this.state.filterValue
    var filterValueElement = this.state.filterValueElement
    if (value) {
      filter[id] = value
      filterValue[id] = null
      filterValueElement[id] = null

      this.setState({ filter, filterValue, filterValueElement, showMultiFilter: false }, () => { this.fetchFilterByElement([value], id);this.fetchFilterValueElement([value], id) })
    } else {
      filterValue[id] = null
      filterValueElement[id] = null
      this.setState({ filterValue, filterValueElement, showMultiFilter: false })
      this.fetchFilterByElement([value], id)
    }

  }

  fetchFilterByElement = (filter, id) => {
    if (id != null) {
      const showAll = filter.length ? false : true
      const filterByElementValues = [{ name: getFrontendTranslations() ? getFrontendTranslations().table.showAll : translate("table.showAll"), value: "", selected: showAll }].concat(this.state.filterEntities.map(entity => {
        const selected = entity.properties.id == filter[0] ? true : false
        return (
          { name: entity.title,
            value: entity.properties.id,
            selected: selected }
        )
      }
      ))

      this.setState(prevState => {
        var filterByElement = [...prevState.filterByElement]
        var filterValue = [...prevState.filterValue]
        var showMultiFilter = prevState.showMultiFilter
        const emptyFilterValues = filterValue.filter(e => e == null)
        showMultiFilter = emptyFilterValues.length || filterValue.length == 0 ? false : true
        filterByElement[id] = { title: getFrontendTranslations() ? getFrontendTranslations().table.filterBy : translate("table.filterBy"), values: filterByElementValues }
        return ({ filterByElement, showMultiFilter })
      })
    } else {
      if (filter.length) {
        //loading page from serevr after filter - LOOP
        for (let i = 0; i < filter.length; i++) {
          const showAll = filter[i] != null ? false : true
          const filterByElementValues = [{ name: getFrontendTranslations() ? getFrontendTranslations().table.showAll : translate("table.showAll"), value: "", selected: showAll }].concat(this.state.filterEntities.map(entity => {
            const selected = entity.properties.id == filter[i] ? true : false
            return (
              { name: entity.title,
                value: entity.properties.id,
                selected: selected }
            )
          }
          ))

          this.setState(prevState => {
            var filterByElement = [...prevState.filterByElement]
            var filterValue = [...prevState.filterValue]
            var showMultiFilter = prevState.showMultiFilter
            const emptyFilterValues = filterValue.filter(e => e == null)
            showMultiFilter = emptyFilterValues.length || filterValue.length == 0 ? false : true
            filterByElement[i] = { title: getFrontendTranslations() ? getFrontendTranslations().table.filterBy : translate("table.filterBy"), values: filterByElementValues }
            return ({ filterByElement, showMultiFilter })
          })

          if (filter[i] != null) {
            this.fetchFilterValueElement([filter[i]], i)
          } else {
            var filterValueElement = this.state.filterValueElement
            filterValueElement[i] = null
            this.setState({ filterValueElement })
          }

        }
      } else {
        const identifier = 0
        const showAll = filter.length ? false : true
        const filterByElementValues = [{ name: getFrontendTranslations() ? getFrontendTranslations().table.showAll : translate("table.showAll"), value: "", selected: showAll }].concat(this.state.filterEntities.map(entity => {
          const selected = entity.properties.id == filter[0] ? true : false
          return (
            { name: entity.title,
              value: entity.properties.id,
              selected: selected }
          )
        }
        ))

        this.setState(prevState => {
          var filterByElement = [...prevState.filterByElement]
          var filterValue = [...prevState.filterValue]
          var showMultiFilter = prevState.showMultiFilter
          const emptyFilterValues = filterValue.filter(e => e == null)
          showMultiFilter = emptyFilterValues.length || filterValue.length == 0 ? false : true
          filterByElement[identifier] = { title: getFrontendTranslations() ? getFrontendTranslations().table.filterBy : translate("table.filterBy"), values: filterByElementValues }
          return ({ filterByElement, showMultiFilter })
        })

      }
    }

  }

  fetchFilterValueElement = (filterBy, id) => {
    var filterValueElement = this.state.filterValueElement
    const filterValueEntity = filterByRelation(this.props.entity.entities, "filter").find(e => e.properties.id === filterBy[0])
    const type = filterValueEntity.class.includes("https://schema.evopark.com/DateTimeFilter") ? "datePicker" : "Select"
    //check if class contains datepicker filter and change the filter value element accordingly
    if (filterValueEntity.class.includes("https://schema.evopark.com/DateTimeFilter")) {
      // show date picker element
      filterValueElement[id] = { title: getFrontendTranslations() ? getFrontendTranslations().table.filterBy : translate("table.filterBy"), values: this.state.filterValue[id], type: type }
      this.setState({ filterValueElement })
    }
    else {
      const allFilterValueElementValues = (filterValueEntity.properties.options).map(entity => {
        const selected = (entity[1] == this.state.filterValue[id] || (entity[1] == "" && this.state.filterValue[id] == null)) ? true : false
        return (
          { name: entity[0],
            value: entity[1],
            selected: selected }
        )
      })
      const distinctFilterValueElementValues = allFilterValueElementValues.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.value === thing.value && t.name === thing.name
        ))
      )

      filterValueElement[id] = { title: getFrontendTranslations() ? getFrontendTranslations().table.filterBy : translate("table.filterBy"), values: distinctFilterValueElementValues, type: type }
      this.setState({ filterValueElement })
    }
  }

  onFilterValueChange = ({ target: { value } }, id) => {
    var filterValue = this.state.filterValue
    if (value) {
      this.setSelectAllMode(false)
      filterValue[id] = value
      this.setState({ filterValue })
      this.setState({ showMultiFilter: true })
      this.state.filterValue.map((e) => {
        if (e == null) {
          this.setState({ showMultiFilter: false })
        }
      })
    } else {

      filterValue[id] = null
      this.setState({ filterValue })
      this.setState({ showMultiFilter: false })
      this.state.filterValue.map((e) => {
        if (e == null) {
          this.setState({ showMultiFilter: false })
        }
      })
    }

    this.fetchFilterValueElement([this.state.filter[id]], id)
  }

  onFilter = () => {

    this.fetchLatestPage(this.sizePerPage, this.currentPage, this.orderBy, this.order, this.state.filter, this.state.filterValue, this.searchValue)

  }

  deleteFilter = (event, id) => {
    this.setState(prevState => {

      var filter = [...prevState.filter]
      var filterValue = [...prevState.filterValue]
      var filterByElement = [...prevState.filterByElement]
      var filterValueElement = [...prevState.filterValueElement]
      var showMultiFilter = prevState.showMultiFilter

      filter = filter.filter((e, index) => index != id)
      filterValue = filterValue.filter((e, index) => index != id)
      filterByElement = filterByElement.filter((e, index) => index != id)
      filterValueElement = filterValueElement.filter((e, index) => index != id)
      filterByElement = []
      filterValueElement = []
      const emptyFilterValues = filterValue.filter(e => e == null)
      showMultiFilter = emptyFilterValues.length || filterValue.length == 0 ? false : true
      return ({ filter, filterValue, filterByElement, filterValueElement, showMultiFilter })
    }, ()=> {
      this.fetchFilterByElement(this.state.filter, null)
    })
  }

  fetchEachFilterByElement = (event, id) => {
    this.fetchFilterByElement([], id)
  }

  isQualified = (entity, valueProp, value) => (
    entity.properties[valueProp] == value
  )

  extractFilterMetaData = ({ label = "", value = "" }) => {
    const [ labelPropOrRelation, labelProp ] = label.split(".")
    const [ valuePropOrRelation, valueProp ] = value.split(".")
    return {
      labelProp: labelProp || labelPropOrRelation,
      valueProp: valueProp || valuePropOrRelation,
      relation: labelProp ? labelPropOrRelation : undefined,
    }
  }

  fetchNestedEntitiesByRelation = (entities, relation) => (
    entities.reduce((result, entity) => (
      result.concat(filterByRelation(entity.entities, relation))
    ), [])
  )

  normalizeItems = () => {
    const { items, columnDefinitions } = this.state
    return items.map(rowEntity => {
      return columnDefinitions.reduce((normalizedEntity, column) => {
        const key = column.property || "actions"
        normalizedEntity[key] = column.value && column.value(rowEntity, column.property)
        return normalizedEntity
      }, {
        [UNIQUE_ID]: rowEntity.selfLink,
        entity: rowEntity,
        ...(this.injectValuesForBulkActions(rowEntity)),
      })
    })
  }

  injectValuesForBulkActions = (entity) => {
    const bulksActions = this.actions.extracted || []
    return bulksActions.reduce((result, action) => {
      const config = findByType(action.fields, "entityId")
      if (config && config.value && entity.properties[config.value]) {
        result[config.value] = entity.selfLink
      }
      return result
    }, {})
  }

  dataFormatter = (_cell, row, columnAdapter) => (
    columnAdapter(row.entity, this.onActionClick)
  )

  onActionClick = (event, action, entity) => {
    event.stopPropagation()
    const isSimple = isSimpleAction(action)
    isSimple && this.toggleModal(true)
    const updatedEntity = this.state.items.find(item => item.selfLink == entity.selfLink) || entity
    const updatedAction = updatedEntity.actions.find(updatedaction => updatedaction.name == action.name)
    this.setState({ detailView: isSimple ? undefined : ACTION_VIEW, renderedAction: updatedAction, renderedEntity: updatedEntity })
  }

  toggleModal = (value) => this.setState((state) => ({ showModal: value !== undefined ? value : !state.showModal }))

  onModalClose = () => {
    this.toggleModal(false)
    this.setState(() => ({ renderedAction: undefined, renderedEntity: undefined }))
  }

  onRowClick = (event, row) => {
    this.setState({ detailView: ENTITY_VIEW, renderedEntity: row.entity })
    event && event.stopPropagation()
    if (!this.props.isSecondaryTable) {
      const currentUrl = this.props.currentUrl + "&detailView=" + row._uniqueId
      history.replace(encodeURL(currentUrl))
    } else {
      const browserUrl = new URL(decodeURL(window.location.pathname))
      const secondaryTableUrl = this.props.entity.selfLink + "&detailView=" + row._uniqueId
      const currentUrl = browserUrl.href + "&secondaryTable=" + encodeURL(secondaryTableUrl)
      history.replace(encodeURL(currentUrl))
    }
  }

  smoothScroll = () => {
    if (!this.detailSection.current) { return }
    setTimeout(() => {
      window.scrollTo({
        top: window.pageYOffset + this.detailSection.current.getBoundingClientRect().top - 100 /*header height*/ - 24 /*m-top*/,
        behavior: "smooth",
      })
    })
  }

  resetActionState = () => {
    this.setState({ detailView: ACTION_VIEW, renderedAction: undefined, renderedEntity: undefined })
  }

  onRenderBulkAction = (action, event) => {
    this.setState({ detailView: ACTION_VIEW, renderedAction: action, renderedEntity: undefined })
    event && event.stopPropagation()
  }

  onExportToCSV = () => {
    let csvItems = this.state.selectedItems.length
      ? this.state.selectedItems
      : this.state.filteredItems // if no row selected, we export all

    csvItems = csvItems.map(item => {
      Object.keys(item).forEach(key => {
        const def = this.state.columnDefinitions.find(columnDef => columnDef.property === key)
        if (def && typeof def.adapter.csvValue === "function") {
          item[key] = def.adapter.csvValue({ properties: item }, key)
        }
      })
      return item
    })

    return csvItems
  }

  onSizePerPageList = (sizePerPage) => {
    this.sizePerPage = sizePerPage
    this.fetchLatestPage(sizePerPage, 1, this.orderBy, this.order, this.filter, this.filterValue, this.searchValue)
  }

  fetchLatestPage = (sizePerPage, page, orderBy, order, filter, filterValue, searchValue) => {
    const paginationHref = findByRelation(this.props.entity.links, "tableAction").href
    let url = new URL(paginationHref)
    sizePerPage ? url.searchParams.set("itemsPerPage", sizePerPage) : url.searchParams.delete("itemsPerPage")
    page ? url.searchParams.set("page", page) : url.searchParams.delete("page")
    orderBy ? url.searchParams.set("orderBy", orderBy) : url.searchParams.delete("orderBy")
    order ? url.searchParams.set("order", order) : url.searchParams.delete("order")
    if (filter) {
      filter.map(eachFilter => url.searchParams.append("filter[]", eachFilter))
    } else { url.searchParams.delete("filter")}
    if (filterValue) {
      filterValue.map(eachFilterValue => url.searchParams.append("filterValue[]", eachFilterValue))
    } else { url.searchParams.delete("filterValue")}
    searchValue ? url.searchParams.set("searchValue", searchValue) : url.searchParams.delete("searchValue")
    let primaryTableUrl = window.location.pathname.indexOf("/b/") === 0 && new URL(decodeURL(window.location.pathname))
    primaryTableUrl && primaryTableUrl.searchParams.delete("secondaryTable")
    url = this.props.isSecondaryTable ? primaryTableUrl + "&secondaryTable=" + encodeURL(url) : url
    history.replace(encodeURL(url))
  }

  onSearchChange = (_page, _sizePerPage, _sortField, _sortOrder, searchText) => {
    this.fetchLatestPage(this.sizePerPage, 1, this.sortName, this.sortOrder, this.filter, this.filterValue, searchText)
  }

  onClearSearch = () => {
    this.fetchLatestPage(this.sizePerPage, 1, this.sortName, this.sortOrder, this.filter, this.filterValue, undefined)
  }

  // if the prev items where the sizePerPage it means it was "ALL", so we need to update it after ADD/DELETE action
  updateSizePerPage = (prevItemsLength) => prevItemsLength === this.sizePerPage && this.onSizePerPageList(this.state.items.length)

  setSelectAllMode = (mode) => {
    setItem(SELECTALL_MODE, mode)
    setItem(LIST_OF_EXEMPTED, [])
    setItem(LIST_OF_SELECTED, [])
    this.setState({ selectedItemsLength: this.selectedItemsLength() })
  }

  updateList = (listName, predicate) => {
    setItem(listName, new Set(predicate(getItem(listName))))
  }

  addItemToList = (listName, item) => {
    this.updateList(listName, list => list.concat(item._uniqueId))
  }

  removeItemFromList = (listName, item) => {
    this.updateList(listName, list => list.filter(e => e != item._uniqueId))
  }

  selectedItemsLength = () => {
    return getItem(SELECTALL_MODE) ? (this.state.totalDataSize - getItem(LIST_OF_EXEMPTED).length) : getItem(LIST_OF_SELECTED).length
  }

  onSelectRow = (item, isSelected, _rowIndex, event) => {

    event.stopPropagation()
    getItem(SELECTALL_MODE)
      ? (isSelected ? this.removeItemFromList(LIST_OF_EXEMPTED, item) : this.addItemToList(LIST_OF_EXEMPTED, item))
      : (isSelected ? this.addItemToList(LIST_OF_SELECTED, item) : this.removeItemFromList(LIST_OF_SELECTED, item))

    this.setState({ selectedItemsLength: this.selectedItemsLength() })
  }

  // Eventhandler is called by the table and by the <SelectAllBar>
  // The <SelectAllBar> calls it without arguments so we use default args
  // to get the magic of selecting all with the same code as the table renderer
  // would call this handler.
  onSelectAll = () => { this.setSelectAllMode(true) }

  onSinglePageSelectAll = (isSelected = true, items = this.state.filteredItems) => {

    getItem(SELECTALL_MODE)
      ? (isSelected ? this.updateList(LIST_OF_EXEMPTED, list => list.filter(e => items.map(item => item._uniqueId).indexOf(e) == -1)) : this.updateList(LIST_OF_EXEMPTED, list => list.concat(items.map(e => e._uniqueId))))
      : (isSelected ? this.updateList(LIST_OF_SELECTED, list => list.concat(items.map(e => e._uniqueId))) : this.updateList(LIST_OF_SELECTED, list => list.filter(e => items.map(item => item._uniqueId).indexOf(e) == -1)))

    this.setState({ selectedItemsLength: this.selectedItemsLength() })
  }

  getSelectedRows = () => (
    getItem(SELECTALL_MODE)
      ? (this.state.filteredItems.filter(item => getItem(LIST_OF_EXEMPTED).indexOf(item._uniqueId) == -1)).map(row => row._uniqueId)
      : getItem(LIST_OF_SELECTED)
  )

  selectOptions = () => ({
    mode: "checkbox",
    showOnlySelected: true,
    selected: this.getSelectedRows(),
    onSelect: this.onSelectRow,
    onSelectAll: this.onSinglePageSelectAll,
  })

  clearSelection = () => { this.setSelectAllMode(false) }

  onActionResolve = (entity) => {
    if (isActionSuccess(entity) && getActionStatus(entity) === ACTION_STATUS.COMPLETED) {
      const items = getActionEntityItems(entity)
      switch (getAction(entity)) {
        case ACTION.UPDATE: {
          this.setState(state => ({ items: findAndReplace(state.items, items) }))
          if (this.state.detailView === ENTITY_VIEW) {
            const renderedEntity = this.state.renderedEntity
            const updatedItem = items.find(item => item.selfLink === renderedEntity.selfLink)
            this.setState({ renderedEntity: updatedItem })
          } else if (this.state.detailView === ACTION_VIEW && items.length === 1) {
            this.setState({ detailView: ENTITY_VIEW, renderedEntity: items[0] })
          } else {
            this.resetActionState()
          }
          break
        }
        case ACTION.CREATE: {
          const currentItemsLength = this.state.items.length
          this.setState(state => ({ items: [...state.items, ...items], totalDataSize: state.totalDataSize + items.length }), () => this.updateSizePerPage(currentItemsLength))
          this.resetActionState()
          break
        }
        case ACTION.DELETE: {
          const currentItemsLength = this.state.items.length
          this.setState(state => ({ items: findAndDelete(state.items, items), totalDataSize: state.totalDataSize - items.length }), () => this.updateSizePerPage(currentItemsLength))
          this.resetActionState()
          break
        }
      }
    } else {
      console.log(`${ACTION_STATUS.POTENTIAL} and ${ACTION_STATUS.FAILED} don't need any handling right now.`)
    }
  }

  rowClassNameFormat = (row) => {
    const activeEntity = row.entity.selfLink === get(this.state.renderedEntity, "selfLink")
    if (activeEntity) {
      return "row-selected"
    }
  }

  rowEvents = {
    onClick: this.onRowClick,
  }

  onSortChange = (_page, _sizePerPage, sortField, sortOrder) => {
    const orderBy = this.state.columnDefinitions.find(column => column.property === sortField)
    this.orderBy = orderBy.sortBy,
    this.order = sortOrder
    this.fetchLatestPage(this.sizePerPage, this.currentPage, this.orderBy, this.order, this.filter, this.filterValue, this.searchValue)
  }

  onPaginationChange = (page, sizePerPage) => {
    this.currentPage = page,
    this.sizePerPage = sizePerPage
    this.fetchLatestPage(this.sizePerPage, this.currentPage, this.orderBy, this.order, this.filter, this.filterValue, this.searchValue)
  }

  TYPE_HANDLERS = {
    sort: this.onSortChange,
    pagination: this.onPaginationChange,
    search: this.onSearchChange,
  }

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
    this.TYPE_HANDLERS[type].call(this, page, sizePerPage, sortField, sortOrder, searchText)
  }

  selectedItems = () => (
    getItem(SELECTALL_MODE) ? [-1].concat(getItem(LIST_OF_EXEMPTED)) : getItem(LIST_OF_SELECTED)
  )

  render() {
    const {
      entity,
      className = "",
      hover = true,
      selectRow = entity.actions && entity.actions.length > 0,
      layoutName,
    } = this.props
    const {
      items,
      filteredItems,
      columnDefinitions,
      selectedItemsLength,
      renderedAction,
      detailView,
      renderedEntity,
      filterEntities,
      filterByElement,
      filterValueElement,
      totalDataSize,
      currentPage,
      sizePerPage,
      searchValue,
      showMultiFilter,
    } = this.state

    const defaultSorted = [{
      dataField: (this.state.orderBy && this.state.columnDefinitions && this.state.columnDefinitions.length) && this.state.columnDefinitions.find(column => column.sortBy === this.state.orderBy).property,
      order: this.state.order && this.state.order.toLowerCase(),
    }]

    const CustomSearch = (props) => {
      let input
      const search = () => {
        this.setSelectAllMode(false)
        props.onSearch(input.value)
      }
      const onKeyUp = (event) => {
        if (event.keyCode == 13) {
          event.preventDefault()
          search()
        }
      }
      return (
        <div className="d-flex pb-1">
          <input type="text" className="form-control" placeholder={getFrontendTranslations() ? getFrontendTranslations().table.searchPlaceholder : translate("table.searchPlaceholder")} ref={ n => input = n } onKeyUp={ onKeyUp } defaultValue={searchValue}/>
          <button className="btn btn-default btn-primary" onClick={ search }>{getFrontendTranslations() ? getFrontendTranslations().table.searchPlaceholder : translate("table.searchPlaceholder")}</button>
          <ClearSearchButton { ...props.searchProps } text={getFrontendTranslations() ? getFrontendTranslations().table.clearSearch : translate("table.clearSearch")} className="btn btn-default btn-secondary" onClear={this.onClearSearch}/>
        </div>
      )
    }

    if (!entity || !columnDefinitions) {
      return null
    }

    const actions = renderedAction ? [renderedAction] : []
    const noDataText = get(findByRelation(entity.entities, "describedby"), "properties.emptyText") || (getFrontendTranslations() ? getFrontendTranslations().table.noDataText : translate("table.noDataText"))

    const noItems = !items || (items && !items.length)

    return <div className={`${className} ${layoutName == "customerSelfService" ? "container px-4" : "mx-4"}`}>
      <Card header={entity.title} headerClass={`${layoutName == "customerSelfService" ? "customer-" : ""}${entity.title.replace(/\s/g, "")}`}>
        { (noItems && !this.searchValue && !this.filterValue) ?
          <React.Fragment>
            <div className="pb-3">{noDataText}</div>
            <ActionsAdapter entity={entity} onClick={this.onActionClick} fullDisplay/>
          </React.Fragment> :
          <React.Fragment>
            <FormSubmissionWrapper onActionResolve={this.onActionResolve}>
              <SimpleActionModal
                title={renderedAction && renderedAction.title}
                showModal={this.state.showModal}
                action={renderedAction}
                closeModal={this.onModalClose}/>
            </FormSubmissionWrapper>
            { filterEntities.length > 0 && <FilterBar onFilter={this.onFilter} filterByElement={filterByElement} onFilterByChange={this.onFilterByChange} filterValueElement={filterValueElement} onFilterValueChange={this.onFilterValueChange} filter={this.state.filter} filterValue={this.state.filterValue} showMultiFilter={showMultiFilter} deleteFilter={this.deleteFilter} fetchEachFilterByElement={this.fetchEachFilterByElement}/>}

            <ToolkitProvider
              keyField={UNIQUE_ID}
              data={filteredItems}
              columns={columnDefinitions}
              search>

              {
                toolkitprops => (
                  <React.Fragment>
                    <div className="react-bs-table-tool-bar">
                      <div className="row">
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-8">
                          {selectRow &&
                            <SelectAllBar
                              selected={selectedItemsLength}
                              total={totalDataSize}
                              onSelectAll={this.onSelectAll}
                              onClearSelection={this.clearSelection}/>}
                        </div>
                        {this.isSearchable && <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4">
                          <CustomSearch { ...toolkitprops.searchProps }/>
                        </div>}
                      </div>
                    </div>
                    <BootstrapTable
                      wrapperClasses={`${layoutName == "customerSelfService" ? "customer-table" : "admin-table"} table-responsive`}
                      { ...toolkitprops.baseProps }
                      bootstrap4
                      remote
                      ref={this.table}
                      hover={hover}
                      rowEvents={this.props.rowClick ? this.rowEvents : undefined}
                      rowClasses={this.rowClassNameFormat}
                      defaultSorted={ defaultSorted }
                      pagination={ paginationFactory({ page: currentPage, sizePerPage, totalSize: totalDataSize }) }
                      onTableChange={ this.handleTableChange }
                      selectRow={selectRow ? this.selectOptions() : undefined}
                    />
                  </React.Fragment>
                )
              }
            </ToolkitProvider>

            <div className="d-flex">
              {!this.props.isSecondaryTable && <BulkActionSelection actions={this.actions && this.actions.extracted} items={selectedItemsLength} onRenderAction={this.onRenderBulkAction} resetActionState={this.resetActionState}/>}
              <ActionsAdapter entity={entity} onClick={this.onActionClick} fullDisplay/>
            </div>
          </React.Fragment>
        }
      </Card>
      <DetailViewSection detailView={detailView} actions={actions} selectedItems={this.selectedItems()} renderedEntity={renderedEntity} onActionResolve={this.onActionResolve} layoutName={layoutName} innerRef={this.detailSection}/>
      <style jsx global>{`
          .react-bootstrap-table-page-btns-ul {
            float: right;
          }
          .row-selected {
            background-color: rgba(0, 0, 0, 0.2);
          }
          .react-bs-table-tool-bar input[type="text"]{
            height: 35px;
          }
          .react-bs-table-tool-bar > .row > div:first-child {
            text-align: center;
            flex: 1;
            max-width: 100%;
          }
          .react-bs-table-tool-bar > .row > div:nth-child(2) {
            margin: 0.5rem auto;
            flex: none;
            max-width: 100%;
          }
          @media (min-width: 768px) {
            .react-bs-table-tool-bar > .row > div:first-child {
              text-align: left;
            }
            .react-bs-table-tool-bar > .row > div:nth-child(2) {
              margin-top: 0;
              margin-bottom: 0;
              max-width: 50%;
            }
          }

          @media
          only screen and (max-width: 760px),
          (min-device-width: 768px) and (max-device-width: 1024px)  {
            .table {
              width: auto !important;
            }
          }
      `}</style>
      <style jsx global>{`
        .react-bs-table-container tr {
          cursor: ${this.props.rowClick ? "pointer" : "auto"};
        }
      `}</style>
    </div>
  }
}

TableRenderer.propTypes = {
  entity: EntityType.isRequired,
  className: PropTypes.string,
  layoutName: PropTypes.string,
  search: PropTypes.bool,
  hover: PropTypes.bool,
  pagination: PropTypes.bool,
  sizePerPage: PropTypes.number,
  selectRow: PropTypes.bool,
  exportCSV: PropTypes.bool,
  rowClick: PropTypes.bool,
  onRowClick: PropTypes.func,
  currentUrl: PropTypes.string,
  prevUrl: PropTypes.string,
  isSecondaryTable: PropTypes.bool,
}
TableRenderer.defaultProps = {
  rowClick: true,
  isSecondaryTable: false,
}

export default TableRenderer
