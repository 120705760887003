import React from "react"
import PropTypes from "prop-types"

import EntityType from "api/SirenEntityType"
import InfoBlock from "components/InfoBlock"
import PropertyTranslation from "components/PropertyTranslation"
import PropertiesLabelValue from "components/PropertiesLabelValue"
import Email, { isEmail } from "components/Email"
import FontAwesomeIcon from "components/FontAwesome/FontAwesomeIcon"
import formatPrice from "formatPrice"

const getValueForKey = (key, properties, mask) => {
  let value = properties[key]
  if (value === undefined || value === null || value === "") {
    value = null
  } else if (mask.indexOf(key) !== -1) {
    value = value.replace(/[A-Za-z0-9](?=[A-Za-z0-9]{3})/g, "*")
  } else if (value === true || value === false) {
    value = <input type="checkbox" disabled checked={value}/>
  } else if (isEmail(value)) {
    value = <Email email={value}/>
  } else if (key === "remarks") {
    value = <span className="text-warning"><FontAwesomeIcon title="warning" iconURI="fa:exclamation-circle" className="mr-2"/>{value}</span>
  } else if (key === "prettyMessage") {
    value = <pre>{value}</pre>
  }

  return value
}

const PropertiesBox = ({ entity, keys = [], title, label, mask = [], headerClass = "" }) => {
  if (!entity) { return null }
  if (title === undefined) {
    title = entity.title
  }

  keys = Array.isArray(keys) ? keys : keys.toString().split(",")
  mask = Array.isArray(mask) ? mask : mask.toString().split(",")
  const properties = entity.properties
  return (
    <InfoBlock title={title} className="mb-4" headerClass={headerClass}>
      {
        keys
        .map(key => {
          // key can be a single prop "email" or a combination "price+currency"
          const combinedKeys = key.split("+")
          const labelProp = combinedKeys[0]
          const currencyProp = combinedKeys[1] && combinedKeys[1] === "currency" ? combinedKeys[1] : undefined
          const value = currencyProp ? (properties[labelProp] ? formatPrice(properties[labelProp], properties[currencyProp]) : "") :
            combinedKeys.reduce((result, combinedKey) => {
              const formattedValue = getValueForKey(combinedKey, properties, mask)
              formattedValue && result.push(<span key={combinedKey}>{formattedValue} </span>)
              return result
            }, [])
          // if not all values are mapped, that means at least one of them is null
          if (value.length === 0 || (!currencyProp && value.length != combinedKeys.length)) {
            return null
          }

          return <PropertiesLabelValue
            key={key}
            label={label ?
              <PropertyTranslation entity={entity} name={labelProp}/>
              : undefined}
            value={value}/>
        })
      }
    </InfoBlock>
  )
}

PropertiesBox.propTypes = {
  entity: EntityType,
  keys: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  label: PropTypes.bool,
  mask: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  headerClass: PropTypes.string,
}

export default PropertiesBox
