import React from "react"
var QRCode = require("qrcode.react")
import SirenEntityType from "api/SirenEntityType"
import {
  findByClass,
  filterByClass,
  filterByType,
  resolveLinks,
} from "api/utils"
import Card from "components/Card"
import PropertiesBox from "components/PropertiesBox"
import { IdentificationMediumClass, IdentificationComponentClass, BillingSegmentListClass } from "components/registry"
import PropertiesLabelValue from "components/PropertiesLabelValue"
import InfoBlock from "components/InfoBlock"
import TableRenderer from "components/TableRenderer/TableRenderer"
import ActionsRenderer from "components/TableRenderer/ActionsRenderer"
import Link from "components/Link"
import PropTypes from "prop-types"
import { getFontAwesomeIconClass } from "../../../FontAwesome/fontawesome"

const imagesAddToWallet = {
  de: require("images/wallet/DE_Add_to_Apple_Wallet.svg"),
  en: require("images/wallet/EN_Add_to_Apple_Wallet.svg"),
  es: require("images/wallet/ES_Add_to_Apple_Wallet.svg"),
  fi: require("images/wallet/FI_Add_to_Apple_Wallet.svg"),
  fr: require("images/wallet/FR_Add_to_Apple_Wallet.svg"),
  nl: require("images/wallet/NL_Add_to_Apple_Wallet.svg"),
}

const ContractBooking = ({ entity = {}, layoutName }) => {

  const identificationMedium = filterByClass(entity.entities, IdentificationMediumClass)
  const actionNames = ["cancel"]
  const linkRelations = ["parking_facility_page"]
  const actions = (entity.actions && entity.actions.length) && entity.actions.filter(action => actionNames.indexOf(action.name) !== -1)
  const links = resolveLinks(entity.links, linkRelations)
  const billingSegmentListEntity = findByClass(entity.entities, BillingSegmentListClass)

  return (
    <Card header={entity.title} headerClass={`${layoutName == "customerSelfService" ? "customer-" : ""}ContractBooking`}>
      <PropertiesBox
        entity={entity}
        keys="orderNumber,contractBookingNumber,parkingProductName,parkingFacilityName,renewal_setting,state,validFrom,validUntil,parkingLotNumber"
        label/>
      { identificationMedium.length > 0 && <InfoBlock title={identificationMedium[0].properties.componentsTitle} className="mb-4">
        { identificationMedium.map(identificationMediumEntity => {
          const identificationComponentEntities = filterByClass(identificationMediumEntity.entities, IdentificationComponentClass)
          return <React.Fragment key={identificationMediumEntity.properties.identifier}>
            {identificationComponentEntities.map(e => {
              if (/QR/i.test(e.properties.type)) {
                // const downloadLinkWallet = findByType(e.links, "application/vnd.apple.pkpass")
                const downloadLinksWallet = filterByType(e.links, "application/vnd.apple.pkpass")
                return <div className="row">
                  <div className="offset-6"></div>
                  <div className="cols-6">
                    <div className="flex">
                      <div className="cols-6">
                        <span>{ e.properties.identifier }</span>
                        <hr />
                        <QRCode value={e.properties.identifier} renderAs="svg"/>
                      </div>
                      <div className="cols-6">
                        {downloadLinksWallet.map(downloadLinkWallet => {
                          return <div className="pt-3" key={downloadLinkWallet.title}>
                            <a href={downloadLinkWallet.href} className={`${downloadLinkWallet.klass} add-to-wallet-button`}>
                              {downloadLinkWallet.icon ? (
                                <span>
                                  <i className={`${getFontAwesomeIconClass(downloadLinkWallet.icon)} pr-2`}/>
                                  <span>{ downloadLinkWallet.title }</span>
                                </span>
                              ) : (
                                <img src={imagesAddToWallet[downloadLinkWallet.lang] || imagesAddToWallet.en} style={{ height: "64px" }}/>
                              )}
                            </a>
                          </div>
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              }
              else {
                return <PropertiesLabelValue
                  key={e}
                  label={e.properties.type}
                  value={e.properties.identifier}/>
              }
            })}
          </React.Fragment>
        })
        }
      </InfoBlock>}
      { billingSegmentListEntity &&
        <TableRenderer
          entity={billingSegmentListEntity}
          className="billing-segments"
          rowClick={true}
          selectRow={false}
          search={false}
          pagination={false}
          isSecondaryTable={true}
          exportCSV={false}/> }
      { links && links.map(link => (
        <div key={link} className="align-self-center mt-4">
          <Link className="btn btn-secondary" link={link}>{link.title}</Link>
        </div>
      ))}
      { !!entity.actions.length && <hr/> }
      <ActionsRenderer actions={actions}/>
      <style jsx global>{`
      .actions {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
      .actions div {
        border: none;
      }
      .billing-segments {
        margin: 0px !important;
      }
      .actions form > div {
        padding:  0px !important;
        margin-bottom:  0px;
      }
      .actions div > h5 {
        display: none;
      }
    `}</style>
    </Card>
  )
}

ContractBooking.propTypes = {
  entity: SirenEntityType.isRequired,
  layoutName: PropTypes.string,
}

export default ContractBooking
