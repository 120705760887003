import React from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"

import EntityLink from "components/EntityLink"

// eslint-disable-next-line no-unused-vars
const EntityButtonLink = ({ children, color, buttonClassName, ...props }) => (
  <EntityLink {...props}>
    {({ title }) => <Button color={color} className={buttonClassName}>{title}</Button> }
  </EntityLink>
)
EntityButtonLink.propTypes = {
  ...EntityLink.propTypes,
  color: PropTypes.string,
  buttonClassName: PropTypes.string,
}

export default EntityButtonLink
