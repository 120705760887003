import PropTypes from "prop-types"
import EntityType from "api/SirenEntityType"

const DateAdapter = ({ entity, property }) => {
  const date = DateAdapter.value(entity, property)
  return date && date.toLocaleDateString("de-DE") || null // i18n
}
DateAdapter.csvValue = (entity, property) => {
  const date = entity.properties[property]
  return (date && date.toISOString()) || ""
}
DateAdapter.value = (entity, property) => entity.properties[property] ? new Date(entity.properties[property]) : ""
// transfrom  "Mon Oct 01 2018 02:00:00 GMT+0200 (Central European Summer Time)" into "2018-10-01T00:00:00.000Z" and then 2018-10-01
DateAdapter.filterValue = (value) => value && value.toISOString().split("T")[0]

DateAdapter.sort = (a, b, order, property) =>
  order === "desc"
    ? b[property] - a[property]
    : a[property] - b[property]

DateAdapter.propTypes = {
  entity: EntityType,
  property: PropTypes.string,
}

export default DateAdapter
