import React from "react"
import PropTypes from "prop-types"

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(@)[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+(?![-_])$/
export const isEmail = (value) => EMAIL_REGEX.test(value)

const Email = ({ email, text = email }) => <a href={`mailto:${email}`}>{text}</a>
Email.propTypes = {
  email: PropTypes.string,
  text: PropTypes.node,
}

export default Email
