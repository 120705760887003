import React, { Fragment, PureComponent } from "react"
import PropTypes from "prop-types"

import Select from "components/FormRenderer/components/Select"
import Card from "components/Card"
import { getFrontendTranslations } from "components/Layout/layoutManager"
import translate from "components/i18n/translate"
import { Input, FormGroup, Label } from "reactstrap"
import DatePicker from "react-datepicker"


class FilterBar extends PureComponent {

  state = {
    count: 0,
    value: [],
  }

  componentDidMount() {
    this.nativeSelect = []
    this.setState(() => ({ value: this.props.filterValueElement ? this.props.filterValueElement : [] }))
  }

  countChange = () => {
    this.setState({ count: this.state.count + 1 })
  }

  deleteCount = () => {
    this.setState({ count: this.state.count - 1 })
  }

  handleInputChange = (e, id) => {
    this.props.onFilterValueChange(e, id)
  }

  handleDateChange = (value, id) => {
    var val = [...this.state.value]
    if (val[id] == undefined) {
      val[id] = {}
    }
    val[id].values = value
    this.setState(() => ({ value: val }), () => {
      const event = new Event("change", { bubbles: true })
      this.nativeSelect[id] && this.nativeSelect[id].dispatchEvent(event)
      this.handleInputChange(event, id)
    })
  }

  innerRef = (element, id) => {
    this.nativeSelect[id] = element
  }

  createMultiFilter = (filterByElement, onFilterByChange, filterValueElement, onFilterValueChange, deleteFilter, isSafari, isIE) => {
    let additionalFilter = []
    for (let i = 0; i < filterByElement.length - 1; i++) {
      additionalFilter.push(
        <div className="row">
          <strong>{getFrontendTranslations() ? getFrontendTranslations().table.contentFilter : translate("table.contentFilter")}</strong>
          <div className="col-xs-5 col-sm-5 col-md-5 col-lg-3 p-0">
            { filterByElement[i + 1] && <Select title={filterByElement[i + 1].title} value={filterByElement[i + 1].values} onChange={(e)=>onFilterByChange(e, `${i + 1}`)}/> }
          </div>
          <div className="col-xs-5 col-sm-5 col-md-5 col-lg-3 p-0">
            { filterValueElement[i + 1] && (filterValueElement[i + 1].type == "Select" ?
              <Select title={filterValueElement[i + 1].title} value={filterValueElement[i + 1].values} onChange={(e)=>onFilterValueChange(e, `${i + 1}`)}/> :
              <FormGroup>
                <Label>{filterValueElement[i + 1].title}
                  {(isSafari || isIE) ?
                    <React.Fragment>
                      <DatePicker
                        onChange={(val)=>this.handleDateChange(val, `${i + 1}`)}
                        className="form-control"
                        name={`filter${i + 1}`}
                        selected={(this.state.value[i + 1] && this.state.value[i + 1].values) ? new Date(this.state.value[i + 1].values) : ""}
                      />
                      <div className="hidden-date-input">
                        <Input
                          innerRef={(element)=>this.innerRef(element, `${i + 1}`)}
                          type="text"
                          name={`filterInput${i + 1}`}
                          id={`filter${i + 1}`}
                          value={(this.state.value[i + 1] && this.state.value[i + 1].values) ? this.state.value[i + 1].values : ""}
                          onChange={this.handleInputChange}
                          readOnly
                        />
                      </div>
                    </React.Fragment>
                    :
                    <Input
                      type="date"
                      name={`filter${i + 1}`}
                      id={`filter${i + 1}`}
                      defaultValue={filterValueElement[i + 1].values}
                      onChange={(e)=>onFilterValueChange(e, `${i + 1}`)}>
                    </Input>
                  }
                </Label>
              </FormGroup>
            )}
          </div>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 p-0 mt-3 add-filter">
            <a href="#" onClick={(e)=>{deleteFilter(e, `${i + 1}`);this.deleteCount()}}><i className="fa fa-trash"></i></a>
          </div>
        </div>
      )
    }
    return additionalFilter
  }

  render() {
    const { filterByElement, onFilterByChange, filterValueElement, onFilterValueChange, onFilter, fetchEachFilterByElement, deleteFilter } = this.props
    const { count } = this.state

    // Safari 3.0+ "[object HTMLElementConstructor]"
    // eslint-disable-next-line no-undef
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]" })(!window["safari"] || (typeof safari !== "undefined" && safari.pushNotification))

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/false || !!document.documentMode

    if (!filterByElement) { return null }

    return <Fragment>
      <Card className="table-filter">
        <div className="row">
          <strong>{getFrontendTranslations() ? getFrontendTranslations().table.contentFilter : translate("table.contentFilter")}</strong>
          <div className="col-xs-5 col-sm-5 col-md-5 col-lg-3 p-0">
            {filterByElement[0] && <Select title={filterByElement[0].title} value={filterByElement[0].values} onChange={(e)=>onFilterByChange(e, "0")}/>}
          </div>
          <div className="col-xs-5 col-sm-5 col-md-5 col-lg-3 p-0">
            { filterValueElement[0] && (filterValueElement[0].type == "Select" ?
              <Select title={filterValueElement[0].title} value={filterValueElement[0].values} onChange={(e)=>onFilterValueChange(e, "0")}/> :
              <FormGroup>
                <Label>{filterValueElement[0].title}
                  {(isSafari || isIE) ?
                    <React.Fragment>
                      <DatePicker
                        onChange={(val)=>this.handleDateChange(val, "0")}
                        className="form-control"
                        name="filter0"
                        selected={(this.state.value[0] && this.state.value[0].values) ? new Date(this.state.value[0].values) : ""}
                      />

                      <div className="hidden-date-input">
                        <Input
                          innerRef={(element)=>this.innerRef(element, "0")}
                          type="text"
                          name="filterInput0"
                          id="filter0"
                          value={(this.state.value[0] && this.state.value[0].values) ? this.state.value[0].values : ""}
                          onChange={this.handleInputChange}
                          readOnly
                        />
                      </div>
                    </React.Fragment>

                    :
                    <Input
                      type="date"
                      name="filter0"
                      id="filter0"
                      defaultValue={filterValueElement[0].values}
                      onChange={(e)=>onFilterValueChange(e, "0")}>
                    </Input>
                  }
                </Label>
              </FormGroup>
            )
            }
          </div>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 p-0 mt-3 add-filter">
            <a href="#" onClick={(e)=>{deleteFilter(e, "0");this.deleteCount()}}><i className="fa fa-trash"></i></a>
          </div>

        </div>
        {this.createMultiFilter(filterByElement, onFilterByChange, filterValueElement, onFilterValueChange, deleteFilter, isSafari, isIE)}
        <div className="add-filter">
          <div className="col-xs-3 col-sm-3 col-md-2 col-lg-2 pr-2 mt-3">
            <a href="#" onClick={(e)=>{fetchEachFilterByElement(e, `${count + 1}`);this.countChange()}} className="float-right"><i className="fas fa-plus-circle"></i>{getFrontendTranslations() ? getFrontendTranslations().table.addFilter : translate("table.addFilter")}</a>
          </div>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 p-0 mt-3">
            <button className="btn btn-default btn-primary" onClick={onFilter}>{getFrontendTranslations() ? getFrontendTranslations().table.filterPlaceholder : translate("table.filterPlaceholder")}</button>
          </div>
        </div>
      </Card>
      <style jsx global>{`
      .table-filter .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1.25rem;
      }

      .table-filter .form-group {
        margin: 1rem 0;
      }

      @media (max-width: 576px) {
        .table-filter .row {
          flex-direction: column;
        }
      }

      .table-filter .add-filter {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 18px;
      }

      .hidden-date-input {
        display: none;
      }

      .react-datepicker-wrapper {
        display: block !important;
      }

    `}</style>
    </Fragment>
  }
}

FilterBar.propTypes = {
  filterByElement: PropTypes.object,
  onFilterByChange: PropTypes.func.isRequired,
  translate: PropTypes.func,
  filterValueElement: PropTypes.array,
  onFilterValueChange: PropTypes.func.isRequired,
  onFilter: PropTypes.func,
  filter: PropTypes.array,
  filterValue: PropTypes.array,
  deleteFilter: PropTypes.func,
  showMultiFilter: PropTypes.bool,
  fetchEachFilterByElement: PropTypes.func,
}

export default FilterBar
