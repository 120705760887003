import PropTypes from "prop-types"
import EntityType from "api/SirenEntityType"

import DateAdapter from "components/TableRenderer/adapters/DateAdapter"

const DateTimeAdapter = ({ entity, property }) => {
  const date = DateAdapter.value(entity, property)
  return date && date.toLocaleString("de-DE") || null // i18n
}
Object.keys(DateAdapter).forEach(prop => DateTimeAdapter[prop] = DateAdapter[prop])

DateTimeAdapter.propTypes = {
  entity: EntityType,
  property: PropTypes.string,
}

export default DateTimeAdapter
