import React from "react"

import Card from "components/Card"

import SirenEntityType from "api/SirenEntityType"
import { self } from "api/links"
import { findByRelationStrict } from "api/utils"
import Entity from "components/Entity"
import PropertiesTable from "components/PropertiesTable"

import getTranslations from "components/i18n/getTranslations"

const GenericEntity = ({ entity }) => {
  if (!entity
    || process.env.NODE_ENV !== "development"
    || findByRelationStrict([entity], "i18n")
    || findByRelationStrict([entity], "accessToken")) {
    return null
  }

  const {
    "class": classes,
    links,
    actions,
    properties,
    entities,
    rel,
    title,
  } = entity
  if (classes.indexOf("collection") !== -1) {
    // Render all "item" related entites
  }

  return <Card
    header={title}
    subTitle={rel.toString()}
    actions={actions}
    links={links.filter(link => !self([link]))} // filter out the self link
  >
    <PropertiesTable properties={properties} translations={getTranslations(entity)}/>
    {entities.map(e => <Entity key={e.key} entity={e}/>)}
  </Card>
}
GenericEntity.propTypes = {
  entity: SirenEntityType,
}
export default GenericEntity
