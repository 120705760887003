import React from "react"
import PropTypes from "prop-types"

import FormRenderer from "components/FormRenderer/FormRenderer"

const ActionsRenderer = ({ actions, className = "" }) => (
  <div className={`actions ${className}`}>
    {Array.prototype.map.call(actions || [], (action) => (
      <FormRenderer key={`${action.name}-${action.href}`} action={action}/>
    ))}
    <style jsx>{`
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          :global(.actions) {
            display: block !important;
          }
       }
      `}</style>
  </div>
)

ActionsRenderer.propTypes = {
  actions: PropTypes.array,
  className: PropTypes.string,
}

export default ActionsRenderer
