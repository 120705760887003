import PropTypes from "prop-types"

import EntityType from "api/SirenEntityType"
import { findByRelationStrict } from "api/utils"

export const getProperty = (entity, prop) => prop.indexOf(".") !== -1
  ? SubentityPropertyAdapter.value(entity, prop)
  : entity.properties[prop]

const SubentityPropertyAdapter = ({ entity, property }) => SubentityPropertyAdapter.value(entity, property)

SubentityPropertyAdapter.value = (entity, property) => {
  const [subEntityRelation, subEntityProperty] = property.split(".")
  const subEntity = findByRelationStrict(entity.entities, subEntityRelation)
  return (subEntity && subEntity.properties[subEntityProperty]) || null
}

SubentityPropertyAdapter.propTypes = {
  entity: EntityType,
  property: PropTypes.string,
}

export default SubentityPropertyAdapter
