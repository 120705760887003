import decodeURL from "urlHelpers/decodeURL"

const URL = window.URL || window.webkitURL

const getSearchParam = ({ url, name }) => {
  if (!url) { return }
  url = new URL(url)
  if (!url.search && url.pathname.indexOf("/b/") === 0) {
    url = new URL(decodeURL(url.pathname))
  }

  return url.searchParams ? url.searchParams.get(name) : null
}

export default getSearchParam
