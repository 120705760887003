import React from "react"
import SirenEntityType from "api/SirenEntityType"
import withEntity from "components/withEntity"
import { filterByClass } from "api/utils"
import PropertiesBox from "components/PropertiesBox"
import { PostalAddressClass } from "components/registry"

const Address = ({ entity = {} }) => {
  const addressEntity = filterByClass(entity.entities, PostalAddressClass)
  if (!addressEntity) { return null }
  return (
    addressEntity.map(eachEntity => (
      <PropertiesBox
        key={eachEntity.title}
        entity={eachEntity}
        keys="streetAddress,addressLocality,addressRegion,postalCode,addressCountry"
        label/>
    ))
  )
}

Address.propTypes = {
  entity: SirenEntityType,
}

export default withEntity(Address, {
  entities: ["address", "deliveryAddress"],
})
