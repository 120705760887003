
import React from "react"

import { Container } from "reactstrap"
import SirenEntityType from "api/SirenEntityType"
import MultiTableRenderer from "components/TableRenderer/MultiTableRenderer"

const Contract = ({ entity }) => (
  <Container className="p-0">
    <MultiTableRenderer entity={entity}/>
  </Container>
)

Contract.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default Contract
