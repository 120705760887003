import React from "react"
import PropTypes from "prop-types"

import FormRenderer from "components/FormRenderer/FormRenderer"
import Card from "components/Card"
import BulkActionForm from "components/TableRenderer/BulkActionForm"
import ProductCreatorForm from "components/TableRenderer/ProductCreatorForm"
import BookingRequestForm from "components/TableRenderer/BookingRequestForm"
import { BULK_ACTION_CLASS, PRODUCT_CREATOR_ACTION_CLASSES, BOOKING_REQUEST_FORM_CLASS } from "api/actions"

const CardActionsRenderer = ({ actions, items, className = "" }) => (
  <div className={`actions ${className}`}>
    {Array.prototype.map.call(actions || [], (action) => {
      let form = null
      if (action.class.indexOf(BULK_ACTION_CLASS) !== -1) {
        form = <BulkActionForm action={action} items={items}/>
      } else if (PRODUCT_CREATOR_ACTION_CLASSES.indexOf(action.name) !== -1) {
        form = <ProductCreatorForm action={action}/>
      } else if (action.class.indexOf(BOOKING_REQUEST_FORM_CLASS) !== -1) {
        form = <BookingRequestForm action={action}/>
      } else {
        form = <FormRenderer action={action}/>
      }

      return (
        <Card key={`${action.name}-${action.href}`} header={action.title}>
          {form}
        </Card>
      )})}
      
    {actions && actions.length > 0 && actions.filter(action => action.name == "enter-license-plate-numbers").length > 0 &&
      <style jsx global>{`
      .actions {
        position: relative;
      }
      .actions > form:nth-child(2) {
        position: absolute;
        right: 0;
        bottom: 0;
      }
      .actions form > div {
        padding:  0px !important;
        margin-bottom:  0px;
        border: none;
      }
      .actions div.p-4.card:not(:first-child) div > h5, .actions div.p-4.card:not(:first-child) div > h6 {
        display: none;
      }
      .actions div > button {
        position: absolute;
        top: -5.8rem;
        margin-top: 3.35rem;
      }
      `}</style>
    }
  </div>
)

CardActionsRenderer.propTypes = {
  actions: PropTypes.array,
  items: PropTypes.array,
  className: PropTypes.string,
}

export default CardActionsRenderer
