import React from "react"

import SirenEntityType from "api/SirenEntityType"
import { mapEntitiesByClass } from "api/entities"
import Card from "components/Card"
import { TextBlockClass } from "components/registry"

const OperatorDashboard = ({ entity }) => (
  mapEntitiesByClass(entity.entities, TextBlockClass, ({ title, properties: { text } }) => (
    <Card key={title} className="m-4" title="title">{text}</Card>
  ))
)

OperatorDashboard.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default OperatorDashboard
