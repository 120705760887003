import React, { PureComponent } from "react"

import PropTypes from "prop-types"
import { ActionType } from "api/SirenEntityType"

import { Button } from "reactstrap"
import ActionsRenderer from "components/TableRenderer/ActionsRenderer"

import FormSubmissionWrapper from "components/FormRenderer/FormSubmissionWrapper"
import withFormContext from "components/FormRenderer/withFormContext"
import { isActionSuccess } from "components/FormRenderer/actionEntity"

class ActionToggle extends PureComponent {
  state = {
    showAction: false,
  }

  toggleAction = () => {
    this.setState((prevState) => ({
      showAction: !prevState.showAction,
    }))
  }

  onActionResolve = entity => {
    const { onActionResolve } = this.props.context
    onActionResolve && onActionResolve(entity)
    isActionSuccess(entity) && this.setState({ showAction: false })
  }

  render() {
    const { action, className } = this.props
    if (!action) {
      return null
    }

    return <div>
      <Button secondary className={className} onClick={this.toggleAction}>{action.title}</Button>
      {
        this.state.showAction && <FormSubmissionWrapper onActionResolve={this.onActionResolve}>
          <ActionsRenderer actions={[action]}/>
        </FormSubmissionWrapper>
      }
      <style jsx global>{`
        .actions {
          margin-top: 10px;
        }
      `}</style>
    </div>
  }
}

ActionToggle.propTypes = {
  action: ActionType,
  className: PropTypes.string,
  context: PropTypes.object,
}

export default withFormContext(ActionToggle)
