import React from "react"
import PropTypes from "prop-types"
import { FormGroup, Label } from "reactstrap"
import ReactSelect from "react-select"

import { isDisabled, getLimitBy, getGroupBy, safeArray } from "components/FormRenderer/form"


const getOptionLabel = option => option.name
// limitBy a prop. When one element with this prop is selected, all the other ones are disabled
const isOptionDisabled = (limitBy) => (option, selecteds) => selecteds.some(selected => selected[limitBy] === option[limitBy])

class MultipleSelect extends React.Component {
  state = {
    selectedOptions: safeArray(this.props.value).filter(v => v.selected),
  }

  // the react-select only gives us an array of selected option
  // so we generate a "fake" change event on our 'native' select
  handleChange = (selectedOptions) => {
    this.setState(() => ({ selectedOptions }), () => {
      const event = new Event("change", { bubbles: true })
      this.nativeSelect && this.nativeSelect.dispatchEvent(event)
    })
  }

  innerRef = (element) => {
    this.nativeSelect = element
    typeof this.props.innerRef === "function" && this.props.innerRef(element)
  }

  render() {
    const { value, classes, name, onChange, title, error, className = "" } = this.props
    let values = safeArray(value)
    const selectedOptions = this.state.selectedOptions.filter(option => values.some(v => v.value === option.value))

    const limitBy = getLimitBy(classes)
    const groupBy = getGroupBy(classes)
    // React Select data structures:
    // 1 - standard: [{ name: "Rick", value: 23 }, { name: "Morty", value: 7 }]
    // 2 - grouped: [{ label: "Group 1", options: [{ name: "Rick", value: 23 }, ...] }, { label: "Group 2", options: [...]}]
    if (groupBy) {
      values = values.reduce((result, v) => {
        const resultGroup = result.find(r => r.label === v[groupBy])
        resultGroup
          ? resultGroup.options.push(v)
          : result.push({ label: v[groupBy], options: [v] })
        return result
      }, [])
    }

    return (
      <FormGroup className={name}>
        <Label>
          {title}
          <div className={isDisabled(classes) ? "disabled" : undefined}>
            <ReactSelect
              value={selectedOptions}
              onChange={this.handleChange}
              options={values}
              getOptionLabel={getOptionLabel}
              isOptionDisabled={limitBy ? isOptionDisabled(limitBy) : undefined}
              isMulti
              isDisabled={isDisabled(classes)}
              closeMenuOnSelect={false}
              blurInputOnSelect={false/*https://github.com/JedWatson/react-select/issues/2771#issuecomment-410080520*/}
            />
          </div>
          <select
            multiple
            ref={this.innerRef}
            name={name}
            onChange={onChange}
            value={selectedOptions.map(option => option.value)}
            className={`${className} select-hidden-input ${error ? "form-control is-invalid" : ""}`}
            readOnly>
            {selectedOptions.map(option => <option key={`${name}-${option.name}`} value={option.value}>{option.name}</option>)}
          </select>
          {error}
        </Label>
        <style jsx>{`
        .select-hidden-input {
          display: none;
        }
      `}</style>
      </FormGroup>
    )
  }
}

MultipleSelect.propTypes = {
  error: PropTypes.node,
  title: PropTypes.node,
  value: PropTypes.any,
  classes: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  innerRef: PropTypes.func,
}

export default MultipleSelect
