import React from "react"
import PropTypes from "prop-types"
import { Collapse, Navbar, Nav, NavItem, NavbarToggler } from "reactstrap"

import Link from "components/Link"
import NavLink from "components/NavLink"
import EntityType, { LinkType, ActionType } from "api/SirenEntityType"
import { findByName } from "api/actions"
import Logout from "components/Layout/Header/Logout"
import HeaderDropdown from "components/Layout/Header/HeaderDropdown"
import Entity from "components/Entity"
import TopNavContent from "components/Layout/TopNavContent"

const sidebarToggle = (e) => {
  e.preventDefault()
  document.body.classList.toggle("sidebar-hidden")
}

const mobileSidebarToggle = (e) => {
  e.preventDefault()
  document.body.classList.toggle("sidebar-mobile-show")
}

class Header extends React.Component {
  state = {
    isOpen: false,
  }
  toggle = (event) => {
    event.stopPropagation()
    this.setState((state) => ({ isOpen: !state.isOpen }))
  }

  render() {
    const { indexLink, loginLink, statusText, customer = {}, sidebar, headerDropdown, topNav, currentUrl } = this.props
    const { isOpen } = this.state
    const logoutAction = this.props.logoutAction || findByName((headerDropdown || {}).actions, "logout")
    const toggleIfOpen = (e) => isOpen && this.toggle(e)

    return (
      <header className={`app-header navbar ${isOpen ? "header-sm-open" : "h-auto"}`} onClick={toggleIfOpen}>
        <div className="w-100 d-flex pb-2 pb-md-0">
          <Navbar className={`app-header-navbar py-0 ${sidebar ? "w-100" : "container"}`} expand="md">
            {sidebar && (
              <React.Fragment>
                <NavbarToggler className="d-lg-none d-md-block" onClick={mobileSidebarToggle}>
                  <span className="navbar-toggler-icon"></span>
                </NavbarToggler>
                <NavbarToggler className="d-md-down-none d-md-block" onClick={sidebarToggle}>
                  <span className="navbar-toggler-icon"></span>
                </NavbarToggler>
              </React.Fragment>
            )}
            <Nav navbar>
              <NavItem rel="index">
                {indexLink && <NavLink link={indexLink}><img src={indexLink.logoHref} alt={indexLink.title} className="logo"/></NavLink>}
              </NavItem>
            </Nav>
            {headerDropdown && <HeaderDropdown entity={headerDropdown} className=""/>}
            <NavbarToggler className="d-sm-up-none" onClick={this.toggle}/>
            <Collapse isOpen={isOpen} navbar>
              <Nav className="d-block d-md-flex container w-auto ml-auto pr-0 mr-auto mr-md-0">
                {(loginLink || logoutAction || customer) && (
                  <NavItem rel="login">
                    {loginLink && <Link link={loginLink} className="btn btn-primary">{loginLink.title}</Link>}
                    <div className="d-flex flex-column justify-content-center align-items-sm-end h-100">
                      <div>
                        {logoutAction && (
                          <React.Fragment>
                            <span className="d-none d-sm-inline">{statusText}</span> <span onClick={toggleIfOpen}><Logout action={logoutAction} btnClassName="pr-sm-0 w-auto"/></span>
                          </React.Fragment>
                        )}
                      </div>
                      {(customer.name || customer.customerNumber) &&
                        <div className={`text-sm-right ${topNav ? "d-none d-sm-block" : ""}`} onClick={(e) => !topNav && toggleIfOpen(e)}>
                          <strong>
                            <Link link={customer.selfServiceLink} title="Dashboard">
                              <i className="fas fa-user m-1 text-dark"></i><span className="text-uppercase">{customer.name}</span><span className="d-none d-sm-inline">{customer.customerNumber && ` / ${customer.customerNumber}`}</span>
                            </Link>
                          </strong>
                        </div>
                      }
                    </div>
                  </NavItem>
                )}
              </Nav>
              {topNav && <div onClick={toggleIfOpen}><TopNavContent links={topNav.links} currentUrl={currentUrl} className="d-sm-none pr-0"/></div>}
            </Collapse>
          </Navbar>
        </div>
        {topNav && <Entity entity={topNav} currentUrl={currentUrl} className="d-none d-sm-flex"/>}
        <style jsx>{`
          .app-header {
            min-height: 100px;
          }
          .app-header > div {
            min-height: 100px;
            box-shadow: 1px 1px 10px grey;
            z-index: 1030;
          }
          .app-header.header-sm-open {
            height: 100vh;
            justify-content: start;
            flex-direction: column;
            background: rgba(0, 0, 0, 0.5);
          }
          .app-header.header-sm-open > div {
            background: white;
          }
          .logo {
            height: 70px;
          }
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            :global(.navbar) {
              align-items: stretch !important;
            }
         }
        `}</style>
      </header>
    )
  }
}

Header.propTypes = {
  indexLink: LinkType,
  loginLink: LinkType,
  logoutAction: ActionType,
  statusText: PropTypes.string,
  customer: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    customerNumber: PropTypes.string,
    selfServiceLink: PropTypes.string,
  }),
  currentUrl: PropTypes.string,
  headerDropdown: EntityType,
  sidebar: PropTypes.bool,
  topNav: EntityType,
}

export default Header
