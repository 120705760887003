
import React, { PureComponent } from "react"

import SirenEntityType from "api/SirenEntityType"
import withEntity from "components/withEntity"

import { findByClass } from "api/utils"
import { permissionCollectionListClass, ParkingProductConfigurationListClass } from "components/registry"

import EntityRenderer from "components/EntityRenderer/EntityRenderer"
import TableRenderer from "components/TableRenderer/TableRenderer"
import Card from "components/Card"
import Image from "components/Image"

class ProductOffer extends PureComponent {
  render() {
    const { entity } = this.props
    const permissionCollectionListEntity = findByClass(entity.entities, permissionCollectionListClass)
    const productConfigurationList = findByClass(entity.entities, ParkingProductConfigurationListClass)

    return (
      <Card header={entity.title}>
        {entity.properties.image && <Image title={`${entity.title} image`} src={entity.properties.image} width="300px" className="mb-4"/>}
        <EntityRenderer entity={entity} properties="id,name,product_name,capacity,bookingsCount,bookings_count,price,setupFee,payrollCycle,paymentMode,customerType,valueAddedTaxIncluded,duration,externalIdentifier,force_active,operator_notes,updated_at,paymentMethods,prebookingMode,processingPeriod,prebookingPeriod,minimumLeaseTerm,poolingType"/>
        { permissionCollectionListEntity &&
          <TableRenderer
            entity={permissionCollectionListEntity}
            className="m-0"
            rowClick={true}
            selectRow={false}
            search={false}
            pagination={false}
            exportCSV={false}/> }
        { productConfigurationList &&
          <TableRenderer
            entity={productConfigurationList}
            className="product-offers"
            selectRow={false}
            search={false}
            pagination={false}
            isSecondaryTable={true}
            exportCSV={false}/> }
        <style jsx global>{`
          .product-offers {
            margin: 0px !important
          }
        `}</style>
      </Card>
    )
  }
}

ProductOffer.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default withEntity(ProductOffer, {
  entities: ["parkingProduct"],
})
