import React, { PureComponent } from "react"
import Proptypes from "prop-types"
import { findByName } from "api/actions"
import FormRenderer from "components/FormRenderer/FormRenderer"
import get from "get"

const PARKING_FACILITY_ID = "parking_facility_id"
const PRODUCT_ID_FIELD = "product_id"
const BOOKING_REQUEST_PRODUCT_CONFIGURATION_ID = "bookingRequestProductConfigurationId"
const PAYMENT_METHOD_TYPE_FIELD = "paymentMethodType"

const getProducts = action => get(findByName(action.fields, PRODUCT_ID_FIELD), "value") || []
const getConfigs = action => get(findByName(action.fields, BOOKING_REQUEST_PRODUCT_CONFIGURATION_ID), "value") || []
const getPaymentMethodTypes = action => get(findByName(action.fields, PAYMENT_METHOD_TYPE_FIELD), "value") || []

class BookingRequestForm extends PureComponent {
  static propTypes = {
    action: Proptypes.object,
  }

  state = {
    action: this.props.action,
  }

  componentDidMount() {
    const { action } = this.state

    this.allProducts = getProducts(this.props.action)
    this.allConfigs = getConfigs(this.props.action)
    this.allPaymentMethodTypes = getPaymentMethodTypes(this.props.action)

    const parkingFacilities = get(findByName(action.fields, PARKING_FACILITY_ID), "value")
    if (parkingFacilities) {
      const defaultParkingFacilityId = parkingFacilities && parkingFacilities[0].value
      this.handleParkingFacilityIdChange(defaultParkingFacilityId)
    }
  }

  onFormChange = (event, field) => {
    if (field.name === PARKING_FACILITY_ID) {
      this.handleParkingFacilityIdChange(event.target.value)
    }
    if (field.name === PRODUCT_ID_FIELD) {
      this.handleParkingProductIdChange(event.target.value)
    }
    if (field.name === BOOKING_REQUEST_PRODUCT_CONFIGURATION_ID) {
      this.handleConfigIdChange(event.target.value)
    }
  }

  handleParkingFacilityIdChange = (parkingFacilityId) => {
    parkingFacilityId = parseInt(parkingFacilityId, 10) // coerce to integer
    const action = { ...this.state.action }

    const productIdField = findByName(action.fields, PRODUCT_ID_FIELD)
    const productsForCurrentParkingFacility = this.allProducts.filter(product => (
      product.parking_facility_ids.includes(parkingFacilityId))
    )

    // this will mutate the field in the `action`
    productIdField.value = productsForCurrentParkingFacility

    if (productsForCurrentParkingFacility && productsForCurrentParkingFacility.length > 0) {
      const currentProduct = productsForCurrentParkingFacility[0]
      this.handleParkingProductIdChange(currentProduct.value)
    }

    this.setState({ action })
  }

  handleParkingProductIdChange = (parkingProductId) => {
    parkingProductId = parseInt(parkingProductId, 10) // coerce to integer
    const action = { ...this.state.action }

    const productConfigField = findByName(action.fields, BOOKING_REQUEST_PRODUCT_CONFIGURATION_ID)
    const configsForCurrentProduct = this.allConfigs.filter(config => (config.parking_product_id === parkingProductId))
    const currentConfig = configsForCurrentProduct.length && this.allConfigs.find(config => config.value === configsForCurrentProduct[0].value)

    // this will mutate the field in the `action`
    if (productConfigField) {
      productConfigField.value = configsForCurrentProduct
      currentConfig && this.handleConfigIdChange(currentConfig.value)
    }

    this.setState({ action })
  }

  handleConfigIdChange = (configId) => {
    configId = parseInt(configId, 10) // coerce to integer
    const action = { ...this.state.action }

    const paymentMethodTypeField = findByName(action.fields, PAYMENT_METHOD_TYPE_FIELD)
    const currentConfig = this.allConfigs.find(config => config.value === configId)
    const possiblePaymentMethodTypesForCurrentConfig = Array.isArray(this.allPaymentMethodTypes) ? this.allPaymentMethodTypes.filter(paymentMethodType => (
      currentConfig.payment_method_types.includes(paymentMethodType.value)
    )) : currentConfig.payment_method_types.filter(paymentMethodType => (
      paymentMethodType == this.allPaymentMethodTypes
    ))

    // this will mutate the field in the `action`
    if (paymentMethodTypeField) {
      paymentMethodTypeField.value = possiblePaymentMethodTypesForCurrentConfig
    }

    // TODO:  add error message to the paymentMethodTypeField that this customer has no payment method
    // matching the selected product config
    // if (possiblePaymentMethodTypesForCurrentProduct.length === 0) {
    //   ...
    // }

    this.setState({ action })
  }

  render() {
    const { action } = this.state
    if (!action) {
      return null
    }

    return <FormRenderer action={action} onChange={this.onFormChange}/>
  }
}

export default BookingRequestForm
