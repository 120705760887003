import React from "react"
import { Progress, Row, Col } from "reactstrap"

import SirenEntityType from "api/SirenEntityType"
import Card from "components/Card"

const getBadgeClassName = (value) =>
  value === "Done"
    ? "badge-success"
    : (value === "Todo" || value === "not live")
      ? "badge-danger"
      : "badge-warning"

const Configuration = ({ entity }) => (
  <Card title={entity.title}>
    {
      Object.values(entity.properties).map(({ title, name, value }) =>
        <Row key={title}>
          <Col md="3"><h6>{title}</h6></Col>
          <Col md="4">
            {name === "progress" ?
              <Progress striped color="success" value={value} className="mb-3"/> :
              <span className={`badge ${getBadgeClassName(value)}`}>{value}</span>}
          </Col>
        </Row>
      )
    }
  </Card>
)
Configuration.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default Configuration
