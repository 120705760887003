import React from "react"
import SirenEntityType from "api/SirenEntityType"
import withEntity from "components/withEntity"
import { findByClass } from "api/utils"
import PropertiesBox from "components/PropertiesBox"
import { CardPaymentMethodClass } from "components/registry"

const CardPaymentMethod = ({ entity = {} }) => {
  const paymentMethodEntity = findByClass(entity.entities, CardPaymentMethodClass)
  if (!paymentMethodEntity) { return null }
  return (
    <PropertiesBox
      entity={paymentMethodEntity}
      keys="ccHolder,ccLast4,ccBrand,ccExp"
      label/>
  )
}

CardPaymentMethod.propTypes = {
  entity: SirenEntityType,
}

export default withEntity(CardPaymentMethod, {
  entities: ["cardPaymentMethod"],
})
