import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"

const FailedFiles = ({ uploadFailedFiles }) => {
  if (!uploadFailedFiles) { return null }

  let failedfilesList = []
  uploadFailedFiles && uploadFailedFiles.map((eachFile) =>
    failedfilesList.push(<li key={eachFile.file}><Row><Col md="3">{eachFile.file}</Col><Col md="5" className="text-danger">{eachFile.error}</Col></Row></li>)
  )
  return failedfilesList
}

FailedFiles.propTypes = {
  uploadFailedFiles: PropTypes.array,
}

export default FailedFiles
