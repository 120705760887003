import React from "react"
import PropTypes from "prop-types"

import { Input, Label, FormGroup } from "reactstrap"

const Checkbox = ({ innerRef, name, title, type, value, onChange, error, required, className = "", id = `${name}-${className}` }) => {
  /* All backend points are not sending us this new notation for now therefore
     the following statement ensure compatibility with that. We can later remove this */
  const normalizedValue = typeof value !== "object" ? { value } : value
  return <FormGroup check className={name}>
    <Input
      innerRef={innerRef}
      id={id}
      className={"form-check-input " + className}
      type={type}
      name={name}
      value={normalizedValue.value}
      invalid={!!error}
      onChange={onChange}
      required={required}
      defaultChecked={!!normalizedValue.selected}
    />
    <Label check className="form-check-label" htmlFor={id}>{title}</Label>
    { error }
  </FormGroup>
}

Checkbox.propTypes = {
  error: PropTypes.node,
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.node,
  type: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  innerRef: PropTypes.func,
}

export default Checkbox
