import React, { PureComponent, Fragment } from "react"
import PropTypes from "prop-types"

import FormRenderer from "components/FormRenderer/FormRenderer"

import { findByType } from "api/actions"
import withFormContext from "components/FormRenderer/withFormContext"
import withFormSubmission from "components/FormRenderer/withFormSubmission"
import TableRenderer from "components/TableRenderer/TableRenderer"
import PageLoader from "components/Layout/PageLoader"

class BulkActionForm extends PureComponent {
  static propTypes = {
    action: PropTypes.object,
    items: PropTypes.array,
    onSubmit: PropTypes.func,
    summary: PropTypes.object,
    submitting: PropTypes.bool,
  }

  onSubmit = ({ data, headers, ...rest }) => {
    const { action, onSubmit, items } = this.props
    const config = findByType(action.fields, "entityId")
    if (!config || !items || items.length === 0) {
      console.log("No items selected")
      return
    }

    const additionalData = items.reduce((result, item) => {
      if (config.name) {
        result[config.name] ? result[config.name].push(item) : result[config.name] = [item]
      }
      return result
    }, {})

    if (headers["Content-Type"] === "multipart/form-data") {
      if (data instanceof FormData) {
        const value = additionalData[config.name]
        Array.isArray(value) ?
          value.forEach(val => data.append(`${config.name}[]`, val)) :
          data.append(config.name, additionalData[config.name])
      }
    } else {
      data = { ...data, ...additionalData }
    }
    onSubmit && onSubmit({ data, headers, ...rest })
  }

  render() {
    const { action, summary, submitting } = this.props
    if (!action) {
      return null
    }

    return <Fragment>
      <FormRenderer action={action} onSubmit={this.onSubmit} className="bulk-action-form"/>
      {submitting && <div className="bulk-action-loader"><PageLoader loading={submitting}/></div>}
      { summary &&
      <TableRenderer
        entity={summary}
        rowClick={false}
        selectRow={false}
        search={false}
        pagination={false}
        isSecondaryTable={true}
        exportCSV={false}/> }
      <style jsx global>{`
        .bulk-action-form .row div.col-md-6:last-child button[type="submit"] {
          float: right;
        }
        .bulk-action-loader > .page-loader {
          position: fixed !important;
        }
      `}</style>
    </Fragment>
  }
}

export default withFormContext(withFormSubmission(BulkActionForm))
