import React from "react"
import PropTypes from "prop-types"

const InfoTooltip = ({ tooltipText }) => <i className="fa fa-info-circle text-secondary" title={tooltipText}></i>

InfoTooltip.propTypes = {
  tooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
}

export default InfoTooltip
