import React, { PureComponent, Suspense } from "react"
import PropTypes from "prop-types"
import { Row, Form, FormFeedback, Col } from "reactstrap"

import Card from "components/Card"
import withFormContext from "components/FormRenderer/withFormContext"
import withFormSubmission from "components/FormRenderer/withFormSubmission"
import { ActionType, LinksType } from "api/SirenEntityType"
import { DOWNLOAD_ACTION_CLASS, findByName } from "api/actions"
import {
  getColSize,
  isInline,
  formatFormClassName,
  getDisplayRules,
  isDiscriminator,
  isStrictMode,
  isRequired,
  hasTooltip,
  isAside,
  addClass,
  removeClass,
  getDiscriminatorKey,
  SPLIT_NEW_LINE,
} from "components/FormRenderer/form"
import formRegistry from "components/FormRenderer/formRegistry"
import Link from "components/Link"
import getFieldTitleAndUnmatchedLinks from "components/FormRenderer/relatedLinks"
import RequiredTooltip from "components/FormRenderer/components/RequiredTooltip"
import InfoTooltip from "components/FormRenderer/components/InfoTooltip"
import { getItem } from "storage"
import translate from "components/i18n/translate"
import { getEnvTitle, getFrontendTranslations } from "components/Layout/layoutManager"

// Can be exposed from a separate util file if this is needed somewhere else.
function groupBy(list, keyGetter) {
  const map = new Map()
  list.forEach(item => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

const normalizeAction = action => {
  const output = {
    ...action,
    categories: action.categories || [{ id: "default" }],
  }
  const groupedItems = groupBy(
    action.fields,
    field => field.category || "default"
  )
  output.categories = output.categories.map(category => ({
    ...category,
    fields: groupedItems.get(category.id) || [],
  }))
  return output
}

const shouldDisplay = (classes = [], state, category) => {
  const conditions = getDisplayRules(classes)
  if (!conditions.length) {
    return true
  }

  const arrayMethod = isStrictMode(classes)
    ? Array.prototype.every
    : Array.prototype.some
  return arrayMethod.call(
    conditions,
    condition =>
      (typeof condition === "object" &&
        (state[condition.criteria] === condition.value ||
          state[`${condition.criteria}-${category}`] === condition.value)) ||
      (typeof condition === "string" &&
        (state[condition] || state[`${condition}-${category}`]))
  )
}

const HIDDEN_CLASS = "d-none"
const SETUP_INTENT_FIELD = "setupIntent"
const PAYMENT_METHOD_TYPE_FIELD = "paymentMethodType"

class FormRenderer extends PureComponent {
  static propTypes = {
    action: ActionType,
    links: LinksType,
    onSubmit: PropTypes.func,
    problem: PropTypes.object,
    submitting: PropTypes.bool,
    successAction: PropTypes.object,
    onChange: PropTypes.func,
    decorateField: PropTypes.func,
    className: PropTypes.string,
    stripe: PropTypes.object,
  }

  static defaultProps = {
    decorateField: (name, { fieldComponent }) => fieldComponent,
  }

  state = {
    validationErrors: {},
  }

  componentDidMount() {
    const { action } = this.props
    action && this.setInitialState(action.fields)
    this.errorRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.problem !== this.props.problem) {
      this.scrollToError()
    }
    if (prevProps.action !== this.props.action && this.props.action) {
      const paymentMethodTypeField = findByName(this.props.action.fields, PAYMENT_METHOD_TYPE_FIELD)
      if (paymentMethodTypeField) {
        const { name, value, category } = paymentMethodTypeField
        const key = getDiscriminatorKey(paymentMethodTypeField.class, name, category)
        if (name === "paymentMethodType" && Array.isArray(value)) {
          const selected = value.find(option => option.selected) || value[0]
          selected && this.setState({ [key]: selected.value })
        }
      }
    }
     
  }

  scrollToError = () => {
    setTimeout(() => {
      this.errorRef.current &&
        window.scrollTo({
          top:
            window.scrollY +
            (this.errorRef.current.getBoundingClientRect().top -
            100 /*header height*/ -
              75) /*input height*/,
          behavior: "smooth",
        })
    })
  }

  resolveAction() {
    let { action, links, problem = {}, submitting } = this.props
    const { validationErrors } = this.state
    let isErrorRefAssigned = false
    const { invalidParams = {} } = problem
    action = normalizeAction(action)
    const main = []
    const aside = []
    action.categories.forEach(category => {
      var paymentMethodCategoryClass = ""
      if (category.id == "paymentMethod" && (getEnvTitle().indexOf("Ghent") !== -1 || getEnvTitle().indexOf("Mobiliteitsbedrijf") !== -1)) {
        const paymentFields = category.fields.filter(field => field.category == "paymentMethod")
        if (paymentFields && (paymentFields.length == 1 && paymentFields[0].type === "hidden")) {
          paymentMethodCategoryClass = "hidden"
        }
        if (paymentFields && (paymentFields[0].type !== "hidden" && paymentFields[0].name === "paymentMethodType")) {
          if (Array.isArray(paymentFields[0].value) && paymentFields[0].value.filter(val => (val.hidden == true && val.value == "invoice")).length > 0) {
            paymentMethodCategoryClass = "hidden"
          } else { paymentMethodCategoryClass = ""}
        }
      }
      const target = isAside(category.class) ? aside : main
      target.push(
        <Card
          key={category.id}
          title={category.title}
          subTitle={category.description}
          className={`p-4 ${category.id} ${paymentMethodCategoryClass}`}
        >
          <Row>
            {category.fields.map((field, index) => {
              const uniqueId = `${category.id}${index}`
              // if the form is inline, then all the fields are too
              const colSize = isInline(action.class)
                ? null
                : getColSize(field.class)
              const visible = shouldDisplay(
                field.class,
                this.state,
                field.category
              )
              if (field.name == "privacyPolicy" && (getEnvTitle().indexOf("Ghent") !== -1 || getEnvTitle().indexOf("Mobiliteitsbedrijf") !== -1)) {
                field.value.selected = true
              }
              
              const required = visible && (isRequired(field.class) || (field.name == "phoneNumber" && getEnvTitle().indexOf("Boston") !== -1))
              const showTooltip = visible && hasTooltip(field.class)
              const errorMessage =
                validationErrors[uniqueId] || invalidParams[field.name]
              const error =
                field.type === "submit" ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {problem.title}
                  </div>
                ) : errorMessage ? (
                  <FormFeedback>
                    <div ref={!isErrorRefAssigned ? this.errorRef : undefined}>
                      {errorMessage}
                    </div>
                  </FormFeedback>
                ) : null
              if (error) {
                // To ensure proper scrolling, ref should only assigned for the first error
                isErrorRefAssigned = true
              }
              const className = `${field.className || ""} ${
                !visible || field.type === "hidden" ? HIDDEN_CLASS : ""
              }`
              // This is handed in as `innerRef` to get hold of the created
              // HTML element. We later can use that in our onChange handler
              // to access the field saved in the HTML elements _field property.
              const attachField = element => {
                if (element) {
                  element._field = field
                  element.uniqueId = uniqueId
                }
              }
              if (links && getEnvTitle().indexOf("Boston") !== -1) {
                links.filter(link => {
                  if (link.rel.indexOf("signup-terms") > -1 || link.rel.indexOf("create-booking-request-terms") > -1) {
                    link.href = "http://www.massport.com/logan-airport/to-from-logan/parking/FPP-info/EVOPark-TC"
                    link.rel.indexOf("external") == -1 && link.rel.push("external")
                  }
                })
              }
              if (links && (getEnvTitle().indexOf("Ghent") !== -1 || getEnvTitle().indexOf("Mobiliteitsbedrijf") !== -1)) {
                links.filter(link => {
                  if (link.rel.indexOf("signup-terms") > -1 || link.rel.indexOf("create-booking-request-terms") > -1) {
                    link.href = "https://stad.gent/nl/reglementen?search=parkeergarage"
                    link.rel.indexOf("external") == -1 && link.rel.push("external")
                  }
                  if (link.rel.indexOf("signup-privacyPolicy") > -1) {
                    link.href = "https://stad.gent/nl/over-gent-en-het-stadsbestuur/juridische-info/met-respect-voor-je-privacy"
                    link.rel.indexOf("external") == -1 && link.rel.push("external")
                  }
                })
              }
              let {
                fieldTitle,
                unmatchedLinks,
              } = getFieldTitleAndUnmatchedLinks({
                links,
                actionName: action.name,
                field,
              })
              fieldTitle = (
                <React.Fragment>
                  <span>
                    {fieldTitle}{" "}
                    <RequiredTooltip required={fieldTitle && required} /> {" "}
                    {showTooltip && <InfoTooltip tooltipText={getFrontendTranslations() ? getFrontendTranslations()[`${action.name}`][`${field.name}`] : translate(`${action.name}.${field.name}`)}/>}
                    {(getEnvTitle().indexOf("Boston") !== -1 && field.name == "terms") && <a target="_blank" rel="noopener noreferrer" href="http://www.massport.com/logan-airport/to-from-logan/parking/FPP-info/EVOPark-FAQ">FAQs</a>}
                  </span>
                  {field.description && <h6 className="text-muted">{field.description}</h6>}
                </React.Fragment>
              )
              const Field = formRegistry.getComponentForType(field.type)

              if (!Field) {
                return null
              }

              if (field.type === "submit") {
                field.class = submitting
                  ? addClass(field.class, "loading")
                  : removeClass(field.class, "loading")
              }

              const key = `${field.name}-${field.title || field.value}`
              let fieldComponent = (
                <Field
                  name={field.name}
                  innerRef={attachField}
                  title={fieldTitle}
                  value={field.value}
                  state={this.state}
                  type={field.type}
                  classes={field.class}
                  min={field.min}
                  max={field.max}
                  step={field.step}
                  className={className}
                  required={required}
                  error={error}
                  onChange={this.onChange}
                  onClick={this.onClick}
                  placeholder={field.placeholder}
                  category={field.category}
                  onBlur={event => {
                    this.validateField(
                      field.validation,
                      event.target.value,
                      uniqueId
                    )
                  }}
                />
              )

              if (unmatchedLinks.length) {
                fieldComponent = (
                  <React.Fragment>
                    {fieldComponent}
                    {unmatchedLinks.map(link => (
                      <div key={link.href}>
                        <Link link={link}>{link.title}</Link>
                      </div>
                    ))}
                  </React.Fragment>
                )
              }

              return (
                <Col key={key} md={colSize} className={className}>
                  {this.props.decorateField(field.name, {
                    fieldComponent,
                    field,
                  }) || null}
                </Col>
              )
            })}
          </Row>
        </Card>
      )
    })

    return aside.length ? (
      <React.Fragment>
        { main.length > 0 && <div className="signup-main p-2">{main}</div> }
        <div className="signup-aside p-2">{aside}</div>
      </React.Fragment>
    ) : (
      main
    )
  }

  validateField = (config = {}, value, key) => {
    let isValid = true
    if (config.format) {
      const regex = new RegExp(`^${config.format}$`)
      isValid = regex.test(value)
      this.setState(state => ({
        validationErrors: {
          ...state.validationErrors,
          [key]: isValid ? "" : config.message,
        },
      }))
    }
    return isValid
  }

  setInitialState = fields => {
    fields &&
      fields.forEach(field => {
        const { name, value, type, category } = field
        if (isDiscriminator(field.class)) {
          const key = getDiscriminatorKey(field.class, name, category)
          switch (type) {
            case "select":
            case "radio": {
              if (field.name === "customerType" && getItem("type") !== null) {
                this.setState({ [key]: getItem("type") })
              }
              else {
                const selected = value.find(option => option.selected) || value[0]
                selected && this.setState({ [key]: selected.value })
              }
              break
            }
            case "checkbox": {
              const checked = typeof value === "object" ? value.selected : value
              this.setState({ [key]: !!checked })
              break
            }
            default:
              this.setState({ [key]: value || false })
          }
        }
      })
  }

  onChange = event => {
    const { name, value, type, checked, _field: field } = event.target
    if (field && isDiscriminator(field.class)) {
      const key = getDiscriminatorKey(field.class, name, field.category)
      if (type === "checkbox") {
        this.setState({ [key]: checked })
      } else {
        this.setState(previousState => ({
          [key]: value || !previousState[key],
        }))
      }
    }
    this.props.onChange && this.props.onChange(event, field)
  }

  onClick = event => {
    event.target.type === "submit" && event.target.setAttribute("clicked", true)
  }

  validateForm = elements => {
    let isFormValid = true
    for (let i = 0; i < elements.length; ++i) {
      const element = elements[i]
      if (
        element.className.indexOf(HIDDEN_CLASS) === -1 &&
        element._field &&
        element._field.validation
      ) {
        const isValid = this.validateField(
          element._field.validation,
          element.value,
          element.uniqueId
        )
        !isValid && (isFormValid = false)
      }
    }
    return isFormValid
  }

  contructPayload = (elements, adder) => {
    for (let i = 0; i < elements.length; ++i) {
      const element = elements[i]
      const name = element.name
      if (name && element.className.indexOf(HIDDEN_CLASS) === -1 && (element.type === "text" || element.type === "textarea" || element.value !== "")) {
        switch (element.type) {
          case "radio": {
            element.checked && adder(name, element.value)
            break
          }
          case "checkbox": {
            adder(name, element.checked ? element.value : "false") // unchecked checkbox will send "false"
            break
          }
          case "tel": {
            if (element.value && element.value.indexOf("00") === 0) {
              element.value = `+${element.value.substr(2)}`
            }
            adder(name, element.value)
            break
          }
          case "file": {
            element.files.length > 0 && adder(name, element.files[0])
            break
          }
          case "select-multiple": {
            const selectedOptions = Array.prototype.reduce.call(
              element.options,
              (result, option) => {
                option.selected && result.push(option.value)
                return result
              },
              []
            )
            adder(name, selectedOptions)
            break
          }
          case "textarea": {
            if (element.className.indexOf(SPLIT_NEW_LINE) !== -1) {
              const value = element.value ? element.value.split(/\n/) : []
              adder(name, value)
            } else {
              adder(name, element.value)
            }
            break
          }
          case "submit": {
            element.getAttribute("clicked") && adder(name, element.value)
            element.removeAttribute("clicked")
            break
          }
          default: {
            adder(name, element.value)
          }
        }
      }
    }
  }

  isDownloadAction = () => {
    const actionClasses = this.props.action.class
    return actionClasses.indexOf(DOWNLOAD_ACTION_CLASS) > -1
  }

  isStripeBasedFormSubmission = (data) => {
    const { stripe } = this.props
    const setupIntent = document.getElementsByName(SETUP_INTENT_FIELD)
    // with furture psps we will need to remove the card check and provide some more meta
    // information to the frontend to figure out the psp
    return (stripe && !!(setupIntent.length) && !!(data[PAYMENT_METHOD_TYPE_FIELD]) && (data[PAYMENT_METHOD_TYPE_FIELD] === "card"))
  }

  onSubmit = event => {
    event.preventDefault()
    // Validate form before proceeding with submission logic
    const isValid = this.validateForm(event.target.elements)
    if (!isValid) {
      this.scrollToError()
      return
    }


    const {
      action: { href, method, type },
      onSubmit,
      stripe,
    } = this.props
    let data, adder

    if (type === "multipart/form-data") {
      data = new FormData()
      adder = (key, value) =>
        Array.isArray(value) && value.length
          ? value.forEach(v => data.append(`${key}[]`, v)) // https://stackoverflow.com/a/28434829/1410020
          : data.append(key, value)
    } else {
      data = {}
      adder = (key, value) => {
        const matched = key.match(/(\w+)\[\](?:\[(\w+)\])?$/)
        if (matched && matched[2]) {
          key = matched[1]
          const nestedKey = matched[2]
          if (data[key]) {
            const lastItem = data[key][data[key].length - 1]
            lastItem[nestedKey]
              ? data[key].push({ [nestedKey]: value })
              : (lastItem[nestedKey] = value)
          } else {
            data[key] = [{ [nestedKey]: value }]
          }
        } else if (matched) {
          key = matched[1]
          data[key]
            ? data[key].push(value)
            : (data[key] = Array.isArray(value) ? value : [value])
        } else {
          data[key] = value
        }
      }
    }

    this.contructPayload(event.target.elements, adder)

    if (this.isStripeBasedFormSubmission(data)) {
      const ccHolder = document.getElementsByName("ccHolder").length && document.getElementsByName("ccHolder")[0].value
      const clientSecret = document.getElementsByName(SETUP_INTENT_FIELD)[0].value
      stripe.handleCardSetup(clientSecret, {
        payment_method_data: {
          billing_details: {
            name: ccHolder,
          },
        },
      }).then(({ setupIntent, error }) => {
        if (setupIntent) {
          data["stripeIntentId"] = setupIntent.id
          onSubmit && onSubmit({
            href,
            method,
            data,
            headers: {
              "Content-Type": type,
            },
            downloadFile: this.isDownloadAction(),
          })
        }
        else {
          this.setState(state => ({
            validationErrors: {
              ...state.validationErrors,
              [document.getElementsByName(SETUP_INTENT_FIELD)[0].uniqueId]: error.message,
            },
          }))
          this.scrollToError()
        }
      })
    } else {
      onSubmit && onSubmit({
        href,
        method,
        data,
        headers: {
          "Content-Type": type,
        },
        downloadFile: this.isDownloadAction(),
      })
    }
  }

  render() {
    const { action } = this.props
    if (!action) {
      return null
    }
    const content = this.resolveAction()
    const className = action.class
      ? `${action.class.join(" ")} ${this.props.className || ""}`
      : this.props.className
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Form
          onSubmit={this.onSubmit}
          className={formatFormClassName(className)}
        >
          {content}
        </Form>
      </Suspense>
    )
  }
}
export default withFormContext(withFormSubmission(FormRenderer))
