import React from "react"
import PropTypes from "prop-types"

import SirenEntityType from "api/SirenEntityType"
import { findByRelation } from "api/utils"
import Link from "components/Link"

const EntityLink = ({ entity, rel, children, ...props }) => {
  const link = findByRelation(entity.links, rel)

  return link && (
    <Link link={link} {...props}>
      {
        typeof children === "function"
          ? children({ ...link })
          : children && children || link.title
      }
    </Link>
  ) || null

}
EntityLink.propTypes = {
  entity: SirenEntityType.isRequired,
  rel: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
}
export default EntityLink
