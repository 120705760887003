import React from "react"
import PropTypes from "prop-types"

const PropertiesLabelValue = ({ label, value }) => (
  <div className="d-flex flex-row">
    {label && <strong className="property-label">{label}</strong>}
    <div className="property-value">{value}</div>
    <style jsx>{`
      .property-label, .property-value {
        flex: 1 0 50%;
        word-break: break-word;
        white-space: pre-line;
      }
    `}</style>
  </div>
)

PropertiesLabelValue.propTypes = {
  label: PropTypes.node,
  value: PropTypes.node,
}

export default PropertiesLabelValue
