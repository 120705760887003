import { NotificationManager as ReactNotificationManager } from "react-notifications"

import { getActionEntityStatus } from "components/FormRenderer/actionEntity"
import history from "appHistory"
import encodeURL from "urlHelpers/encodeURL"
import { findByRelation } from "api/utils"
import { TIMEOUT_NOTIFICATION, TIMEOUT_NOTIFICATION_WITH_LINK } from "config"

const METHODS = ["info", "success", "warning", "error"]
const NotificationManager = { ...ReactNotificationManager }
METHODS.forEach(method => {
  NotificationManager[method] = ({ entity, message, title, timeout = TIMEOUT_NOTIFICATION, onClick, priority }) => {
    const status = getActionEntityStatus(entity)
    const notificationLink = status && findByRelation(status.links, "notification-url")
    if (typeof timeout === "undefined" && notificationLink) {
      timeout = TIMEOUT_NOTIFICATION_WITH_LINK
    }

    return ReactNotificationManager[method](message, title, timeout, (event) => {
      typeof onClick === "function" && onClick(event)
      notificationLink && history.push(encodeURL(notificationLink.href))
    }, priority)
  }
})


export default NotificationManager
