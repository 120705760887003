import React, { Component } from "react"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import { isDisabled } from "components/FormRenderer/form"
import { Input as InputStrap } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"

class IEDatePicker extends Component {

  state = {
    value: this.props.value || this.props.defaultValue,
  }

  handleChange = (value) => {
    this.setState(() => ({ value }), () => {
      const event = new Event("change", { bubbles: true })
      this.nativeSelect && this.nativeSelect.dispatchEvent(event)
    })
  }

  innerRef = (element) => {
    this.nativeSelect = element
    typeof this.props.innerRef === "function" && this.props.innerRef(element)
  }

  render() {
    const { name, classes, required, error, className, min, max, onChange } = this.props

    return (
      <React.Fragment className={name}>
        <DatePicker
          required={required}
          minDate={min ? new Date(min) : new Date("1980-01-01")}
          maxDate={max ? new Date(max) : ""}
          onChange={this.handleChange}
          className="form-control custom-class"
          disabled={isDisabled(classes)}
          selected={this.state.value ? new Date(this.state.value) : ""}
        />

        <div className="hidden-date-input">
          <InputStrap
            innerRef={this.innerRef}
            type="text"
            name={name}
            value={this.state.value}
            onChange={onChange}
            className={`${className} hidden-date-input ${error ? "form-control is-invalid" : ""}`}
            readOnly
          />
        </div>
        {error}
        <style jsx>{`
        .hidden-date-input {
          display: none;
        }
      `}</style>
        <style jsx global>{`
        .custom-class {
          border: 1px solid #c2cfd6 !important;
        }
        .react-datepicker-wrapper {
          display: block !important;
        }
      `}</style>
      </React.Fragment>
    )
  }
}

IEDatePicker.propTypes = {
  name: PropTypes.string,
  classes: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
  error: PropTypes.node,
  className: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  title: PropTypes.node,
  innerRef: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default IEDatePicker
