import React, { Component } from "react"

import { Input as InputStrap, FormGroup, Label } from "reactstrap"
import PureInput from "components/FormRenderer/components/PureInput"
const IEDatePicker = React.lazy(() => import("components/FormRenderer/components/IEDatePicker"))
import MultiDatePicker from "components/FormRenderer/components/MultiDatePicker"

class Input extends Component {
  static requireIEDateComponent = undefined

  state = {
    requireIEDateComponent: this.props.type === "date" && Input.requireIEDateComponent,
  }

  getDateInputRef = input => {
    this.dateInput = input
    this.props.innerRef && this.props.innerRef(input)
  }

  componentDidMount() {
    if (this.dateInput && this.dateInput.type !== "date") {
      this.setState({
        requireIEDateComponent: Input.requireIEDateComponent = true,
      })
    }
  }

  render() {
    return (
      <FormGroup className={this.props.name}>
        <Label>{this.props.title}
          <PureInput
            {...this.props}
            innerRef={this.props.type === "date" ? this.getDateInputRef : this.props.innerRef}
            component={this._getComponent()}/>
          {this.props.error}
        </Label>
      </FormGroup>
    )
  }

  _getComponent() {
    if (this.state.requireIEDateComponent)
    {
      return IEDatePicker
    }
    else if (this.props.classes.includes("js-multiple-datepicker")) {
      return MultiDatePicker
    }
    else {
      return InputStrap
    }
  }
}
Input.propTypes = PureInput.propTypes

export default Input
