import React from "react"
import { Row } from "reactstrap"

import Link from "components/Link"
import SirenEntityType from "api/SirenEntityType"
import { findByRelation } from "api/utils"
import AccountForm from "components/pages/public/Account/AccountForm"

const Login = ({ entity }) => {
  const resetPasswordLink = findByRelation(entity.links, "resetPassword")

  return <AccountForm actions={entity.actions} name="login">
    {resetPasswordLink && <Row className="justify-content-center px-4">
      <Link link={resetPasswordLink}/>
    </Row>}
  </AccountForm>
}

Login.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default Login
