import { parse, stringify } from "flatted/esm"

const storage = window.localStorage || {
  getItem() {},
  setItem() {},
  removeItem() {},
}

export const getItem = (key) => {
  try {
    const serializedItem = storage.getItem(key)
    if (serializedItem === null) {
      return undefined
    }

    return parse(serializedItem)
  } catch (err) {
    removeItem(key)

    return undefined
  }
}

export const setItem = (key, value) => {
  try {
    const serializedItem = stringify(value)
    storage.setItem(key, serializedItem)
  } catch (err) { return }
}

export const removeItem = (key) => {
  try {
    storage.removeItem(key)
  } catch (err) { return }
}
