import React from "react"

import EntityType from "api/SirenEntityType"
import Card from "components/Card"
import PropertiesBox from "components/PropertiesBox"
import { findByClass } from "api/utils"

import Image from "components/Image"
import ParkingFacilityFeatures from "components/pages/admin/ParkingFacility/ParkingFacilityFeatures"
import withEntity from "components/withEntity"
import { PostalAddressClass, PermissionCapacityClass, ParkingProductListClass } from "components/registry"
import TableRenderer from "components/TableRenderer/TableRenderer"

const ParkingFacility = ({ entity }) => {
  const postalAddressEntity = findByClass(entity.entities, PostalAddressClass)
  const permissionCapacityEntity = findByClass(entity.entities, PermissionCapacityClass)
  const productOfferList = findByClass(entity.entities, ParkingProductListClass)
  return (
    <Card header={entity.title}>
      {entity.properties.image && <Image title={`${entity.title} image`} src={entity.properties.image} width="300px" className="mb-4"/>}
      <PropertiesBox
        entity={entity}
        keys="email,phone,publicAccess,openingHours,timezone,maximumAttendeeCapacity,headroom+headroomUnit"
        label/>
      <ParkingFacilityFeatures entity={entity}/>
      <PropertiesBox
        entity={postalAddressEntity}
        keys="streetAddress,addressLocality,addressRegion,postalCode,addressCountry"
        label/>
      <PropertiesBox
        entity={permissionCapacityEntity}
        keys="activeBookingsCount,capacity"
        label/>
      <TableRenderer
        entity={productOfferList}
        className="product-offers"
        selectRow={false}
        search={false}
        pagination={false}
        isSecondaryTable={true}
        exportCSV={false}/>
      <style jsx global>{`
        .product-offers {
          margin: 0px !important
        }
      `}</style>
    </Card>
  )
}

ParkingFacility.propTypes = {
  entity: EntityType.isRequired,
}

export default withEntity(ParkingFacility, {
  entities: ["permissionCapacity"],
})
