import React from "react"
import PropTypes from "prop-types"
import { Input } from "reactstrap"

const HiddenInput = ({ type, name, value, innerRef, invalid }) => <Input type={type} name={name} value={value} innerRef={innerRef} invalid={invalid} />
HiddenInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  innerRef: PropTypes.func,
  invalid: PropTypes.bool,
}

export default HiddenInput
