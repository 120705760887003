import React from "react"
import PropTypes from "prop-types"
import { Label } from "reactstrap"
import { BlockPicker } from "react-color"

class Color extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    title: PropTypes.node,
    value: PropTypes.string,
    className: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.node,
    innerRef: PropTypes.func,
  }

  state = {
    displayColorPicker: false,
    color: this.props.value,
  }

  handleClick = () => this.setState((state) => ({ displayColorPicker: !state.displayColorPicker }))
  handleClose = () => this.setState(() => ({ displayColorPicker: false }))
  handleChangeComplete = (color) => this.setState({ color: color.hex })

  render() {
    const { name, title, className, error, innerRef } = this.props
    return (
      <div>
        <Label className={className}>
          {title}
        </Label>
        <div className="color-swatch-container" onClick={this.handleClick}>
          <div className="color-swatch"/>
        </div>
        {this.state.displayColorPicker
        && (
          <div className="color-popover">
            <div className="color-cover" onClick={this.handleClose}/>
            <BlockPicker
              color={this.state.color}
              onChangeComplete={this.handleChangeComplete}/>
          </div>
        )}
        <input
          type="color"
          className="input-color"
          name={name}
          value={this.state.color}
          ref={innerRef}/>
        {error}
        <style jsx>{`
          .color-swatch-container {
            padding: 5px;
            background: white;
            box-shadow: 0 0 0 1px rgba(0, 0, 0 , 0.1);
            cursor: pointer;
            width: 85px;
          }
          .color-swatch {
            height: 36px;
          }
          .color-popover {
            position: absolute;
            z-index: 2;
            left: -28px;
            top: 80px;
            box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
          }
          .color-cover {
            position: fixed;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
          }
          .input-color {
            display: none;
          }
        `}</style>
        <style jsx>{`
          .color-swatch {
              background: ${this.state.color};
            }
        `}</style>
      </div>
    )
  }
}

export default Color
