import React from "react"
import PropTypes from "prop-types"

import EntityType from "api/SirenEntityType"
import { getProperty } from "components/TableRenderer/adapters/SubentityPropertyAdapter"
import Email from "components/Email"

// Email adapter can support properties like:
// email
// customer.email
// customer.email+customer.name
// email+name

const EMailAdapter = ({ entity, property }) => {
  const [emailProperty, nameProperty] = property.split("+")
  const email = getProperty(entity, emailProperty)
  const name = nameProperty ? getProperty(entity, nameProperty) : undefined

  return <Email email={email} text={name}/>
}

// We want the sort to be by name as it's what is displayed
EMailAdapter.value = (entity, property) => {
  const [emailProperty, nameProperty] = property.split("+")
  return `${nameProperty ? getProperty(entity, nameProperty) : ""}|${getProperty(entity, emailProperty)}`
}
// trick the csv export into thinking it's 2 columns
EMailAdapter.csvHeader = "name\",\"email"
EMailAdapter.csvValue = (entity, property) => {
  const [name, email] = entity.properties[property].split("|")
  return `${name || ""}","${email}`
}

EMailAdapter.propTypes = {
  entity: EntityType,
  property: PropTypes.string,
}

export default EMailAdapter
