import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from "reactstrap"

import EntityType from "api/SirenEntityType"
import { filterByClass } from "api/actions"
import FontAwesomeIcon from "components/FontAwesome/FontAwesomeIcon"
import LocaleSelect from "../../LocaleSelect"

class HeaderDropdown extends Component {
  static propTypes = {
    entity: EntityType,
    className: PropTypes.string,
  }

  state = { dropdownOpen: false }
  toggle = () => this.setState(() => ({ dropdownOpen: !this.state.dropdownOpen }))

  render() {
    const { properties, actions } = this.props.entity
    const localeActions = filterByClass(actions, "locale")

    function icon(props) {
      if (props.locale) {
        return <span className={`fi fi-${props.locale.toLowerCase().split("-")[1]}`}></span>
      } else { // fallback to old backend
        <FontAwesomeIcon title={props.icon} iconURI={props.icon} fontSize="23px"/>
      }
    }

    return <Dropdown
      nav tag="div" isOpen={this.state.dropdownOpen} toggle={this.toggle}
      className={this.props.className}>
      <DropdownToggle nav>
        {icon(properties)}
      </DropdownToggle>
      <DropdownMenu right>
        <LocaleSelect actions={localeActions}/>
      </DropdownMenu>
    </Dropdown>
  }
}

export default HeaderDropdown
