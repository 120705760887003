import React from "react"
import PropTypes from "prop-types"

import { Table as ReactStrapTable } from "reactstrap"

const Table = ({ children }) => <ReactStrapTable>{children}</ReactStrapTable>
Table.propTypes = {
  children: PropTypes.node,
}

export default Table
