import React from "react"
import SirenEntityType from "api/SirenEntityType"
import Card from "components/Card"
import PropertiesLabelValue from "components/PropertiesLabelValue"
import getTranslations from "components/i18n/getTranslations"

const BillingSegment = ({ entity = {} }) => {
  const entries = Object.entries(entity.properties)
  return (
    <Card header={entity.title}>
      { entries.map(([key, value ]) => (
        <PropertiesLabelValue
          key={key}
          label={getTranslations(entity)[key] || key}
          value={value}/>
      ))}

    </Card>
  )
}

BillingSegment.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default BillingSegment
