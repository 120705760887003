import React from "react"
import PropTypes from "prop-types"

import NotificationManager from "components/NotificationManager"
import api, { ERROR_VALIDATION } from "api/api"
import { isActionEntity, isActionSuccess, actionTitle } from "components/FormRenderer/actionEntity"
import { getFrontendTranslations } from "components/Layout/layoutManager"
import { SummaryClass } from "components/registry"
import translate from "components/i18n/translate"

export default (Component) => {
  class withFormSubmission extends React.Component {
    static propTypes = {
      context: PropTypes.object,
    }

    state = {
      problem: undefined,
      summary: undefined,
    }

    onSubmit = (config) => {
      this.setState({ problem: undefined, submitting: true })
      const { onEntityResolve, onActionResolve } = this.props.context
      return api(config).then(({ entity, headers, fileDownloaded }) => {
        this.setState({ submitting: false })
        if (fileDownloaded) { return }
        if (entity.class.indexOf(SummaryClass) !== -1) { this.setState({ summary: entity }); return }
        if (isActionEntity(entity)) {
          this.setState({ successAction: entity })
          onActionResolve && onActionResolve(entity)
          NotificationManager[isActionSuccess(entity) ? "success" : "warning"]({ entity, message: actionTitle(entity) })
        } else {
          let url
          if (headers && headers.location) {
            url = headers.location
          } else {
            url = entity.selfLink
          }
          onEntityResolve && onEntityResolve({ entity, url })
        }
      }).catch(problem => {
        if (problem instanceof Error) {
          problem = {
            title: problem.message,
            message: problem.message,
          }
        } else if (problem.type === ERROR_VALIDATION) {
          problem.message = problem.message || (getFrontendTranslations() ? getFrontendTranslations().error.formValidation : translate("error.formValidation"))
        } else if (!problem.title && problem.status >= 400 && problem.status <= 500) {
          problem.message = getFrontendTranslations() ? getFrontendTranslations().error[`${problem.status}`] : translate(`error.${problem.status}`)
        }
        NotificationManager.error({ message: problem.message || problem.title })
        this.setState({ problem, submitting: false })
      })
    }
    render() {
      return <Component
        onSubmit={this.onSubmit}
        {...this.props}
        problem={this.state.problem}
        submitting={this.state.submitting}
        successAction={this.state.successAction}
        summary={this.state.summary}/>
    }
  }

  return withFormSubmission
}
