import React from "react"
import PropTypes from "prop-types"

import SirenEntityType, { ActionType } from "api/SirenEntityType"

import Card from "components/Card"
import PropertiesBox from "components/PropertiesBox"
import ActionToggle from "components/ActionToggle"

const EntityRenderer = ({ entity, properties, action }) => (entity || action)
  ? <Card>
    <PropertiesBox entity={entity} keys={properties} label/>
    <ActionToggle action={action}/>
  </Card>
  : null

EntityRenderer.propTypes = {
  entity: SirenEntityType,
  action: ActionType,
  properties: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
}

export default EntityRenderer
