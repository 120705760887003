import React from "react"
import PropTypes from "prop-types"
import { Navbar } from "reactstrap"

import EntityType from "api/SirenEntityType"
import TopNavContent from "components/Layout/TopNavContent"

const TopNav = ({ entity, currentUrl, className = "" }) => (
  <Navbar color="secondary" dark className={`top-nav w-100 ${className}`}>
    <div className="container">
      <TopNavContent links={entity.links} currentUrl={currentUrl}/>
    </div>
    <style jsx global>{`
      .top-nav {
        height: 55px;
      }
      @media (min-width: 576px) {
        .app-body {
          padding-top: 55px;
        }
      }
    `}
    </style>
  </Navbar>
)
TopNav.propTypes = {
  entity: EntityType.isRequired,
  currentUrl: PropTypes.string,
  className: PropTypes.string,
}

export default TopNav
