import React from "react"
import SirenEntityType from "api/SirenEntityType"
import withEntity from "components/withEntity"
import { findByClass } from "api/utils"
import PropertiesBox from "components/PropertiesBox"
import { SepaMandateClass } from "components/registry"

const PaymentMethod = ({ entity = {} }) => {
  const paymentMethodEntity = findByClass(entity.entities, SepaMandateClass)
  if (!paymentMethodEntity) { return null }
  return (
    <PropertiesBox
      entity={paymentMethodEntity}
      keys="accountHolder,iban,bic,mandateIdentification"
      label/>
  )
}

PaymentMethod.propTypes = {
  entity: SirenEntityType,
}

export default withEntity(PaymentMethod, {
  entities: ["paymentMethod"],
})
