import React from "react"
import PropTypes from "prop-types"
import { Container, Alert } from "reactstrap"
import SirenEntityType from "api/SirenEntityType"
import TableRenderer from "components/TableRenderer/TableRenderer"
import { filterByClassStrict } from "api/utils"
import { TextBlockClass } from "components/registry"
import TextBlock from "components/pages/public/LandingPage/TextBlock"

const CustomerDashboardList = ({ entity, layoutName, currentUrl, prevUrl }) => {
  const textBlock = filterByClassStrict(entity.entities, TextBlockClass)
  return (
    <React.Fragment>
      {textBlock.length > 0 && textBlock.map(eachTextBlock => (
        <Container key={eachTextBlock.title}>
          <Alert color={eachTextBlock.properties.message_type} className={`${layoutName == "customerSelfService" ? "container" : ""}`}>
            <TextBlock entity={eachTextBlock}/>
          </Alert>
        </Container>
      ))
      }
      <TableRenderer entity={entity} layoutName={layoutName} currentUrl={currentUrl} prevUrl={prevUrl}/>
    </React.Fragment>
  )
}


CustomerDashboardList.propTypes = {
  entity: SirenEntityType.isRequired,
  layoutName: PropTypes.string,
  currentUrl: PropTypes.string,
  prevUrl: PropTypes.string,
}

export default CustomerDashboardList
