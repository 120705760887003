import React, { Component, Fragment } from "react"

import SirenEntityType from "api/SirenEntityType"
import { getActionEntityItems } from "components/FormRenderer/actionEntity"
import { findByRelationStrict } from "api/utils"

import FormPage from "components/FormRenderer/FormPage"
import Link from "components/Link"
import FontAwesomeIcon from "components/FontAwesome/FontAwesomeIcon"
import { setItem } from "storage"
import Card from "components/Card"
import { getFrontendTranslations } from "components/Layout/layoutManager"
import translate from "components/i18n/translate"

const ACTION_NAME = "edit"
const LOCALE_FIELD_NAME = "operator_locale"
const COLOR_NAME = "color"
const COLOR_SCHEME = "colorScheme"

const getSelectedLocale = (actions) => {
  const action = actions.find(a => a.name === ACTION_NAME)
  if (action) {
    const localeField = action.fields.find(field => field.name === LOCALE_FIELD_NAME)
    if (localeField) {
      const localeSelected = localeField.value.find(v => v.selected)
      return localeSelected ? localeSelected.value : undefined
    }
  }

  return undefined
}

const getColorScheme = (actions) => {
  const action = actions.find(a => a.name === ACTION_NAME)
  const colorField = action.fields.find(field => field.name === COLOR_NAME)
  return colorField.value
}

class Organization extends Component {
  onActionResolve = entity => {
    const entities = getActionEntityItems(entity)
    const oldLocale = getSelectedLocale(this.props.entity.actions)
    const newLocale = getSelectedLocale(entities[0].actions) // seems a bit weak.. :/
    const oldColorScheme = getColorScheme(this.props.entity.actions)
    const newColorScheme = getColorScheme(entities[0].actions)
    oldColorScheme !== newColorScheme && setItem(COLOR_SCHEME, newColorScheme)
    oldLocale !== newLocale && document.location.reload() // if the locale changed, we refresh as we need all new translations
  }

  render() {
    const { entity } = this.props
    const stripeConnectUrl = findByRelationStrict(entity.links, ["external", "stripe"])

    return <Fragment>
      <FormPage entity={entity} onActionResolve={this.onActionResolve}/>
      {stripeConnectUrl && (
        <Card title={getFrontendTranslations() ? getFrontendTranslations().stripe.stripeTitle : translate("stripe.title")} subTitle={getFrontendTranslations() ? getFrontendTranslations().stripe.stripeDesc : translate("stripe.desc")} className="p-4 m-4">
          <span className="d-inline-block">
            <Link download link={stripeConnectUrl} className="btn btn-primary mt-4 float-left d-inline-block">
              <FontAwesomeIcon title={stripeConnectUrl.title} iconURI={stripeConnectUrl.icon} className="mr-2"/>{stripeConnectUrl.title}
            </Link>
          </span>
        </Card>
      )}
    </Fragment>
  }
}

Organization.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default Organization
