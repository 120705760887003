import React from "react"
import PropTypes from "prop-types"

import EntityType from "api/SirenEntityType"

const TextBlock = ({ entity, className = "" }) =>
  <section className={`section-text-block container my-4 ${className}`}>
    {entity.properties.html && <article className="html ql-editor" dangerouslySetInnerHTML={{ __html: entity.properties.html }}/>}
    {entity.properties.text && <article className="text text-left">{entity.properties.text}</article>}
    <style jsx>{`
    h2 {
      white-space: pre-line;
    }
    .section-text-block :global(img) {
      max-width: 100%;
    }
    .section-text-block :global(blockquote) {
      border-left: 4px solid #ccc;
      margin-bottom: 5px;
      margin-top: 5px;
      padding-left: 16px;
    }

    .section-text-block :global(.ql-indent-1) {
      padding-left: 3em;
    }
    .section-text-block :global(li.ql-indent-1) {
      padding-left: 4.5em;
    }
    .section-text-block :global(.ql-indent-2) {
      padding-left: 6em;
    }
    .section-text-block :global(li.ql-indent-2) {
      padding-left: 7.5em;
    }
    .section-text-block :global(.ql-indent-3) {
      padding-left: 9em;
    }
    .section-text-block :global(li.ql-indent-3) {
      padding-left: 10.5em;
    }
    .section-text-block :global(.ql-indent-4) {
      padding-left: 12em;
    }
    .section-text-block :global(li.ql-indent-4) {
      padding-left: 13.5em;
    }
    .section-text-block :global(.ql-indent-5) {
      padding-left: 15em;
    }
    .section-text-block :global(li.ql-indent-5) {
      padding-left: 16.5em;
    }
    .section-text-block :global(.ql-indent-6) {
      padding-left: 18em;
    }
    .section-text-block :global(li.ql-indent-6) {
      padding-left: 19.5em;
    }
    .section-text-block :global(.ql-indent-7) {
      padding-left: 21em;
    }
    .section-text-block :global(li.ql-indent-7) {
      padding-left: 22.5em;
    }
    .section-text-block :global(.ql-indent-8) {
      padding-left: 24em;
    }
    .section-text-block :global(li.ql-indent-8) {
      padding-left: 25.5em;
    }
    .section-text-block :global(.ql-indent-9) {
      padding-left: 27em;
    }
    .section-text-block :global(li.ql-indent-9) {
      padding-left: 28.5em;
    }
  `}</style>
  </section>

TextBlock.propTypes = {
  entity: EntityType,
  className: PropTypes.string,
}
export default TextBlock
