import React from "react"

import FormRenderer from "components/FormRenderer/FormRenderer"
import { follow } from "api/links"
import EntityType from "api/SirenEntityType"
import history from "appHistory"
import encodeURL from "urlHelpers/encodeURL"
import { findByName } from "api/actions"
import { findByRelation } from "api/utils"
import { mapEntitiesByClassStrict } from "api/entities"
import { getItem, setItem } from "storage"
import Loader from "components/Loader"
import { getEnvTitle, getFrontendTranslations } from "components/Layout/layoutManager"
import translate from "components/i18n/translate"

const FACILITY_REL = "facilities"
const FACILITY_CITE_AS = "cite-as"
const FIND_PARKING_ACTION = "find-parking"
const LOCALITY_REL = "https://schema.evopark.com/Locality"
const PARKING_CLASS = "http://schema.org/ParkingFacility"
const FIELD_LOCALITY_NAME = "locality"
const FIELD_PARKING_NAME = "id"
const LAST_LOCALITY_SELECTED = "lastLocalitySelected"

const updateLocalitySelect = (action, selectedValue, parkings) => {
  const localityField = findByName(action.fields, FIELD_LOCALITY_NAME) || {}

  if (localityField.value.find(locality => locality.value === selectedValue)) {
    const uniqueLocalities = []
    localityField.value = localityField.value
    .filter((locality) => { // filter unique localities
      if (locality.value && uniqueLocalities.indexOf(locality.value) === -1) {
        uniqueLocalities.push(locality.value)
        return true
      }
    })
    .map(locality => {
      locality.selected = locality.value === selectedValue
      return locality
    })
    // it influences the second select for parkings too
    const parkingField = findByName(action.fields, FIELD_PARKING_NAME) || {}
    parkingField.value = parkings.filter(parking => parking.locality === selectedValue)
    parkingField.class = parkingField.class.filter(klass => klass !== "disabled")
  }
}

class FacilityFinder extends React.Component {
  static propTypes = {
    entity: EntityType,
  }

  state = {
    action: null,
    parkings: [],
    isLoading: true,
  }

  componentDidMount() {
    follow({ links: this.props.entity.links, relationName: FACILITY_REL, callback: (entity) => {
      const parkings = mapEntitiesByClassStrict(entity.entities, PARKING_CLASS, parking => ({
        name: parking.title,
        value: (findByRelation(parking.links, FACILITY_CITE_AS) || {}).href,
        locality: (findByRelation(parking.links, LOCALITY_REL) || {}).href,
      }))

      const action = findByName(entity.actions, FIND_PARKING_ACTION)
      if (action) {
        const localityField = findByName(action.fields, FIELD_LOCALITY_NAME)
        if (localityField) {
          localityField.onChange = this.onLocalitySelect
          const lastLocalitySelected = getItem(LAST_LOCALITY_SELECTED)
          lastLocalitySelected && updateLocalitySelect(action, lastLocalitySelected, parkings)
        } else {
          // If there is no locality, then we populate the parking select
          (findByName(action.fields, FIELD_PARKING_NAME) || {}).value = parkings
        }
        if (action.fields && (getEnvTitle().indexOf("Ghent") !== -1 || getEnvTitle().indexOf("Mobiliteitsbedrijf") !== -1)) {
          action.fields = action.fields.map(field => {
            const { type, name } = field
            var { value } = field
            if (type == "select" && name == "id") {
              const defaultOption = [{ name: getFrontendTranslations() ? getFrontendTranslations().facility.defaultSelect : translate("facility.defaultSelect"), value: "", disabled: true, selected: true, hidden: true }]
              value = [...defaultOption, ...value]
            }
            return { ...field, value }
          })
        }
      }

      this.setState(() => ({ parkings, action, isLoading: false }))
    } })
  }

  onLocalitySelect = (event) => {
    // I have do this 'copy' for the FormRenderer to re-evaluate the option object
    const action = { ...this.state.action }
    // We modify the action config to reflect the user changes
    updateLocalitySelect(action, event.target.value, this.state.parkings)
    setItem(LAST_LOCALITY_SELECTED, event.target.value)
    this.setState(() => ({ action }))
  }

  onFormChange = (event, field) => {
    if (field.name === FIELD_LOCALITY_NAME) {
      this.onLocalitySelect(event)
    }
  }

  onSubmit = ({ data }) => history.push(encodeURL(data[FIELD_PARKING_NAME].replace(document.location.origin, "")))

  render() {
    const { action, isLoading } = this.state

    return (
      <section className="facility-finder container-sm d-sm-flex h-100 text-center">
        {isLoading && <Loader/>}
        {action && <FormRenderer action={action} onSubmit={this.onSubmit} onChange={this.onFormChange} className="mx-auto"/>}
        <style jsx>{`
        .facility-finder :global(.card-title) {
          font-size: 1.53rem;
          margin-bottom: 1.5rem;
          word-wrap: normal;
        }
        .facility-finder :global(select) {
          margin: 0 auto;
        }
        .facility-finder :global(button[type="submit"]) {
          margin: 0 auto;
          overflow: hidden;
        }

        .facility-finder :global(.col) {
          padding: 0 !important;
        }

        .facility-finder :global(.loader) {
          font-size: 100%;
          align-self: center;
          margin: 0 auto;
          margin-top: 100%;
        }

        @media (min-width: 576px) {
          .facility-finder :global(.loader) {
            margin-top: 0;
          }
        }

        @media (min-width: 665px) {
          .facility-finder {
            width: 90%;
            max-width: 1108px;
          }
          .facility-finder :global(.card) {
            padding: 0 !important;
          }
          .facility-finder :global(select) {
            width: 85%;
          }
        }

        @media (max-width: 665px) {
          .facility-finder :global(.row) {
            display: block;
          }
          .facility-finder :global(.form-group) {
            margin-bottom: 1em;
          }
          .facility-finder :global(button), .facility-finder :global(select) {
            width: 100%;
          }
        }
        `}</style>
      </section>
    )
  }
}

export default FacilityFinder
