import React, { Component } from "react"
import PropTypes from "prop-types"

import { FormGroup, Label } from "reactstrap"
import { CardElement } from "react-stripe-elements"

import HiddenInput from "components/FormRenderer/components/HiddenInput"

class Stripe extends Component {

  state = {
    error: this.props.error,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      this.setState(() => ({ error: this.props.error }))
    }
  }

  createOptions = {
    style: {
      base: {
        fontSize: "16px",
        color: "#495057",
        "::placeholder": {
          color: "#868e96",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  }

  handleChange = change => {
    console.log("[change]", change)
    if (change.error) {
      this.setState({ error: change.error.message })
    } else {
      this.setState({ error: undefined })
    }
  }

  render() {
    const { title, name, value, innerRef } = this.props
    const error = this.state.error

    return (
      <FormGroup>
        <Label>{title}
          <CardElement {...this.createOptions} onChange={this.handleChange}/>
          <HiddenInput type="hidden" name={name} value={value} innerRef={innerRef} invalid={!!error}/>
          {typeof error === "string" ? <div className="invalid-feedback">{ error }</div> : error }
        </Label>
        <style jsx global>{`
            .StripeElement {
              border: 1px solid ${error ? "red" : "#c2cfd6"};
              padding: 8px;
              margin: 2px;
            }
          `}</style>
      </FormGroup>
    )
  }
}

Stripe.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.node,
  innerRef: PropTypes.func,
}

export default Stripe

