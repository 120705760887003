import React from "react"
import PropTypes from "prop-types"

import { Input, FormGroup, Label } from "reactstrap"
import { isDisabled, isMultipleSelect } from "components/FormRenderer/form"
import MultipleSelect from "components/FormRenderer/components/MultipleSelect"

const Select = (props) => {
  const { innerRef, value, classes, name, onChange, required, title, error, className } = props
  if (isMultipleSelect(classes)) {
    return <MultipleSelect {...props}/>
  }

  const values = Array.isArray(value) ? value : [value]
  const selectedOption = values.find(option => option.selected)
  const defaultValue = selectedOption && selectedOption.value

  return (
    <FormGroup className={name}>
      <Label>{title}
        <Input
          innerRef={innerRef}
          type="select"
          name={name}
          defaultValue={defaultValue}
          required={required}
          disabled={isDisabled(classes)}
          onChange={onChange}
          className={className}
          invalid={!!error}>
          {values.map(option => <option key={`${name}-${option.value || option.name}`} value={option.value} className={`menu-item ${option.value}`} disabled={option.disabled} hidden={option.hidden}>{option.name}</option>)}
        </Input>
        {error}
      </Label>
    </FormGroup>
  )
}

Select.propTypes = {
  error: PropTypes.node,
  title: PropTypes.node,
  value: PropTypes.any,
  classes: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  innerRef: PropTypes.func,
}

export default Select
