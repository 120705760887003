import React from "react"
import PropTypes from "prop-types"

import FormSubmissionWrapper from "components/FormRenderer/FormSubmissionWrapper"
import CardActionsRenderer from "components/TableRenderer/CardActionsRenderer"
import { ACTION_VIEW, ENTITY_VIEW } from "components/TableRenderer/TableRenderer"
import Entity from "components/Entity"
import EntityType from "api/SirenEntityType"

const DetailViewSection = ({ detailView, actions, selectedItems, renderedEntity, innerRef, onActionResolve, layoutName }) => (
  <div ref={innerRef}>
    <FormSubmissionWrapper onActionResolve={onActionResolve}>
      {detailView === ACTION_VIEW && <CardActionsRenderer actions={actions} items={selectedItems}/>}
      {detailView === ENTITY_VIEW && <Entity entity={renderedEntity} layoutName={layoutName} renderContext="table"/>}
    </FormSubmissionWrapper>
  </div>
)

DetailViewSection.propTypes = {
  actions: PropTypes.array,
  selectedItems: PropTypes.array,
  detailView: PropTypes.string,
  renderedEntity: EntityType,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onActionResolve: PropTypes.func,
  layoutName: PropTypes.string,
}

export default DetailViewSection
