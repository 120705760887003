import React from "react"
import PropTypes from "prop-types"

import { follow, selfHref } from "api/links"
import { findByRelationStrict, filterByRelationStrict } from "api/utils"
import { findAndReplaceEmbeddedLink } from "api/entities"

import api from "api/api"
import { assertExpectations } from "components/assertExpectations"



const unresolvedEntitiesExpectations = (expectations = [], entities = []) => (
  expectations.filter(expectation => {
    const subEntity = findByRelationStrict(entities, expectation)
    return subEntity && subEntity.href
  })
)

const withEntity = (Component, expectations) => {
  class ComponentEntity extends React.Component {
    static propTypes = {
      entity: PropTypes.object,
    }

    state = { entity: this.props.entity }

    componentDidMount() {
      this.resolveExpectations()
    }

    componentDidUpdate(prevProps) {
      if (prevProps.entity !== this.props.entity) {
        this.setState(() => ({ entity: this.props.entity }))
        this.resolveExpectations()
      }
    }

    resolveExpectations = () => {
      const { entity } = this.props
      if (expectations && entity) {
        if (!assertExpectations(expectations, entity)) {
          this.resolveEntity(entity)
        } else {
          this.resolveEmbeddedLinks(entity)
        }
      }
    }

    resolveEntity = (entity) => {
      const selfLink = selfHref(entity.links)
      selfLink && follow({ link: selfLink, callback: this.onEntityResolve })
    }

    onEntityResolve = (entity) => {
      this.setState({ entity })
      this.resolveEmbeddedLinks(entity)
    }

    resolveEmbeddedLinks = (entity = {}) => {
      const embeddedLinkRels = unresolvedEntitiesExpectations(expectations["entities"], entity.entities)
      embeddedLinkRels.forEach(rel => {
        const embeddedLinks = filterByRelationStrict(entity.entities, rel)
        embeddedLinks.forEach(embeddedLink => {
          embeddedLink && api({ href: embeddedLink.href }).then(({ entity: resolvedEntity }) => {
            const entities = findAndReplaceEmbeddedLink(this.state.entity.entities, resolvedEntity)
            this.setState(prevState => ({ entity: { ...prevState.entity, entities: entities } }))
          })
        })
      })
    }

    render() {
      return <Component {...this.props} entity={this.state.entity}/>
    }
  }

  return ComponentEntity
}

export default withEntity
