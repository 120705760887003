import React from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"

import EntityType from "api/SirenEntityType"
import Image from "components/Image"
import FontAwesomeButton from "components/FontAwesome/FontAwesomeButton"

import { findByRelationStrict } from "api/utils"
import { splitActionsByClass, BULK_ACTION_CLASS } from "api/actions"

const ActionsAdapter = ({ entity, onClick, fullDisplay, removeBulk = true }) => {
  const actions = removeBulk ? splitActionsByClass(entity.actions, BULK_ACTION_CLASS).rest : entity.actions
  return <div>
    {
      actions.map(action => {
        const iconName = `icon action-${action.name}`
        const iconLink = findByRelationStrict(entity.links, iconName)
          || (entity.parent && findByRelationStrict(entity.parent.links, iconName))
        const src = iconLink && iconLink.href
        return (
          <span key={"action-" + action.name} className="action" onClick={(event) => onClick(event, action, entity)}>
            {fullDisplay ?
              <FontAwesomeButton iconURI={src}>{action.title}</FontAwesomeButton> :
              <Image title={action.title} src={src} width="24px" height="18px">
                <Button>{action.title}</Button>
              </Image>
            }
          </span>
        )
      })
    }
    <style jsx>{`
      span.action {
        margin-left: .5em;
        cursor: pointer;
      }
    `}</style>
  </div>
}
ActionsAdapter.propTypes = {
  entity: EntityType,
  onClick: PropTypes.func,
  fullDisplay: PropTypes.bool,
  removeBulk: PropTypes.bool,
}

export default ActionsAdapter
