import React from "react"
import PropTypes from "prop-types"

import FontAwesomeIcon from "components/FontAwesome/FontAwesomeIcon"
import { isFontAwesomeIcon } from "components/FontAwesome/fontawesome"
/**
 * Renders an image, and if the src can not be loaded, renders its children
 * instead.
 *
 * @class Image
 * @extends {React.PureComponent}
 */
class Image extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
    src: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    children: PropTypes.node,
  }

  state = {
    error: false,
  }

  onLoad = () => {
    this.setState({ error: false })
  }

  onError = () => {
    this.setState({ error: true })
  }

  render() {
    const { title, src, className, children, width, height } = this.props
    if (this.state.error || !src) {
      return children || null
    } else if (isFontAwesomeIcon(src)) {
      return <FontAwesomeIcon
        title={title}
        iconURI={src}
        className={className}
        width={width}
        height={height}/>
    } else {
      return <img
        src={src}
        className={className}
        onError={this.onError}
        onLoad={this.onLoad}
        title={title}
        width={width}
        height={height}/>
    }
  }
}

export default Image
