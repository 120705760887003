import React from "react"
import PropTypes from "prop-types"

const InfoBlock = ({ title, className, children, headerClass = "" }) => (
  <div className={className}>
    {title && <h3 className={`${headerClass} mt-4 mt-lg-0 mb-4 mb-lg-2 text-main`}>{title}</h3>}
    {children}
  </div>
)


InfoBlock.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  className: PropTypes.string,
  children: PropTypes.node,
  headerClass: PropTypes.string,
}

export default InfoBlock
