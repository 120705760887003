import React from "react"
import { render } from "react-dom"

// Import Font Awesome Icons Set
import "@fortawesome/fontawesome-free/css/all.min.css"
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css"

import "../scss/style.scss"
import "babel-polyfill"

import App from "App"

render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById("app")
)
