import React from "react"
var QRCode = require("qrcode.react")
import SirenEntityType from "api/SirenEntityType"
import withEntity from "components/withEntity"
import { findByClass, filterByClass } from "api/utils"
import Card from "components/Card"
import PropertiesBox from "components/PropertiesBox"
import { CustomerClass, ContractBookingClass, IdentificationComponentClass } from "components/registry"
import PropertiesLabelValue from "components/PropertiesLabelValue"
import InfoBlock from "components/InfoBlock"
import PropTypes from "prop-types"

const IdentificationMedium = ({ entity = {}, layoutName }) => {
  const customerEntity = findByClass(entity.entities, CustomerClass)
  const contractBookingEntity = findByClass(entity.entities, ContractBookingClass)
  const identificationComponentEntities = filterByClass(entity.entities, IdentificationComponentClass)
  const identificationComponentEntityTitle = entity.properties.componentsTitle

  return (
    <Card header={entity.title} headerClass={`${layoutName == "customerSelfService" ? "customer-" : ""}IdentificationMedium`}>
      <InfoBlock title={identificationComponentEntityTitle} className="mb-4">
        {identificationComponentEntities.map(e => {
          if (/QR/i.test(e.properties.type)) {
            return <div className="py-3"><QRCode value={e.properties.identifier} /></div>
          }
          else {
            return <PropertiesLabelValue
              key={e}
              label={e.properties.type}
              value={e.properties.identifier}/>
          }
        })}
      </InfoBlock>
      <PropertiesBox
        entity={customerEntity}
        keys="customerNumber,name,contact_name,contact_department,email,secondaryEmail"
        title={entity.properties && entity.properties.customerTitle}
        label/>
      <PropertiesBox
        entity={contractBookingEntity}
        keys="orderNumber,contractBookingNumber,parkingProductName,customerType,state,validFrom,validUntil"
        label/>
    </Card>
  )
}

IdentificationMedium.propTypes = {
  entity: SirenEntityType.isRequired,
  layoutName: PropTypes.string,
}

export default withEntity(IdentificationMedium, { entities: ["contractBooking", "customer"] })
