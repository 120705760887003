import React from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"

import Loader from "components/Loader"

const LoadingButton = ({ loading, children, className = "", ...props }) => (
  <Button {...props} className={`d-flex align-content-center ${className}`}>
    {children}
    {loading ? <Loader className="ml-2 mt-1"/> : null}
  </Button>
)
LoadingButton.propTypes = {
  ...Button.propTypes,
  loading: PropTypes.bool,
  className: PropTypes.string,
}

export default LoadingButton
