import React from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap"

import FormRenderer from "components/FormRenderer/FormRenderer"
import withFormSubmission from "components/FormRenderer/withFormSubmission"
import withFormContext from "components/FormRenderer/withFormContext"
import { getFrontendTranslations } from "components/Layout/layoutManager"
import translate from "components/i18n/translate"

class SimpleActionModal extends React.Component {
  onSubmit = (config) => {
    this.props.onSubmit(config)
    .then(this.props.closeModal)
    .catch(this.props.closeModal)
  }

  render() {
    return (
      <Modal isOpen={this.props.showModal} toggle={this.props.closeModal}>
        <ModalHeader toggle={this.props.closeModal}>{this.props.title}</ModalHeader>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.closeModal}>{getFrontendTranslations() ? getFrontendTranslations().modal.cancelButtonText : translate("modal.cancelButtonText")}</Button>
          <FormRenderer action={this.props.action} onSubmit={this.onSubmit} className="form-no-style"/>
        </ModalFooter>
        <style jsx global>{`
        .form-no-style .card {
          padding: 0 !important;
          margin: 0 !important;
          border: none;
        }
        .form-no-style .card-body {
          padding: 0 !important;
          margin-bottom: 7px;
        }
        .form-no-style .form-group {
          margin-bottom: 0;
        }
        .form-no-style .invalid-feedback {
          display: none !important;
        }
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          :global(.modal-footer) {
            flex-direction: column !important;
            align-items: stretch !important;
          }
          :global(.modal-footer .form-no-style .img-fluid) {
            display: none !important;
          }
          :global(.modal-footer .form-no-style .form-group > button) {
            margin: 10px 0px 1px 0px !important;
          }
         
       }
      `}</style>
      </Modal>
    )
  }
}
SimpleActionModal.propTypes = {
  callback: PropTypes.func,
  title: PropTypes.string,
  showModal: PropTypes.bool,
  action: PropTypes.object,
  onSubmit: PropTypes.func,
  closeModal: PropTypes.func,
}

export default withFormContext(withFormSubmission(SimpleActionModal))
