import React from "react"

import { Container } from "reactstrap"

import FormRenderer from "components/FormRenderer/FormRenderer"
import SirenEntityType from "api/SirenEntityType"
import PropTypes from "prop-types"
import { findByRelationStrict, findByClass, isEmptyString } from "api/utils"
import { findById, findByName, findByType } from "api/actions"
import SignupSummary from "components/pages/public/Account/Signup/SignupSummary"
import get from "get"
import { PostalAddressClass, SepaMandateClass, CardPaymentMethodClass, ProductOfferClass, NewBookingRequestPageClass, WaitlistClass } from "components/registry"
import EntityRenderer from "components/EntityRenderer/EntityRenderer"
import formatPrice from "formatPrice"
import PropertyTranslation from "components/PropertyTranslation"

import StripeScriptLoader from "react-stripe-script-loader"
import { Elements, StripeProvider, injectStripe } from "react-stripe-elements"
import CreditCardRenderer from "components/CreditCardRenderer/CreditCardRenderer"

const PARKING_PRODUCT_RELATION = "parkingProduct"
const BOOKING_REQUEST_CATEGORY_ID = "bookingRequest"
const BOOKING_REQUEST_INITIALLY_REQUESTED_PARKING_SPACE_FIELD = "bookingRequestInitiallyRequestedParkingSpaceCount"
const BOOKING_REQUEST_REMARKS_FIELD = "remarks"
const BOOKING_REQUEST_STARTSON_FIELD = "bookingRequestStartsOn"
const BOOKING_REQUEST_PAYMENT_METHOD_TYPE_FIELD = "paymentMethodType"
const BOOKING_REQUEST_PRODUCT_CONFIGURATION_ID = "bookingRequestProductConfigurationId"

class FinaliseRequestOffer extends React.Component {
  static propTypes = {
    entity: SirenEntityType,
    actionName: PropTypes.string,
    action: PropTypes.object,
  }

  state = {
    action: findByName(this.props.entity.actions, this.props.actionName),
    price: 0,
    initiallyRequestedParkingSpaceCount: get(findByName(this.props.entity.actions, this.props.actionName) && findByName(findByName(this.props.entity.actions, this.props.actionName).fields, BOOKING_REQUEST_INITIALLY_REQUESTED_PARKING_SPACE_FIELD), "value") || 1,
  }

  componentDidMount() {
    const { entity } = this.props
    const action = this.state.action
    var price
    const bookingRequestProductConfigurationIdField = action && findByName(action.fields, BOOKING_REQUEST_PRODUCT_CONFIGURATION_ID)
    if (bookingRequestProductConfigurationIdField && Array.isArray(bookingRequestProductConfigurationIdField.value)) {
      const defaultConfigId = bookingRequestProductConfigurationIdField && bookingRequestProductConfigurationIdField.value[0].value
      price = entity.properties.configOptions.find(val => val.id == defaultConfigId).firstMonthPrice
    } else {
      price = this.props.entity.properties.firstMonthPrice || findByRelationStrict(this.props.entity.entities, PARKING_PRODUCT_RELATION).properties.price
    }
    this.setState({ price })
    this.summary && this.summary.updatePrice(this.state.initiallyRequestedParkingSpaceCount, price)
  }

  currentPaymentMethodTypeValue = null

  onFormChange = (event, field) => {
    if (field.name === BOOKING_REQUEST_INITIALLY_REQUESTED_PARKING_SPACE_FIELD) {
      this.setState({ initiallyRequestedParkingSpaceCount: event.target.value })
      this.summary && this.summary.updatePrice(event.target.value, this.state.price)
    }

    if (field.name === BOOKING_REQUEST_PAYMENT_METHOD_TYPE_FIELD) {
      this.currentPaymentMethodTypeValue = event.target.value
    }

    if (field.name === BOOKING_REQUEST_PRODUCT_CONFIGURATION_ID) {
      const { entity } = this.props
      var action = { ...this.state.action }

      const updatedFields = action.fields.map(item => {
        var eachField = { ...item }
        if (eachField.name == BOOKING_REQUEST_PAYMENT_METHOD_TYPE_FIELD && (Array.isArray(eachField.value) || eachField.type == "hidden")) {

          const defaultConfigId = event.target.value
          const defaultPaymentMethodType = entity.properties.configOptions.find(valu => valu.id == defaultConfigId).paymentMethodTypes
          const price = entity.properties.configOptions.find(valu => valu.id == defaultConfigId).firstMonthPrice
          this.setState({ price })
          this.summary && this.summary.updatePrice(this.state.initiallyRequestedParkingSpaceCount, price)
          var index = 0
          var newValues = eachField.value
          if (Array.isArray(eachField.value)) {
            newValues = eachField.value.map(val => {
              var eachValue = { ...val }
              if (defaultPaymentMethodType.indexOf(val.value) === -1) {
                eachValue.hidden = true
                eachValue.selected = false
                return eachValue
              } else {
                index = index + 1
                eachValue.hidden = false
                if (index == 1) {
                  eachValue.selected = true
                  this.currentPaymentMethodTypeValue = eachValue.value
                } else { eachValue.selected = false }
                return eachValue
              }
            })
          }
          return { ...eachField, value: newValues }
        }
        return eachField
      })
      var newAction = { ...this.state.action, fields: updatedFields }
      this.setState({ action: newAction })

    }
  }

  setSummaryRef = summary => {
    this.summary = summary
  }

  decorateField = (name, { fieldComponent }) => {
    if (name === BOOKING_REQUEST_STARTSON_FIELD) {
      return this.decorateBookingRequestStartsOnField(fieldComponent)
    }

    if (name === BOOKING_REQUEST_REMARKS_FIELD && this.props.entity.class.find(klass => klass === NewBookingRequestPageClass)) {
      return this.decorateNewBookingRemarksField(fieldComponent)
    }

    const elementClasses = fieldComponent.props.classes || []
    if (elementClasses.includes("decorate-initiallyRequestedParkingSpaceCount-sum") ||
      (name === BOOKING_REQUEST_INITIALLY_REQUESTED_PARKING_SPACE_FIELD && !elementClasses.includes("skip-decorate-initiallyRequestedParkingSpaceCount-sum"))) {
      return this.decorateInitiallyRequestedParkingSpaceCountField(fieldComponent)
    }

    if (name === BOOKING_REQUEST_PAYMENT_METHOD_TYPE_FIELD) {
      return this.decoratePaymentMethodTypeField(fieldComponent)
    }


    return fieldComponent
  }

  decoratePaymentMethodTypeField(fieldComponent) {
    const { entity, actionName } = this.props
    const directDebitPaymentMethodEntity = findByClass(entity.entities, SepaMandateClass)
    const cardPaymentMethodEntity = findByClass(entity.entities, CardPaymentMethodClass)

    const action = { ...findByName(entity.actions, actionName) }
    const bookingRequestProductConfigurationIdField = action && findByName(action.fields, BOOKING_REQUEST_PRODUCT_CONFIGURATION_ID)
    var bookingRequestPaymentMethodTypeField = action && findByName(action.fields, BOOKING_REQUEST_PAYMENT_METHOD_TYPE_FIELD)

    if (bookingRequestPaymentMethodTypeField && Array.isArray(bookingRequestPaymentMethodTypeField.value)) {
      const selected = bookingRequestPaymentMethodTypeField.value.filter(e => e.selected == true)
      if (bookingRequestProductConfigurationIdField && Array.isArray(bookingRequestProductConfigurationIdField.value) && selected.length < 1) {
        const defaultConfigId = bookingRequestProductConfigurationIdField.value[0].value
        const defaultPaymentMethodType = entity.properties.configOptions.find(val => val.id == defaultConfigId).paymentMethodTypes
        var index = 0
        Array.isArray(bookingRequestPaymentMethodTypeField.value) && bookingRequestPaymentMethodTypeField.value.map(val => {
          if (defaultPaymentMethodType.indexOf(val.value) === -1) {
            val.hidden = true
            val.selected = false
          } else {
            index = index + 1
            val.hidden = false
            if (index == 1) {
              val.selected = true
            } else { val.selected = false }
          }
        })
      }
    }


    if (!this.currentPaymentMethodTypeValue) {
      // set initial value
      this.currentPaymentMethodTypeValue = Array.isArray(fieldComponent.props.value) ? bookingRequestProductConfigurationIdField ? fieldComponent.props.value.find(val => val.selected == true).value : fieldComponent.props.value[0].value : fieldComponent.props.value
    }

    return (
      <React.Fragment>
        {fieldComponent}
        {(this.currentPaymentMethodTypeValue === "direct_debit" && directDebitPaymentMethodEntity) &&
          <EntityRenderer entity={directDebitPaymentMethodEntity} properties="accountHolder,iban,bic,mandateIdentification,creditor_identifier"/>
        }
        {(this.currentPaymentMethodTypeValue === "card" && cardPaymentMethodEntity) &&
          <CreditCardRenderer entity={cardPaymentMethodEntity} properties="ccHolder,ccLast4,ccBrand,ccExp"/>
        }
      </React.Fragment>
    )
  }

  decorateNewBookingRemarksField(fieldComponent) {
    const { entity } = this.props
    const addressEntity = findByClass(entity.entities, PostalAddressClass)
    const productOfferEntity = findByClass(entity.entities, ProductOfferClass)
    return (
      entity.class.find(klass => klass === NewBookingRequestPageClass) &&
      <div>
        <EntityRenderer entity={entity} properties="name,email,customerNumber"/>
        <EntityRenderer entity={addressEntity} properties="streetAddress,addressLocality,addressRegion,postalCode,addressCountry"/>
        <EntityRenderer entity={productOfferEntity} properties="costCenter,name"/>
        {fieldComponent}
      </div>
    )
  }

  decorateInitiallyRequestedParkingSpaceCountField(fieldComponent) {
    const { entity } = this.props

    const parkingProductEntity = findByRelationStrict(entity.entities, PARKING_PRODUCT_RELATION)
    const price = this.state.price
    const priceCurrency = get(parkingProductEntity, "properties.priceCurrency")
    const summaryText = entity.properties.totalDescription
    const initiallyRequestedParkingSpaceCount = this.state.initiallyRequestedParkingSpaceCount

    return <React.Fragment>
      {fieldComponent}
      <SignupSummary
        ref={this.setSummaryRef}
        summaryText={summaryText}
        price={price}
        priceCurrency={priceCurrency}
        initiallyRequestedParkingSpaceCount={initiallyRequestedParkingSpaceCount}/>
    </React.Fragment>
  }

  decorateBookingRequestStartsOnField(fieldComponent) {
    const { entity } = this.props
    const parkingProductEntity = findByRelationStrict(entity.entities, PARKING_PRODUCT_RELATION)
    const duration = get(parkingProductEntity, "properties.duration")
    const priceCurrency = get(parkingProductEntity, "properties.priceCurrency")
    const oneTimePrice = get(parkingProductEntity, "properties.oneTimePrice")
    const setupFee = get(parkingProductEntity, "properties.setupFee")
    const pricePerUnit = get(parkingProductEntity, "properties.price")
    const bookingRequestProductConfigurationIdField = this.state.action && findByName(this.state.action.fields, BOOKING_REQUEST_PRODUCT_CONFIGURATION_ID)
    const showDuration = (bookingRequestProductConfigurationIdField && Array.isArray(bookingRequestProductConfigurationIdField.value)) ? false : true

    return (
      <div>
        {showDuration && <div className="text-secondary">{<PropertyTranslation entity={parkingProductEntity} name="duration"/>} {duration}</div>}
        {(oneTimePrice || setupFee || pricePerUnit) &&
          <div className="pb-3 font-weight-bold">
            {!isNaN(oneTimePrice) && <div>{<PropertyTranslation entity={parkingProductEntity} name="oneTimePrice"/>} {formatPrice(oneTimePrice, priceCurrency)}</div>}
            {!isNaN(setupFee) && !isEmptyString(setupFee) && <div>{<PropertyTranslation entity={parkingProductEntity} name="setupFee"/>} {formatPrice(setupFee, priceCurrency)}</div>}
            {!isNaN(pricePerUnit) && <div>{<PropertyTranslation entity={parkingProductEntity} name="price"/>} {formatPrice(pricePerUnit, priceCurrency)}</div>}
          </div>
        }
        {fieldComponent}
      </div>
    )
  }


  render() {
    const { entity } = this.props
    const action = this.state.action
    const addressEntity = findByClass(entity.entities, PostalAddressClass)
    const productOfferEntity = findByClass(entity.entities, ProductOfferClass)

    if (!action) {
      return null
    }
    const bookingRequestCategory = findById(action.categories, BOOKING_REQUEST_CATEGORY_ID)
    if (bookingRequestCategory) {
      const parkingProductEntity = findByRelationStrict(entity.entities, PARKING_PRODUCT_RELATION)
      bookingRequestCategory.title = get(parkingProductEntity, "properties.name")
      bookingRequestCategory.class = ["aside"]
    }
    // Make the submit button float right
    const submitField = findByType(action.fields, "submit")
    submitField.className = `${submitField.className || ""} d-block w-100`

    const { publicStripeKey } = entity.properties
    const StripeComponent = injectStripe(FormRenderer)
    const hasStripeElement = action.fields.filter(field => field.type === "stripe-intent").length > 0

    return (
      <div className="signup-page">
        <Container>
          <div className="text-center my-4">
            <h1>{entity.title}</h1>
          </div>
          {(publicStripeKey || hasStripeElement) ? (
            <StripeScriptLoader
              script="https://js.stripe.com/v3/"
              loader="Loading..."
            >
              <StripeProvider apiKey={ publicStripeKey ? publicStripeKey : "null"}>
                <Elements>
                  <StripeComponent action={action} links={entity.links} onChange={this.onFormChange} decorateField={this.decorateField} className="d-md-flex"/>
                </Elements>
              </StripeProvider>
            </StripeScriptLoader>
          ) : (
            <div className="d-md-flex">
              {entity.class.find(klass => klass === WaitlistClass) && <div className="signup-main p-2">
                <EntityRenderer entity={entity} properties="name,email,customerNumber"/>
                <EntityRenderer entity={addressEntity} properties="streetAddress,addressLocality,addressRegion,postalCode,addressCountry"/>
                <EntityRenderer entity={productOfferEntity} properties="costCenter,name"/>
              </div>}
              <FormRenderer action={action} links={entity.links} onChange={this.onFormChange} decorateField={this.decorateField} className="d-md-flex"/>
            </div>
          )}
        </Container>
        <style jsx>{`
          @media (min-width: 769px) {
            .signup-page div {
              flex-direction: column;
            }
            .signup-page :global(.signup-main) {
              flex: 1 0 70%;
              overflow-y: auto;
            }
            .signup-page :global(.signup-aside) {
              flex: 1 0 0%;
              min-width: 335px;
              position: sticky;
              top: 105px;
              align-self: flex-start;
            }
          }

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            .signup-page :global(.d-md-flex) {
              width: 100% !important;
            }
            .signup-page :global(.p-4.card:before) {
              width: 85px !important;
            }
          }

          @-moz-document url-prefix() {
            .signup-page :global(.p-4.card:before) {
              width: 85px !important;
            }
          }

          :global(.app-body) {
            overflow-x: inherit;
          }
        `}
        </style>
      </div>
    )
  }
}

FinaliseRequestOffer.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default FinaliseRequestOffer
