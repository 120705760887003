import React from "react"

import SirenEntityType from "api/SirenEntityType"
import AccountForm from "components/pages/public/Account/AccountForm"

const UpdateOrResetPassword = ({ entity }) => <AccountForm actions={entity.actions} name="resetPassword"/>

UpdateOrResetPassword.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default UpdateOrResetPassword
