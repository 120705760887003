import React from "react"

import SirenEntityType from "api/SirenEntityType"
import Hero from "components/pages/public/ParkingFacilityPage/Hero"
import ParkingFacilityPageGarageInfo from "components/pages/public/ParkingFacilityPage/ParkingFacilityPageGarageInfo"
import ParkingFacilityPageDescription from "components/pages/public/ParkingFacilityPage/ParkingFacilityPageDescription"
import ParkingFacilityPageTariffs from "components/pages/public/ParkingFacilityPage/ParkingFacilityPageTariffs"

const ParkingFacilityPage = ({ entity }) => {

  return (
    <section>
      <Hero title={entity.title}/>
      <div className="container">
        <ParkingFacilityPageGarageInfo entity={entity}/>
        <ParkingFacilityPageDescription entity={entity}/>
        <ParkingFacilityPageTariffs entity={entity}/>
      </div>
    </section>
  )
}
ParkingFacilityPage.propTypes = {
  entity: SirenEntityType.isRequired,
}
export default ParkingFacilityPage
