import React from "react"
import PropTypes from "prop-types"
import { Container, Row } from "reactstrap"

import FormRenderer from "components/FormRenderer/FormRenderer"
import { ActionsType } from "api/SirenEntityType"
import { findByName } from "api/actions"

const AccountForm = ({ actions, name, children }) => {
  const action = findByName(actions, name)
  if (!action) {
    return null
  }
  return (
    <div className="app flex-row d-flex align-items-center h-100 mt-4">
      <Container className="account-container">
        <Row className="justify-content-center">
          <FormRenderer action={action}/>
        </Row>
        {children}
      </Container>
      <style jsx>{`
          @media (min-width: 769px) {
            :global(.account-container) {
              width: 32.7%;
            }
          }
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          :global(.row) {
            display: block !important;
          }
       }
      `}</style>
    </div>
  )
}

AccountForm.propTypes = {
  actions: ActionsType,
  name: PropTypes.string,
  children: PropTypes.node,
}

export default AccountForm
