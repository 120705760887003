import React from "react"
import PropTypes from "prop-types"

import SirenEntityType, { ActionType } from "api/SirenEntityType"

import Card from "components/Card"
import { Row, Col } from "reactstrap"
import PropertyTranslation from "components/PropertyTranslation"

const CreditCardRenderer = ({ entity, properties }) => {
  const propertyKeys = properties.toString().split(",")

  return (
    <div className="credit-card">
      <Card title={entity.title}>
        <Card className={`${entity.properties["last_payment_status"] == "Rejected" ? "card-rejected" : ""}`}>
          <Row>
            <Col md="6"><i className="fas fa-credit-card fa-9x"></i></Col>
            <Col md="6">
              {propertyKeys.map(key => (
                <div className="pb-2" key={key}>
                  <label className="label mb-0">{<PropertyTranslation entity={entity} name={key}/>}</label>
                  <div className={`${entity.properties[key] == "Rejected" ? "danger" : ""}`}>{key == "ccLast4" || key == "cc" ? "xxxx-xxxx-xxxx-" : ""}{entity.properties[key]}</div>
                </div>
              ))}
            </Col>
          </Row>
        </Card>
      </Card>
      <style jsx>{`
    .credit-card :global(.card-title) {
        color: #0376ba;
        font-size: 1.53125rem;
    }
    .credit-card :global(.card):first-child {
        background-color: #e9e9e9;
    }
    .credit-card :global(.card):nth-child(2) {
        border-radius: 25px;
    }
    i {
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .label {
        color: #b8b8b8;
        font-size: 20px;
    }
    .danger {
      font-weight: bold;
      color: red;
    }
    .credit-card :global(.card-rejected) {
      border: solid 1px red;
      background: #ffe5e5;
    }
    `}</style>
    </div>
  )
}
  

CreditCardRenderer.propTypes = {
  entity: SirenEntityType,
  action: ActionType,
  properties: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
}

export default CreditCardRenderer
