import translations from "provision/translations.json"
import get from "get"
import { getItem } from "storage"
import { LANGUAGE_KEY } from "api/api"

/**
 * Simple translate method for now.
 * Can be used like:
 *
 * translate("error.generic")
 * or
 * translate("error.showNumber", { total: 23 })
 *
 * The later will work as a string replace where `%{total}` will be replace by `23`
 * "There is at least %{total} errors" -> "There is at least 23 errors"
 */
export default (key, values) => {
  const lang = getItem(LANGUAGE_KEY) || translations.referenceLanguage
  const translated = get(translations[lang], key)
  if (!values || !translated) {
    return translated || `${lang}:${translations.namespace}.${key}`
  }

  return Object
  .keys(values)
  .reduce(
    (result, valueKey) => result.replace(`%{${valueKey}}`, values[valueKey]),
    translated
  )
}
