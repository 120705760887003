import React from "react"
import PropTypes from "prop-types"
import { Badge, Nav } from "reactstrap"

import Link from "components/Link"
import EntityType from "api/SirenEntityType"
import SidebarMinimizer from "components/Layout/Sidebar/SidebarMinimizer"
import { filterByRelation } from "api/utils"
import { getNotification } from "components/FormRenderer/form"

const toggleDropDown = (e) => {
  e.preventDefault()
  e.target.parentElement.classList.toggle("open")
}

const Sidebar = ({ entity, currentUrl }) => {

  const items = filterByRelation(entity.entities, "category").map(category => (
    <li key={category.title} className="nav-item nav-dropdown open">
      <a className="nav-link nav-dropdown-toggle" href="#" onClick={toggleDropDown}>
        <i className={`nav-icon ${category.properties.icon || "icon-energy"}`}></i>
        {category.title}
        {category.properties.notification && <Badge className="mr-3" color="danger" pill>{category.properties.notification}</Badge>}
      </a>
      <ul className="nav-dropdown-items">
        { filterByRelation(category.links, "menu-item").map(link => {
          const isActive = currentUrl.split("?")[0] === link.href
          const notificationCount = getNotification(link.class)

          return (
            <li className={`nav-item ${link.title.replace(/\s/g, "")}`} key={link.title}>
              <Link link={link} className={`nav-link active ${isActive ? "custom-active" : ""} ${link.title.replace(/\s/g, "")}`}>
                {link.title}
                {notificationCount && notificationCount !== "0" && <Badge color="primary" pill>{notificationCount}</Badge>}
              </Link>
            </li>
          )})}
      </ul>
      <style jsx>{`
          :global(.sidebar .nav-link.active.custom-active) {
            background-color: #398db5;
          }
      `}
      </style>
    </li>
  ))

  return (
    <div className="sidebar">
      <div className="scrollbar-container sidebar-nav">
        <Nav>
          {items}
        </Nav>
      </div>
      <SidebarMinimizer/>
    </div>
  )
}
Sidebar.propTypes = {
  entity: EntityType,
  currentUrl: PropTypes.string,
}

export default Sidebar
