import React, { Component } from "react"

import { FormGroup, Label, Button, InputGroup, InputGroupAddon, Input } from "reactstrap"
import FontAwesomeIcon from "components/FontAwesome/FontAwesomeIcon"
import PureInput from "components/FormRenderer/components/PureInput"
import { getFrontendTranslations } from "components/Layout/layoutManager"
import translate from "components/i18n/translate"
import generatePassword from "generatePassword"
import { isWithGenerator } from "components/FormRenderer/form"
import RequiredTooltip from "components/FormRenderer/components/RequiredTooltip"

class Password extends Component {
  state = {
    value: this.props.value,
    visible: false,
    confirmPasswordValue: "",
    visibleconfirmPassword: false,
    touched: false,
  }

  toggleType = () => this.setState((state) => ({ visible: !state.visible }))
  toggleConfirmPassword = () => this.setState((state) => ({ visibleconfirmPassword: !state.visibleconfirmPassword }))
  generatePassword = () => this.setState(() => ({ value: generatePassword(), visible: true }))
  handlePasswordChange = (event) => {
    this.setState({ value: event.target.value })
  }
  handleConfirmPassword = (event) => {
    this.setState({ confirmPasswordValue: event.target.value })
  }
  handleClick = () => {
    this.setState({ touched: true })
  }

  render() {
    const { value, visible, confirmPasswordValue, visibleconfirmPassword, touched } = this.state
    const withGenerator = isWithGenerator(this.props.classes)
    const invalid = (value != confirmPasswordValue && touched)
    const signupForm = document.getElementsByClassName("app-body signup")
    const passwordResetForm = document.getElementsByClassName("app-body customer reset-password")

    return (
      <React.Fragment>
        <FormGroup className={this.props.name}>
          <Label>{this.props.title}
            <InputGroup>
              <PureInput {...this.props} value={value} type={visible ? "text" : "password"} onChange={(e) => {this.handlePasswordChange(e)}}/>
              <InputGroupAddon addonType="append">
                <Button color="secondary" title={visible ? (getFrontendTranslations() ? getFrontendTranslations().inputPassword.hide : translate("inputPassword.hide")) : (getFrontendTranslations() ? getFrontendTranslations().inputPassword.show : translate("inputPassword.show"))} onClick={this.toggleType}>
                  <FontAwesomeIcon iconURI={visible ? "fa:eye-slash" : "fa:eye"}/>
                </Button>
              </InputGroupAddon>
            </InputGroup>
            {withGenerator && <Button onClick={this.generatePassword} className="mt-2">{getFrontendTranslations() ? getFrontendTranslations().inputPassword.generate : translate("inputPassword.generate")}</Button>}
            {this.props.error}
          </Label>
        </FormGroup>
        {(signupForm.length > 0 || passwordResetForm.length > 0) &&
        <FormGroup className="confirmPassword">
          <Label>{getFrontendTranslations() ? getFrontendTranslations().inputPassword.confirmPassword : translate("inputPassword.confirmPassword")} {" "} <RequiredTooltip required={true} />
            <InputGroup>
              <Input invalid={invalid} name="confirmPassword" value={confirmPasswordValue} type={visibleconfirmPassword ? "text" : "password"} onChange={(e) => {this.handleConfirmPassword(e)}} onClick={this.handleClick} required/>
              <InputGroupAddon addonType="append">
                <Button color="secondary" title={visibleconfirmPassword ? (getFrontendTranslations() ? getFrontendTranslations().inputPassword.hide : translate("inputPassword.hide")) : (getFrontendTranslations() ? getFrontendTranslations().inputPassword.show : translate("inputPassword.show"))} onClick={this.toggleConfirmPassword}>
                  <FontAwesomeIcon iconURI={visibleconfirmPassword ? "fa:eye-slash" : "fa:eye"}/>
                </Button>
              </InputGroupAddon>
            </InputGroup>
            { invalid && <div className="invalid-feedback" style={{ display: "block" }}>{getFrontendTranslations() ? getFrontendTranslations().inputPassword.confirmPasswordError : translate("inputPassword.confirmPasswordError")}</div>}
          </Label>
        </FormGroup>
        }
        <style jsx global>{`
        .invalid-feedback {
          display: block !important;
        }
      `}</style>
      </React.Fragment>
    )
  }
}
Password.propTypes = PureInput.propTypes

export default Password
