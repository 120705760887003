import React from "react"
import PropTypes from "prop-types"

import { Input, FormGroup, Col, Label } from "reactstrap"
import { isInline, getDiscriminatorKey } from "components/FormRenderer/form"

const Radio = ({ innerRef, value, classes, name, title, className, onChange, state, error, category, required }) => {
  const values = Array.isArray(value) ? value : [value]
  const key = getDiscriminatorKey(classes, name, category)

  return (
    <FormGroup row className={name}>
      <Col md="3">
        <Label>{title}</Label>
      </Col>
      <Col md="9">
        {
          values.map(option => (
            <FormGroup check inline={isInline(classes)} key={`${name}-${option.value || option.name}`}>
              <Input
                className={`form-check-input ${className}`}
                type="radio"
                id={option.value}
                name={name}
                value={option.value}
                onChange={onChange}
                checked={state[key] === option.value}
                innerRef={innerRef}
                required = {required}
                hidden = {option.hidden}
              />
              <Label className={`form-check-label ${option.hidden ? "hidden" : ""}`} check htmlFor={option.value}>{option.name}</Label>
            </FormGroup>
          ))
        }
      </Col>
      {error}
      <style jsx global>{`
        .hidden {
          display: none;
        }
      `}</style>
    </FormGroup>
  )
}
Radio.propTypes = {
  value: PropTypes.any,
  classes: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  title: PropTypes.node,
  className: PropTypes.string,
  onChange: PropTypes.func,
  state: PropTypes.object,
  innerRef: PropTypes.func,
  error: PropTypes.node,
  category: PropTypes.string,
  required: PropTypes.bool,
}

export default Radio
