import { lazy } from "react"

const Input = lazy(() => import("components/FormRenderer/components/Input"))

const components = {
  "file": lazy(() => import("components/FormRenderer/components/File")),
  "color": lazy(() => import("components/FormRenderer/components/Color")),
  "hidden": lazy(() => import("components/FormRenderer/components/HiddenInput")),
  "textarea": lazy(() => import("components/FormRenderer/components/TextArea")),
  "checkbox": lazy(() => import("components/FormRenderer/components/Checkbox")),
  "radio": lazy(() => import("components/FormRenderer/components/Radio")),
  "select": lazy(() => import("components/FormRenderer/components/Select")),
  "password": lazy(() => import("components/FormRenderer/components/Password")),
  "submit": lazy(() => import("components/FormRenderer/components/Submit")),
  "stripe-intent": lazy(() => import("components/FormRenderer/components/Stripe")),
}

const registry = {
  getComponentForType: (type = "text") => (type !== "entityId" && (components[type] || Input)) || null,
}

export default registry
