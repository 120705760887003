
import React from "react"

import { Button, Container, Alert } from "reactstrap"
import SirenEntityType from "api/SirenEntityType"
import withEntity from "components/withEntity"
import Card from "components/Card"
import Link from "components/Link"
import { findByName } from "api/actions"
import { filterByClassStrict, findByRelationStrict } from "api/utils"
import PropertiesBox from "components/PropertiesBox"
import FontAwesomeIcon from "components/FontAwesome/FontAwesomeIcon"
import ActionToggle from "components/ActionToggle"
import getCustomKeys from "components/getCustomKeys"
import { getFrontendTranslations } from "components/Layout/layoutManager"
import translate from "components/i18n/translate"
import { TextBlockClass } from "components/registry"
import TextBlock from "components/pages/public/LandingPage/TextBlock"
import CreditCardRenderer from "components/CreditCardRenderer/CreditCardRenderer"
import PropTypes from "prop-types"
import api from "api/api"


const CustomerAccount = ({ entity, layoutName }) => {
  const addressEntity = findByRelationStrict(entity.entities, ["address"])
  const deliveryAddressEntity = findByRelationStrict(entity.entities, ["deliveryAddress"])
  //TODO: Clean up payment method entity once psp branch is merged on backend
  const directDebitPaymentMethodEntity = findByRelationStrict(entity.entities, ["paymentMethod"]) ||
    findByRelationStrict(entity.entities, ["directDebitPaymentMethod"])
  const trustCommercePaymentMethodEntity = findByRelationStrict(entity.entities, ["trustcommercePaymentMethod"])
  const pgsPaymentMethodEntity = findByRelationStrict(entity.entities, ["pgsPaymentMethod"])
  const cardPaymentMethodEntity = findByRelationStrict(entity.entities, ["cardPaymentMethod"])
  const updateCardPaymentMethodAction = findByName(entity.actions, "update-card-payment-method")
  const replaceCardPaymentMethodAction = findByName(entity.actions, "replace-card-payment-method")
  const stripeSepaPaymentMethodEntity = findByRelationStrict(entity.entities, ["stripeSepaPaymentMethod"])
  const replaceStripeSepaPaymentMethodAction = findByName(entity.actions, "replace-stripe-sepa-payment-method")
  // const updatePgsPayment = findByName(entity.actions, "pgs-update")
  const changePasswordAction = findByName(entity.actions, "change-password")
  const updateCustomerAction = findByName(entity.actions, "update-customer")
  //TODO: Clean up update payment method action once psp branch is merged on backend
  const updateDirectDebitPaymentMethodAction = findByName(entity.actions, "update-payment-method") ||
    findByName(entity.actions, "update-direct-debit-payment-method")
  const updateAddressAction = findByName(entity.actions, "update-address")
  const sepaMandateLink = directDebitPaymentMethodEntity && findByRelationStrict(directDebitPaymentMethodEntity.links, ["alternate", "pdf"])
  const customKeys = entity && getCustomKeys(entity.properties, "custom")
  const pgsFormUrl = pgsPaymentMethodEntity && findByRelationStrict(pgsPaymentMethodEntity.links, ["external", "pgs"])
  const pgsForm = pgsPaymentMethodEntity && findByName(pgsPaymentMethodEntity.actions, "pgs-update")
  const pgsSwitchType = pgsPaymentMethodEntity && findByName(pgsPaymentMethodEntity.actions, "pgs-switch-type")

  const trustCommerceConnectUrl = trustCommercePaymentMethodEntity && findByRelationStrict(trustCommercePaymentMethodEntity.links, ["external", "trustcommerce"])
  const textBlock = filterByClassStrict(entity.entities, TextBlockClass)

  return (
    <Container>
      {textBlock.length > 0 && textBlock.map(eachTextBlock => (
        <Alert key={eachTextBlock.title} color={eachTextBlock.properties.message_type}>
          <TextBlock entity={eachTextBlock}/>
        </Alert>
      ))
      }

      { trustCommercePaymentMethodEntity &&
        <Card title={getFrontendTranslations() ? getFrontendTranslations().trustCommerce.TCtitle : translate("trustCommerce.title")} subTitle={getFrontendTranslations() ? getFrontendTranslations().trustCommerce.TCdesc : translate("trustCommerce.desc")}>
          <CreditCardRenderer entity={trustCommercePaymentMethodEntity} properties="name,exp,cc,last_payment_status"/>
          { trustCommerceConnectUrl && (
            <span className="d-inline-block">
              <Link download link={trustCommerceConnectUrl} className="btn btn-primary mt-4 float-left d-inline-block">
                <FontAwesomeIcon title={trustCommerceConnectUrl.title} iconURI={trustCommerceConnectUrl.icon} className="mr-2"/>{trustCommerceConnectUrl.title}
              </Link>
            </span>
          )}
        </Card>
      }

      { pgsPaymentMethodEntity &&
        <Card title={getFrontendTranslations() ? getFrontendTranslations().pgs.PGStitle : translate("pgs.title")} subTitle={getFrontendTranslations() ? getFrontendTranslations().pgs.PGSdesc : translate("pgs.desc")}>
          <CreditCardRenderer entity={pgsPaymentMethodEntity} properties="card_number,exp,last4"/>
          { pgsFormUrl && (
            <span className="d-inline-block">
              <Link download link={pgsFormUrl} className="btn btn-primary mt-4 float-left d-inline-block">
                <FontAwesomeIcon title={pgsFormUrl.title} iconURI={pgsFormUrl.icon} className="mr-2"/>{pgsFormUrl.title}
              </Link>
            </span>
          )}

          { pgsSwitchType && (
            <form method={pgsSwitchType.method} className="d-inline-block mr-3">
              <Button
                onClick={() => {
                  const href = pgsSwitchType.href
                  const method = pgsSwitchType.method
                  api({ href, method }).then((response) => {
                    window.location.href = response.data.href
                  })}
                }>{pgsSwitchType.title}</Button>
            </form>
          )}

          { pgsForm && (
            <form method={pgsForm.method} className="d-inline-block">
              <Button
                onClick={() => {
                  const href = pgsForm.href
                  const method = pgsForm.method
                  api({ href, method }).then((response) => {
                    window.location.href = response.data.href
                  })}
                }>{pgsForm.title}</Button>
            </form>
          )}
        </Card>
      }

      { cardPaymentMethodEntity &&
        <Card>
          <CreditCardRenderer entity={cardPaymentMethodEntity} properties="name,exp,cc,last_payment_status"/>
          <ActionToggle action={updateCardPaymentMethodAction} className="mb-2"/>
          <ActionToggle action={replaceCardPaymentMethodAction}/>
        </Card>
      }

      { stripeSepaPaymentMethodEntity &&
        <Card>
          <CreditCardRenderer entity={stripeSepaPaymentMethodEntity} properties="account_holder,last4,last_payment_status"/>
          <ActionToggle action={replaceStripeSepaPaymentMethodAction}/>
        </Card>
      }

      { entity && <Card>
        <PropertiesBox
          entity={entity}
          keys={"customerNumber,name,contact_name,contact_department,email,secondaryEmail,telephone,costCenter,vatNumber," + `${customKeys}`}
          label
          headerClass={`${layoutName == "customerSelfService" ? "customer-" : ""}customerDetails`}/>
        <ActionToggle action={changePasswordAction} className="mb-2"/>
        <ActionToggle action={updateCustomerAction}/>
      </Card>}

      { addressEntity && <Card>
        <PropertiesBox
          entity={addressEntity}
          keys="streetAddress,addressLocality,addressRegion,postalCode,addressCountry"
          label/>
        <PropertiesBox
          entity={deliveryAddressEntity}
          keys="streetAddress,addressLocality,addressRegion,postalCode,addressCountry"
          label/>
        <ActionToggle action={updateAddressAction}/>
      </Card> }

      { directDebitPaymentMethodEntity &&
        <Card>
          <PropertiesBox entity={directDebitPaymentMethodEntity} keys="bic,iban,creditor_identifier,mandateIdentification" label mask="bic,iban"/>
          <ActionToggle action={updateDirectDebitPaymentMethodAction}/>
          {sepaMandateLink && (
            <Link download link={sepaMandateLink} className="btn btn-outline-secondary mt-4">
              <FontAwesomeIcon title={sepaMandateLink.title} iconURI={sepaMandateLink.icon} className="mr-2"/>{sepaMandateLink.title}
            </Link>
          )}
        </Card>
      }

    </Container>
  )
}

CustomerAccount.propTypes = {
  entity: SirenEntityType.isRequired,
  layoutName: PropTypes.string,
}

export default withEntity(CustomerAccount, {
  entities: ["address", "paymentMethod", "directDebitPaymentMethod", "deliveryAddress"],
})
