import React from "react"
import PropTypes from "prop-types"

import formatPrice from "formatPrice"
class SignupSummary extends React.Component {
  static propTypes = {
    summaryText: PropTypes.string,
    price: PropTypes.string,
    priceCurrency: PropTypes.string,
    conditions: PropTypes.string,
    initiallyRequestedParkingSpaceCount: PropTypes.number,
  }

  state = { initiallyRequestedParkingSpaceCount: this.props.initiallyRequestedParkingSpaceCount, price: this.props.price }

  updatePrice = (initiallyRequestedParkingSpaceCount, price) => this.setState(() => ({ initiallyRequestedParkingSpaceCount, price }))

  render() {
    const { summaryText, priceCurrency } = this.props
    let initiallyRequestedParkingSpaceCount
    if (this.state.initiallyRequestedParkingSpaceCount instanceof Array) {
      initiallyRequestedParkingSpaceCount = this.state.initiallyRequestedParkingSpaceCount.filter(item => item.selected).map(item => item.value)[0]
    }
    else {
      initiallyRequestedParkingSpaceCount = this.state.initiallyRequestedParkingSpaceCount
    }

    const priceTotal = initiallyRequestedParkingSpaceCount * this.state.price

    return (
      <div className="pb-3">
        {!isNaN(priceTotal) && <React.Fragment>
          <hr/>
          <h4>{summaryText} {formatPrice(priceTotal, priceCurrency)}</h4>
        </React.Fragment>}
      </div>
    )
  }
}

export default SignupSummary
