import React from "react"
import SirenEntityType from "api/SirenEntityType"
import FinaliseRequestOffer from "components/pages/public/Account/Signup/FinaliseRequestOffer"

const NewBookingRequestPage = ({ entity = {} }) => <FinaliseRequestOffer entity={entity} actionName="create-booking-request"/>
NewBookingRequestPage.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default NewBookingRequestPage
