import React from "react"
import SirenEntityType from "api/SirenEntityType"
import PropertiesBox from "components/PropertiesBox"

const Contact = ({ entity }) => <PropertiesBox entity={entity} keys="contactEmail,contactPhone" title={null}/>

Contact.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default Contact
