import { findByRelationStrict } from "api/utils"

const expectationsKeys = ["properties", "links", "actions"]
/**
 * Ensure all the expectations from the Component are met compared to the props he received
 * The expectations should be defined with an array of string.
 * For instance: { properties: ["name"] }
 * The expectations are then matched like this:
 *   - properties: object key / String
 *   - actions: name / String
 *   - links: rel / Array[String]
 * @param {Object} expectations
 * @param {Object} entity
 */

export const assertExpectations = (expectations = {}, entity = {}) =>
  expectationsKeys.every(key => !expectations[key] || assertExpectation(expectations[key], entity[key], key))

export const assertExpectation = (expectations, values, key) =>
  expectations.every(expectation => {
    switch (key) {
      case "properties":
        return expectation in values
      case "actions":
        return values.find(value => value.name === expectation)
      default: {
        expectation = Array.isArray(expectation) ? expectation : [expectation]

        return expectation.every(exp => !!findByRelationStrict(values, exp))
      }
    }
  })


