import React from "react"
import PropTypes from "prop-types"
import { Label } from "reactstrap"
import ReactQuill, { Quill } from "react-quill"
import BlotFormatter from "quill-blot-formatter"

// Import WYSIWYG Editor theme
import "react-quill/dist/quill.snow.css"

import get from "get"
import { getFrontendTranslations } from "components/Layout/layoutManager"
import translate from "components/i18n/translate"

// Since quill does not include the header 3-6 icon assets intentionally
// https://github.com/quilljs/quill/issues/1730#issuecomment-371994144
// We import the h3 icon ourselves here via parceljs
// See: https://parceljs.org/assets.html#javascript
// The "fs" import will be removed by parcel, I guess
import fs from "fs"
Quill.imports["ui/icons"].header[3] = fs.readFileSync("./node_modules/quill/assets/icons/header-3.svg", "utf8")

// Prefer using inline-style instead of class
// https://quilljs.com/guides/how-to-customize-quill/#class-vs-inline
const AlignStyle = Quill.import("attributors/style/align")
const BackgroundStyle = Quill.import("attributors/style/background")
const ColorStyle = Quill.import("attributors/style/color")
const DirectionStyle = Quill.import("attributors/style/direction")
const FontStyle = Quill.import("attributors/style/font")
const SizeStyle = Quill.import("attributors/style/size")
SizeStyle.whitelist = ["0.75em", "1.5em", "2.5em"]

Quill.register(AlignStyle, true)
Quill.register(BackgroundStyle, true)
Quill.register(ColorStyle, true)
Quill.register(DirectionStyle, true)
Quill.register(FontStyle, true)
Quill.register(SizeStyle, true)

// quill doesn't keep the style attribute on the img elements
// so we need this to override
// https://github.com/kensnyder/quill-image-resize-module/issues/10#issuecomment-381345361
const ATTRIBUTES = [
  "alt",
  "height",
  "width",
  "style",
]

const ALLOWED_IMG_STYLE = ["margin", "display", "float"]
var BaseImageFormat = Quill.import("formats/image")
class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ATTRIBUTES.reduce((result, attribute) => {
      if (domNode.hasAttribute(attribute)) {
        result[attribute] = domNode.getAttribute(attribute)
      }
      return result
    }, {})
  }

  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      value
        ? this.domNode.setAttribute(name, name === "style" ? this.sanitize_style(value) : value)
        : this.domNode.removeAttribute(name)
    } else {
      super.format(name, value)
    }
  }

  sanitize_style(style) {
    const styles = style.split(";")
    let resultStyle = ""
    styles.forEach(v => {
      if (ALLOWED_IMG_STYLE.indexOf(v.trim().split(":")[0]) !== -1) {
        resultStyle += v + ";"
      }
    })
    return resultStyle
  }
}
Quill.register(ImageFormat, true)
Quill.register("modules/blotFormatter", BlotFormatter)

const getClassName = (domNode) => {
  if (!domNode) { return "" }
  if (domNode.className) { return domNode.className }
  return get(Array.prototype.find.call(domNode.attributes, (attr => attr.name === "classname")), "value") || ""
}

const BaseLinkFormat = Quill.import("formats/link")
// extends https://github.com/quilljs/quill/blob/5d79e80e10154c5c6fd549c97d76cd0b71e7fa80/formats/link.js
class LinkFormat extends BaseLinkFormat {
  static create(link) {
    let linkNode = undefined
    if (typeof link !== "string") {
      linkNode = link
      link = link.getAttribute("href")
    }
    const node = super.create(link)
    linkNode && node.setAttribute("class", getClassName(linkNode))
    return node
  }
  static formats = domNode => domNode
  static sanitize = url => url
}
Quill.register(LinkFormat)

/*
 * WYSIWYG editor component
 */
class Editor extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    title: PropTypes.node,
    value: PropTypes.string,
    className: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.node,
    innerRef: PropTypes.func,
  }

  state = {
    editorHtml: this.props.value || "",
    theme: "snow",
  }

  constructor(props) {
    super(props)
    this.editorContainerRef = React.createRef()
  }

  componentDidMount() {
    // Add the button icon to the editor toolbar
    this.editorContainerRef.current.querySelector(".ql-button").className += " fa fa-plus-square"
  }

  handleChange = (html) => {
    this.setState({ editorHtml: html })
  }

  render () {
    const { name, title, className, error, innerRef } = this.props
    return (
      <div className="editor mb-4" ref={this.editorContainerRef}>
        <Label>{title}</Label>
        <ReactQuill
          theme={this.state.theme}
          onChange={this.handleChange}
          value={this.state.editorHtml}
          modules={Editor.modules}
          formats={Editor.formats}
          className={className}
        />
        <textarea
          className={`form-control text-area ${className}`}
          disabled
          name={name}
          value={this.state.editorHtml}
          ref={innerRef}
        />
        {error}
        <style jsx>{`
          .editor {
            min-height: 18em;
            margin-bottom: 1em;
          }

          .editor > :global(label) {
            display: block;
          }

          /*we need to use this instead of .d-none because .d-none fields aren't send to the API*/
          .text-area {
            display: none;
          }
        `}</style>
        <style jsx global>{`
          .ql-editor {
            min-height: 18em;
            margin-bottom: 1em;
            line-height: inherit;
          }

          .ql-container {
            font-size: 0.875rem;
          }

          .ql-editor p {
            margin-top: 0;
            margin-bottom: 1rem;
          }

          .ql-snow .ql-editor h1,
          .ql-snow .ql-editor h2,
          .ql-snow .ql-editor h3 {
            margin-bottom: 0.5rem;
            font-family: inherit;
            font-weight: 500;
            line-height: 1.2;
            color: inherit;
          }

          .ql-snow .ql-editor h1 {
            font-size: 2.1875rem;
          }
          .ql-snow .ql-editor h2 {
            font-size: 1.75rem;
          }
          .ql-snow .ql-editor h3 {
            font-size: 1.53125rem;
          }

          .ql-snow .ql-editor a {
            text-decoration: none;
            color: #00b0f0;
          }

          .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="0.75em"]::before,
          .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="0.75em"]::before {
            content: 'Small';
          }
          .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="0.75em"]::before {
            font-size: 0.75em;
          }

          .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="1.5em"]::before,
          .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1.5em"]::before {
            content: 'Large';
          }
          .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1.5em"]::before {
            font-size: 1.5em;
          }

          .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="2.5em"]::before,
          .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="2.5em"]::before {
            content: 'Huge';
          }
          .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="2.5em"]::before {
            font-size: 2.5em;
          }
        `}
        </style>
      </div>
    )
  }
}

const linkHandler = function (buttonClicked) {
  const range = this.quill.getSelection()
  const noTextSelected = range == null || range.length == 0
  const href = prompt(getFrontendTranslations() ? getFrontendTranslations().editor.askLinkHref : translate("editor.askLinkHref"), "https://")
  let text = ""
  if (noTextSelected) {
    text = prompt(getFrontendTranslations() ? getFrontendTranslations().editor.askLinkText : translate("editor.askLinkText"))
  } else {
    text = this.quill.getText(range.index, range.length)
    this.quill.updateContents({ ops: [{ retain: range.index }, { delete: range.length }] })
  }
  this.quill.clipboard.dangerouslyPasteHTML(
    range.index,
    `<a ${buttonClicked === "button" ? "className='btn btn-primary text-white'" : ""} href="${href}">${text}</a>`
  )
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: {
    container: [
      [{ "header": 1 }, { "header": 2 }, { "header": 3 }],
      [{ "size": ["0.75em", false, "1.5em", "2.5em"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ "align": [] }],
      [{ "list": "ordered" }, { "list": "bullet" }, { "indent": "-1" }, { "indent": "+1" }],
      [{ link: "link" }, { button: "button" }, "image"],
      ["clean"],
    ],
    handlers: {
      button: linkHandler,
      link: linkHandler,
    },
  },
  blotFormatter: {},
}
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  "header", "font", "size", "align",
  "bold", "italic", "underline", "strike", "blockquote",
  "list", "bullet", "indent",
  "link", "image",
  "style", "width", "height", // used by the image resize
  "button",
]

export default Editor
