import React from "react"

import Context from "components/FormRenderer/formSubmissionContext"

export default (Component) => {
  const withFormContext = props => (
    <Context.Consumer>
      { context => <Component context={context} {...props}/> }
    </Context.Consumer>
  )

  return withFormContext
}
