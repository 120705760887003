import React from "react"

import SirenEntityType from "api/SirenEntityType"
import { findByName } from "api/actions"
import FinaliseRequestOffer from "components/pages/public/Account/Signup/FinaliseRequestOffer"
import { fillSignupWithCustomer } from "components/pages/public/Account/Signup/fillSignup"

const ACTION_NAME = "signup"
const IS_DEV = process.env.NODE_ENV === "development"

const Signup = ({ entity = {} }) => {
  IS_DEV && fillSignupWithCustomer(findByName(entity.actions, ACTION_NAME))
  return <FinaliseRequestOffer entity={entity} actionName={ACTION_NAME}/>
}
Signup.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default Signup
