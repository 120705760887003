import React from "react"
import SirenEntityType from "api/SirenEntityType"
import withEntity from "components/withEntity"
import { findByName } from "api/actions"
import { findByClass, findByRelationStrict, filterByClass, filterByRelationStrict } from "api/utils"
import Card from "components/Card"
import PropertiesBox from "components/PropertiesBox"
import ActionsRenderer from "components/TableRenderer/ActionsRenderer"
import ActionToggle from "components/ActionToggle"
import Link from "components/Link"
import FontAwesomeIcon from "components/FontAwesome/FontAwesomeIcon"
import Address from "components/pages/admin/BookingRequest/Address"
//TODO: Remove PaymentMethod entity once psp branch is merged on backend
import PaymentMethod from "components/pages/admin/BookingRequest/PaymentMethod"
import DirectDebitPaymentMethod from "components/pages/admin/BookingRequest/DirectDebitPaymentMethod"
import CardPaymentMethod from "components/pages/admin/BookingRequest/CardPaymentMethod"
import { BookingChangeListClass, CustomerClass, IdentificationMediumClass, IdentificationComponentClass, ContractBookingListClass, ContractBookingClass } from "components/registry"
import InfoBlock from "components/InfoBlock"
import PropertiesLabelValue from "components/PropertiesLabelValue"
import TableRenderer from "components/TableRenderer/TableRenderer"
import CreditCardRenderer from "components/CreditCardRenderer/CreditCardRenderer"
import PropTypes from "prop-types"
import getCustomKeys from "components/getCustomKeys"
import DownloadButtons from "../../../DownloadButtons"

const BookingRequest = ({ entity = {}, layoutName }) => {
  const bookingChangeListEntity = findByClass(entity.entities, BookingChangeListClass)
  const customerEntity = findByClass(entity.entities, CustomerClass)
  const contractBookingListEntity = findByClass(entity.entities, ContractBookingListClass)
  const contractBookings = filterByClass(entity.entities, ContractBookingClass)
  const downloadLinks = filterByRelationStrict(entity.links, ["alternate", "download-link"])
  const welcomeLetterLink = findByRelationStrict(entity.links, ["alternate", "pdf"])
  const welcomeLetterLinkDocx = findByRelationStrict(entity.links, ["alternate", "docx"])
  const downloadSpreadsheetLink = findByRelationStrict(entity.links, ["alternate", "xlsx"])
  const identificationMedia = filterByClass(entity.entities, IdentificationMediumClass)
  const ACTION_DECLINE = "decline"
  const declineAction = findByName(entity.actions, ACTION_DECLINE)
  const ACTION_ABORT_ONBOARDING = "abort-onboarding"
  const abortOnboardingAction = findByName(entity.actions, ACTION_ABORT_ONBOARDING)
  const trustcommercePaymentMethodEntity = customerEntity && findByRelationStrict(customerEntity.entities, ["trustcommercePaymentMethod"])
  const customKeys = customerEntity && getCustomKeys(customerEntity.properties, "custom")
  const contractBookingsTitleSuffix = [contractBookings.length, entity.properties.contractBookingsCount].filter(x=>x).join("/")
  const identificationMediaTitleSuffix = [identificationMedia.length, entity.properties.identificationMediaCount].filter(x=>x).join("/")

  return (
    <Card header={entity.title} headerClass={`${layoutName == "customerSelfService" ? "customer-" : ""}BookingRequest`}>
      <PropertiesBox
        entity={customerEntity}
        keys={"customerNumber,name,contact_name,contact_department,email,secondaryEmail,telephone,vatNumber," + `${customKeys}`}
        label/>
      <Address entity={customerEntity}/>
      <PropertiesBox
        entity={entity}
        keys = "customerOnboardingField,validFrom,validUntil,customerType,daysSinceRequest,parkingFacilityName,parkingProductName,currentParkingSpaceCount,remarks,parkingProductPaymentMode,paymentMethodTypeForDisplay,paymentMethodShortDescriptor,oneTimeFee,initialBillingAmount,recurringFee,payrollCycle,initiallyRequestedParkingSpaceCount,initiallyRequestedVehicleCount,currentVehicleCount,productCategories,productDuration,costCenter"
        label/>

      { contractBookings.length > 0 && <InfoBlock title={`${contractBookings[0].title} (${contractBookingsTitleSuffix})`} className="mb-4">
        { contractBookings.map(contractBookingEntity => {
          return <PropertiesBox
            key={contractBookingEntity.properties.id}
            entity={contractBookingEntity}
            keys="contractBookingNumber,parkingLotNumber,licensePlateNumbers,cardNumber,id"
            label
            title="" />
        })}
      </InfoBlock>}

      { identificationMedia.length > 0 && <InfoBlock title={`${identificationMedia[0].title} (${identificationMediaTitleSuffix})`} className="mb-4">
        { identificationMedia.map(identificationMedium => {
          const identificationMediumComponents = filterByClass(identificationMedium.entities, IdentificationComponentClass)
          return identificationMediumComponents.length > 0 ? <div className="pb-2">
            {identificationMediumComponents.map(identificationMediumComponent => {
              return <PropertiesLabelValue
                key={identificationMediumComponent.properties.identifier}
                label={identificationMediumComponent.properties.type}
                value={identificationMediumComponent.properties.identifier}/>
            })}</div> : null
        })
        }
      </InfoBlock>}
      { contractBookingListEntity &&
        <TableRenderer
          entity={contractBookingListEntity}
          className="no-margin"
          rowClick
          selectRow={false}
          search={false}
          pagination={false}
          isSecondaryTable={true}
          exportCSV={false}/> }
      <PaymentMethod entity={customerEntity}/>
      {entity.properties.paymentMethodType === "direct_debit" &&
      <DirectDebitPaymentMethod entity={customerEntity}/>}
      {entity.properties.paymentMethodType === "card" &&
      <CardPaymentMethod entity={customerEntity}/>}
      { trustcommercePaymentMethodEntity &&
        <CreditCardRenderer entity={trustcommercePaymentMethodEntity} properties="name,exp,cc,last_payment_status"/>
      }
      <DownloadButtons links={downloadLinks}/>
      {welcomeLetterLink && (
        <span className="d-inline-block">
          <Link download link={welcomeLetterLink} className="btn btn-outline-secondary mt-4 float-left d-inline-block">
            <FontAwesomeIcon title={welcomeLetterLink.title} iconURI={welcomeLetterLink.icon} className="mr-2"/>{welcomeLetterLink.title}
          </Link>
        </span>
      )}
      {welcomeLetterLinkDocx && (
        <span className="d-inline-block">
          <Link download link={welcomeLetterLinkDocx} className="btn btn-outline-secondary mt-4 float-left d-inline-block">
            <FontAwesomeIcon title={welcomeLetterLinkDocx.title} iconURI={welcomeLetterLinkDocx.icon} className="mr-2"/>{welcomeLetterLinkDocx.title}
          </Link>
        </span>
      )}
      {downloadSpreadsheetLink && (
        <span className="d-inline-block">
          <Link download link={downloadSpreadsheetLink} className="btn btn-outline-secondary mt-4 float-left d-inline-block">
            <FontAwesomeIcon title={downloadSpreadsheetLink.title} iconURI={downloadSpreadsheetLink.icon} className="mr-2"/>{downloadSpreadsheetLink.title}
          </Link>
        </span>
      )}
      { bookingChangeListEntity &&
        <TableRenderer
          entity={bookingChangeListEntity}
          className="no-margin"
          rowClick={false}
          selectRow={false}
          search={false}
          pagination={false}
          isSecondaryTable={true}
          exportCSV={false}
        />
      }
      { !!entity.actions.length && <hr/> }
      <div className="mixed-actions">
        <ActionsRenderer
          actions={entity.actions.filter(action => action.name != ACTION_DECLINE &&
                                                   action.name != ACTION_ABORT_ONBOARDING)}/>
        {declineAction && (
          <div className="toggled-action position-relative">
            <ActionToggle action={declineAction} className="btn btn-warning px-4"/>
          </div>
        )}
        {abortOnboardingAction && (
          <div className="toggled-action position-relative">
            <ActionToggle action={abortOnboardingAction} className="btn btn-warning px-4"/>
          </div>
        )}
      </div>
      <style jsx global>{`
        .actions {
          position: relative;
        }

        .actions > form:nth-child(2) {
          position: absolute;
          right: 0;
          bottom: 0;
        }

        .actions div {
          border: none;
        }

        .actions form > div {
          padding: 0 !important;
          margin-bottom: 0;
        }

        .actions div > h5 {
          display: none;
        }

        .actions div.p-4.card:not(:first-child) div > h6 {
          display: none;
        }

        .no-margin {
          margin: 0 !important;
        }

        .toggled-action .actions {
          margin-top: 0;
        }

        .mixed-actions > .toggled-action > div > button {
          position: absolute;
          top: -7.8rem;
          margin-top: 3.35rem;
          right: 0;
          margin-right: 1.25rem;
        }
      `}</style>
    </Card>
  )
}

BookingRequest.propTypes = {
  entity: SirenEntityType.isRequired,
  layoutName: PropTypes.string,
}

export default withEntity(BookingRequest, {
  entities: ["customer", "bookingChanges", "contractBooking"],
})
