import EntityType from "api/SirenEntityType"

import { mapEntitiesByRelationStrict } from "api/entities"

const EmployeeGroupListAdapter = ({ entity }) => EmployeeGroupListAdapter.value(entity)
EmployeeGroupListAdapter.value = (entity) => mapEntitiesByRelationStrict(entity.entities, "group", groupEntity => groupEntity.title).join(", ")
EmployeeGroupListAdapter.propTypes = {
  entity: EntityType,
}

export default EmployeeGroupListAdapter
