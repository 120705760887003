import React from "react"
import PropTypes from "prop-types"

import { LinkType } from "api/SirenEntityType"
import encodeURL from "urlHelpers/encodeURL"

const PROPTYPES = {
  link: LinkType,
  href: PropTypes.string,
  children: PropTypes.node,
  target: PropTypes.oneOf(["_self", "_blank", "_parent", "_top"]),
  rel: PropTypes.array,
  external: PropTypes.bool,
  download: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  type: PropTypes.string,
}

const Link = ({
  link = {},
  href = link.href,
  children = link.title || href,
  target,
  rel = link.rel || [],
  external = rel.indexOf("external") !== -1,
  download,
  className,
  type = link.type,
  ...props }) => {
  // https://mathiasbynens.github.io/rel-noopener/
  if (target === "_blank") {
    rel.indexOf("noopener") === -1 && rel.push("noopener")
    rel.indexOf("noreferrer") === -1 && rel.push("noreferrer")
  }

  if (external) {
    rel.indexOf("external") === -1 && rel.push("external")
  } else {
    href = type ? href : encodeURL(href)
  }

  const handleClickInNewBrowserTab = (e) => {
    e.preventDefault()
    window.open(href, "_blank")
    return false
  }

  return (
    <a
      href={href}
      download={download}
      {...(target === "_blank" && { onClick: handleClickInNewBrowserTab })}
      {...(target !== "_blank" && { target: target })}
      rel={rel.length && rel.join(" ") || undefined}
      className={className}
      type={type}
      {...props}
    >
      {children}
    </a>
  )
}

Link.propTypes = PROPTYPES
export default Link
