import React from "react"
import PropTypes from "prop-types"

import Table from "components/Table"
import Value from "components/Value"

/**
 * Renders a table of properties
 *
 * @param {object} properties to render. Can be undefined or null and nothing
 * is rendered.
 */
const PropertiesTable = ({ properties = {}, translations = {} }) => {
  const entries = Object.entries(properties)
  if (!entries.length) {
    return null
  }
  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        { entries.map(([key, value ]) => (
          <tr key={key}>
            <td>{translations[key] || key}</td>
            <td><Value>{value}</Value></td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
PropertiesTable.propTypes = {
  properties: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  translations: PropTypes.object,
}

export default PropertiesTable
