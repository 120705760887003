import React from "react"

import { filterByRelationStrict } from "api/utils"
import Link from "components/Link"

const flattenArray = (arr) => arr.reduce((accumulator, value) => accumulator.concat(value), [])

const getFieldTitleAndUnmatchedLinks = ({ links, actionName, field }) => {
  const relatedLinks = filterByRelationStrict(links, ["related", `${actionName}-${field.name}`])
  const matchedLinks = relatedLinks.filter(link => field.title.indexOf(link.title) !== -1)
  const unmatchedLinks = relatedLinks.filter(link => field.title.indexOf(link.title) === -1)

  if (!matchedLinks.length) {
    return { fieldTitle: field.title, unmatchedLinks }
  }

  let fieldTitle = matchedLinks.reduce((result, link) => {
    result = result.map(part => {
      if (typeof part !== "string" || part.indexOf(link.title) === -1) {
        return part
      }

      // split on the title (return an array of [beforeTitle, afterTitle]), and then insert the link in the middle
      part = part.split(link.title)
      return [part[0], <Link key={`${link.title}-${link.href}`} link={link} target="_blank">{link.title}</Link>, part[1]]
    })

    return flattenArray(result)
  }, [field.title])

  fieldTitle = <span>{fieldTitle}</span>

  return { fieldTitle, unmatchedLinks }
}

export default getFieldTitleAndUnmatchedLinks
