import PropTypes from "prop-types"

import EntityType from "api/SirenEntityType"
import formatPrice from "formatPrice"
import { getProperty } from "components/TableRenderer/adapters/SubentityPropertyAdapter"

const PriceAndCurrencyAdapter = ({ entity, property }) => {
  const priceAndCurrency = PriceAndCurrencyAdapter.value(entity, property)

  if (!priceAndCurrency) { return null }
  const [price, currency] = priceAndCurrency.split("|")

  try {
    return formatPrice(price, currency)
  } catch (error) {
    return error instanceof RangeError ? formatPrice(price, "EUR") : price
  }
}
PriceAndCurrencyAdapter.value = (entity, property = "price+priceCurrency") => {
  let [priceProperty, currencyProperty] = property.split("+")
  const price = getProperty(entity, priceProperty)
  const currency = getProperty(entity, currencyProperty) || "EUR"
  
  return price ? formatPrice(price, currency) : ""
}
// trick the csv export into thinking it's 2 columns
PriceAndCurrencyAdapter.csvHeader = "price\",\"currency"
PriceAndCurrencyAdapter.csvValue = (entity, property) => {
  const priceAndCurrency = entity.properties[property]
  if (!priceAndCurrency) { return "" }
  const [price, currency] = priceAndCurrency.split("|")

  return `${price}","${currency}`
}
PriceAndCurrencyAdapter.sort = (a, b, order, property) => {
  const aValue = a[property] ? parseFloat(a[property].split("|")[0]) : 0
  const bValue = b[property] ? parseFloat(b[property].split("|")[0]) : 0
  return order === "desc"
    ? bValue - aValue
    : aValue - bValue
}
PriceAndCurrencyAdapter.propTypes = {
  entity: EntityType,
  property: PropTypes.string,
}

export default PriceAndCurrencyAdapter
