import React from "react"
import PropTypes from "prop-types"
import FontAwesomeIcon from "components/FontAwesome/FontAwesomeIcon"
import Link from "components/Link"

const PROPTYPES = {
  links: PropTypes.array,
}

const DownloadButtons = ({ links }) => {
  return links.map(link => {
    return <Link
      key={link.href}
      download link={link}
      className="btn btn-outline-secondary mt-4 mr-3 float-left d-inline-block">
      <FontAwesomeIcon
        title={link.title} iconURI={link.icon}
        className="mr-2"/>{link.title}
    </Link>
  })
}

DownloadButtons.propTypes = PROPTYPES
export default DownloadButtons
