import React from "react"

import propertySort from "components/TableRenderer/adapters/propertySort"
import { findByClass } from "api/utils"

const asyncAdapters = {
  "https://schema.evopark.com/adapter/Email": () => import("components/TableRenderer/adapters/EMailAdapter"),
  "https://schema.evopark.com/adapter/Date": () => import("components/TableRenderer/adapters/DateAdapter"),
  "https://schema.evopark.com/adapter/DateTime": () => import("components/TableRenderer/adapters/DateTimeAdapter"),
  "https://schema.evopark.com/adapter/Actions": () => import("components/TableRenderer/adapters/ActionsAdapter"),
  "https://schema.evopark.com/adapter/EmployeeGroupList": () => import("components/TableRenderer/adapters/EmployeeGroupListAdapter"),
  "https://schema.evopark.com/adapter/SubentityProperty": () => import("components/TableRenderer/adapters/SubentityPropertyAdapter"),
  "https://schema.evopark.com/adapter/PriceAndCurrency": () => import("components/TableRenderer/adapters/PriceAndCurrencyAdapter"),
  "https://schema.evopark.com/adapter/PDF": () => import("components/TableRenderer/adapters/PDFAdapter"),
  "https://schema.evopark.com/adapter/TemplateTitle": () => import("components/TableRenderer/adapters/TemplateTitleAdapter"),
  "http://schema.org/Duration": () => import("components/TableRenderer/adapters/DurationAdapter"),
}

const adapterDefaultValue = (entity, property) => entity.properties[property]

export const resolveAdapter = (adapterClass, property, entityIdentifier) => {
  const adapter = asyncAdapters[adapterClass]
  if (adapter) {
    // eslint-disable-next-line react/display-name
    return adapter().then(({ default: Adapter }) => {
      const func = (entity, onClick) => <Adapter entity={entity} property={property} onClick={onClick}/>
      func.value = Adapter.value || adapterDefaultValue
      func.csvValue = Adapter.csvValue
      func.filterValue = Adapter.filterValue
      func.sort = Adapter.sort || propertySort
      func.csvHeader = Adapter.csvHeader
      return func
    })
  } else {
    return Promise.resolve(propertyAdapter(property, entityIdentifier))
  }
}

/**
 * Returns a component that renders a specified property.
 *
 * This also provides a `sort` method that sorts property by there string
 * representation using `localeCompare`.
 *
 * @param {String} property name of the property to render
 */
export const propertyAdapter = (property, entityIdentifier) => {
  const adapter = entity => {
    if (entityIdentifier) {
      entity = findByClass(entity.entities, entityIdentifier) || entity
    }
    if (property === "title" || property === "name") {
      return entity.properties[property] || entity.title
    } else {
      return entity.properties[property]
    }
  }
  adapter.sort = propertySort

  adapter.value = adapter
  return adapter
}
