import React from "react"
import PropTypes from "prop-types"

import { NavLink as NavLinkStrap } from "reactstrap"
import Link from "components/Link"

import { LinkType } from "api/SirenEntityType"

const NavLink = ({ link, children = " ", ...props }) => (
  <NavLinkStrap tag={Link} link={link} {...props}>{children}</NavLinkStrap>
)

NavLink.propTypes = {
  link: LinkType.isRequired,
  children: PropTypes.node,
}

export default NavLink
