const getCustomKeys = (properties, customString) => {
  var filteredKys = []
  Object.keys(properties).map(key => {
    if (key.split("_")[0] === customString) {
      filteredKys = filteredKys.concat(key)
      return filteredKys
    }
  })
  return filteredKys
}

export default getCustomKeys
