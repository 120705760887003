import React from "react"
import PropTypes from "prop-types"

import { Button } from "reactstrap"

import { getFontAwesomeIconClass } from "components/FontAwesome/fontawesome"

const FontAwesomeButton = ({ iconURI, color = "primary", children }) => (
  <Button color={color}>
    <i className={`${getFontAwesomeIconClass(iconURI)} pr-2`}/>
    {children}
  </Button>
)

FontAwesomeButton.propTypes = {
  iconURI: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
}

export default FontAwesomeButton
