import React from "react"
import PropTypes from "prop-types"

import EntityType from "api/SirenEntityType"
import { getProperty } from "components/TableRenderer/adapters/SubentityPropertyAdapter"

// When a template is a default template, we want to show the title in italic

const TemplateTitleAdapter = ({ entity, property }) => {
  const defaultTemplate = getProperty(entity, "defaultTemplate")
  const title = getProperty(entity, property)
  return defaultTemplate ? (<i>{title}</i>) : title
}

TemplateTitleAdapter.propTypes = {
  entity: EntityType,
  property: PropTypes.string,
}

export default TemplateTitleAdapter
