
import React from "react"

import SirenEntityType from "api/SirenEntityType"
import { CardDeck, Alert } from "reactstrap"
import Card from "components/Card"
import { findByClassStrict, filterByClassStrict, findByRelationStrict } from "api/utils"
import PropertyTranslation from "components/PropertyTranslation"
import InfoBlock from "components/InfoBlock"
import Link from "components/Link"
import { TextBlockClass } from "components/registry"
import TextBlock from "components/pages/public/LandingPage/TextBlock"
import { getItem } from "storage"

const CustomerDashboard = ({ entity }) => {
  const contractSummary = findByClassStrict(entity.entities, "https://schema.evopark.com/ContractSummary")
  const contractLink = findByRelationStrict(entity.links, "contract")
  const invoices = filterByClassStrict(entity.entities, "http://schema.org/Invoice")
  const invoicesLink = findByRelationStrict(entity.links, "invoices")
  const customer = findByClassStrict(entity.entities, "https://schema.evopark.com/Customer")
  const accountLink = findByRelationStrict(entity.links, "account")
  const textBlock = filterByClassStrict(entity.entities, TextBlockClass)

  return (
    <React.Fragment>
      <section className="container">
        {textBlock.length > 0 && textBlock.map(eachTextBlock => (
          <Alert key={eachTextBlock.title} color={eachTextBlock.properties.message_type}>
            <TextBlock entity={eachTextBlock}/>
          </Alert>
        ))
        }
        <CardDeck>
          {contractSummary && (
            <Card md="4" className="bg-light">
              <InfoBlock title={contractSummary.title} className="d-flex flex-column justify-content-between h-100">
                <div className="mt-4">
                  <strong className="my-3"><PropertyTranslation entity={contractSummary} name="activeContractBookingsCount"/></strong>
                  <div className="my-3">{contractSummary.properties.activeContractBookingsCount}</div>
                  <strong className="my-3"><PropertyTranslation entity={contractSummary} name="bookingRequestsCount"/></strong>
                  <div className="my-3">{contractSummary.properties.bookingRequestsCount}</div>
                </div>
                {contractLink && (
                  <div className="align-self-center mt-4">
                    <Link className="btn btn-secondary" link={contractLink}>{contractLink.title}</Link>
                  </div>
                )}
              </InfoBlock>
            </Card>
          )}
          { invoices.length && (
            <Card md="4" className="bg-light">
              <InfoBlock title={<PropertyTranslation entity={entity} name="invoices"/>} className="d-flex flex-column justify-content-between h-100">
                <div>
                  {invoices.map(e => {
                    const { billingPeriod, netTotal, vatTotal, currency } = e.properties

                    const parts = billingPeriod.split("/") // following iso8601 time-interval format
                    const locale = getItem("lang") || "en-us"
                    const month = new Date(parts[0]).toLocaleString(locale, { month: "long" }) /*i18n*/
                    const amount = (Number(netTotal) + Number(vatTotal))
                    return (
                      <div key={e.title}>
                        <div className="my-3"><strong>{month} {new Date(parts[0]).getFullYear()}</strong></div>
                        { !!amount && <div className="my-3">{amount.toFixed(2)} {currency}</div> }
                      </div>
                    )
                  })}
                </div>
                {invoicesLink && (
                  <div className="align-self-center mt-4">
                    <Link className="btn btn-secondary" link={invoicesLink}>{invoicesLink.title}</Link>
                  </div>
                )}
              </InfoBlock>
            </Card>
          ) || null}
          {customer && (
            <Card md="4" className="bg-light">
              <InfoBlock title={customer.title} className="d-flex flex-column justify-content-between h-100">
                <div>
                  <h4 className="my-3"><strong>{customer.properties.name}</strong></h4>
                  <div className="my-3">{customer.properties.email}</div>
                </div>
                {accountLink && (
                  <div className="align-self-center mt-4">
                    <Link className="btn btn-secondary" link={accountLink}>{accountLink.title}</Link>
                  </div>
                )}
              </InfoBlock>
            </Card>
          )}
        </CardDeck>
      </section>
    </React.Fragment>
  )
}

CustomerDashboard.propTypes = {
  entity: SirenEntityType.isRequired,
}
export default CustomerDashboard
