export const BULK_ACTION_CLASS = "https://schema.evopark.com/BulkAction"
export const DOWNLOAD_ACTION_CLASS = "https://schema.evopark.com/DownloadAction"
export const PRODUCT_CREATOR_ACTION_CLASSES = ["add-parking-product", "update-parking-product"]
export const BOOKING_REQUEST_FORM_CLASS = "https://schema.evopark.com/BookingRequestForm"

export const splitActionsByClass = (actions = [], className) => (
  Array.prototype.reduce.call(actions || [], (result, action) => {
    action.class.indexOf(className) !== -1 ? result.extracted.push(action) : result.rest.push(action)
    return result
  }, { extracted: [], rest: [] })
)

const fetchByAbstract = (arrayMethod) =>
  (propName) =>
    (things = [], value) =>
      arrayMethod.call(things, thing => thing[propName] === value)

const findBy = fetchByAbstract(Array.prototype.find)
const filterBy = fetchByAbstract(Array.prototype.filter)

export const findByName = findBy("name")
export const filterByName = filterBy("name")

export const findByType = findBy("type")
export const filterByType = filterBy("type")

export const findById = findBy("id")
export const filterById = filterBy("id")

export const filterByClass = (array, className) => array.filter(element => element.class.includes(className))

export const isSimpleAction = (action) => {
  if (action.fields && action.fields.length > 0) {
    return action.fields.every(field => field.type == "submit" || field.type == "hidden")
  }
  return false
}

export const fillActionValues = (action, values) => {
  if (!action) { return }
  action.fields = action.fields.map((field) => {
    const fieldValue = values[field.name]
    if ((field.type === "radio" || field.type === "select") && fieldValue) {
      field.value = (field.value || []).map(v => ({ ...v, selected: v.value === fieldValue }))
    } else if (field.type === "checkbox" && fieldValue !== undefined) {
      field.value = typeof field.value !== "object" ? { value: field.value } : field.value
      field.value.selected = fieldValue
    } else {
      field.value = field.value || fieldValue
    }
    return field
  })
}
