import React from "react"

import Helmet from "react-helmet"

import { mapEntitiesByRelation } from "api/entities"
import SirenEntityType from "api/SirenEntityType"

// return flatten array of meta infos
const getMetaFrom = entity => (
  [].concat.apply([], mapEntitiesByRelation(entity.entities, "meta", ({ properties }) =>
    Object.keys(properties).map(name => ({ name, content: properties[name] }))
  ))
)
const PageMeta = ({ entity = {} }) => (
  <Helmet title={entity.title} meta={getMetaFrom(entity)}/>
)
PageMeta.propTypes = {
  entity: SirenEntityType,
}

export default PageMeta
