import React from "react"

import SirenEntityType from "api/SirenEntityType"
import InfoBlock from "components/InfoBlock"

const Address = ({ entity }) => (
  <InfoBlock title={entity.title}>
    <div>{entity.properties.streetAddress}</div>
    <div>{entity.properties.postalCode} {entity.properties.addressLocality}</div>
  </InfoBlock>
)

Address.propTypes = {
  entity: SirenEntityType.isRequired,
}

export default Address
