import React from "react"

import EntityType from "api/SirenEntityType"
import PropertiesLabelValue from "components/PropertiesLabelValue"
import { mapEntitiesByClass } from "api/entities"
import { LocationFeatureSpecificationClass } from "components/registry"
import FontAwesomeIcon from "components/FontAwesome/FontAwesomeIcon"
import InfoBlock from "components/InfoBlock"
import PropertyTranslation from "components/PropertyTranslation"

const ParkingFacilityFeatures = ({ entity }) => {
  const features = mapEntitiesByClass(entity.entities, LocationFeatureSpecificationClass, entityFeature => {
    const value = (
      <React.Fragment>
        {entityFeature.properties.value}
        {entityFeature.properties.image && <FontAwesomeIcon iconURI={entityFeature.properties.image}/>}
      </React.Fragment>
    )
    return <PropertiesLabelValue key={entityFeature.key} label={entityFeature.title} value={value}/>
  })

  if (!features.length) { return null }

  return (
    <InfoBlock title={<PropertyTranslation entity={entity} name="amenityFeature"/>} className="mb-4">
      {features}
    </InfoBlock>
  )
}

ParkingFacilityFeatures.propTypes = {
  entity: EntityType.isRequired,
}

export default ParkingFacilityFeatures
